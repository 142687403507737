import React from 'react'

import Header from './UnifiedHeader'
import ConnectionStatusBar from 'components/Elements/ConnectionStatusBar'


const IOHeader = () => {

  return <>
    <Header />
    <ConnectionStatusBar />
  </>
}

export default IOHeader
