import { handleActions } from 'redux-actions'

const initialQuotes = {
  quotes: [],
  quote: null,
  lastUpdatedAt: null
}

export default handleActions({

  LOAD_QUOTES_FAILED(state) {
    // force reload next time
    return { ...state, quotes: [], lastUpdatedAt: null }
  },

  LOAD_QUOTES_FULFILLED(state, { payload }) {
    return { ...state, quotes: payload, lastUpdatedAt: Date.now() }
  }

}, initialQuotes)

export const QuoteSelectors = {
  get(state) {
    return state.quotes.quotes
  },

  updatedAt(state) {
    return state.quotes.lastUpdatedAt
  }
}

