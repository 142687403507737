// hack to programmatically affect a file download
export default (contents, type, filename) => {
  const
    binaryBlob = new Blob([ contents ], { type }),
    anchorTag = document.createElement("a")

  anchorTag.href = window.URL.createObjectURL(binaryBlob)
  anchorTag.download = filename
  anchorTag.click()
  anchorTag.remove()
}
