import Session from './session'
import { Paths } from 'utils/paths'

const Keys = {}

// load all tokens
Keys.all = function () {
  return (dispatch, getState) => {
    const client = Session.getAuthenticatedClient(dispatch, getState)
    if (! client) {
      return
    }

    const url = Paths.api_tokens({ username: client.username })

    return dispatch({
      type: 'KEYS_ALL',
      payload: Session.ajax(client, url)
    })
  }
}

// create a new token
Keys.create = function () {
  return (dispatch, getState) => {
    const client = Session.getAuthenticatedClient(dispatch, getState)
    if (! client) return

    const url = Paths.api_tokens({ username: client.username })

    return dispatch({
      type: 'KEYS_CREATE',
      payload: Session.ajax(client, url, {
        method: 'POST',
        body: JSON.stringify({token: {expiration: 0}})
      })
    })
  }
}

Keys.toggleModal = (isVisible) => ({
  type: 'KEYS_TOGGLE_MODAL',
  payload: isVisible
})

export default Keys

