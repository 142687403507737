import { createActions } from 'redux-actions'

import Session from './session'
// import log from 'utils/logger'
import { Paths } from 'utils/paths'

const Guides = createActions({
  SHOW_GUIDE: name => ({ name }),
  SHOW_GUIDE_PAGE: page => ({ page }),
}, 'HIDE_GUIDE')

const _cached = {}

Guides.get = () => {
  const type = 'GET_GUIDES'
  return (dispatch, getState) => {
    const client = Session.getAuthenticatedClient(dispatch, getState)
    if (client) {
      if (_cached[client.username]) {
        // log("[actions/guides] loading from cache", _cached[client.username])
        return dispatch({
          type,
          payload: Promise.resolve(_cached[client.username])
        })
      }

      const url = Paths.api_guides()
      return dispatch({
        type,
        payload: Session.ajax(client, url).then(response => {
          // log("[actions/guides] loading from ajax", response)
          _cached[client.username] = response
          return response
        })
      })
    }
  }
}

Guides.dismiss = (key) => {
  const type = 'DISMISS_GUIDE'
  return (dispatch, getState) => {
    const client = Session.getAuthenticatedClient(dispatch, getState)
    if (client) {
      if (_cached[client.username]) {
        const idx = _cached[client.username].findIndex(g => g.key === key)
        if (idx >= 0) {
          _cached[client.username].splice(idx, 1)
        }
      }

      const url = Paths.api_guide_dismiss({key})
      return dispatch({
        type,
        payload: Session.ajax(client, url, { method: "POST" }).then(response => {
          _cached[client.username] = response
          return {
            response,
            guide: key
          }
        })
      })
    }
  }
}

export default Guides
