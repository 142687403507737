import React from 'react'
import DocumentTitle from 'components/TopLevel/DocumentTitle';
import { Link } from 'react-router'

import image_404 from 'images/404.jpg'

const NotFound = () =>(
  <div>
    <DocumentTitle title="IO - Not Found" />
    <img src={image_404} className="error-image img-responsive" alt="404 Error. Page not found." />
    <Link to='/'>Return home</Link>
  </div>
)

export default NotFound
