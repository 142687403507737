import { handleActions } from 'redux-actions'

const initialState = {
  dashboardWelcome: {
    run: false,
    step: 0,
    blockType: null,
    editorStep: null
  }
}

function updateDashboardWelcome(state, replace={}) {
  return Object.assign({}, state, {
    dashboardWelcome: Object.assign({}, state.dashboardWelcome, replace)
  })
}

const walkthroughs = handleActions({

  START_DASHBOARD_WALKTHROUGH(state) {
    return updateDashboardWelcome(state, { step: 0, run: true })
  },

  STOP_DASHBOARD_WALKTHROUGH(state) {
    return updateDashboardWelcome(state, { step: 0, run: false })
  },

  FINISH_DASHBOARD_WALKTHROUGH(state) {
    return updateDashboardWelcome(state, { run: false })
  },

  BLOCKS_TOGGLE_MODAL(state, { payload }) {
    const { step } = state.dashboardWelcome
    const replace = { }
    if (payload && step <= 1) {
      replace['step'] = 2
    } else if (!payload && step <= 5) {
      replace['step'] = 5
    }
    return updateDashboardWelcome(state, replace)
  },

  BLOCKS_SET_CREATE_TYPE(state, { payload }) {
    const { step } = state.dashboardWelcome
    const { blockType } = payload
    const replace = { blockType }
    if (step <= 2) {
      replace['step'] = 3
    }
    return updateDashboardWelcome(state, replace)
  },

  BLOCKS_SELECT_EDITOR_STEP(state, { payload }) {
    const { step } = state.dashboardWelcome
    const replace = { editorStep: payload }
    if (step === 3 && payload === 'settings') {
      replace['step'] = 4
    }
    return updateDashboardWelcome(state, replace)
  },

  DASHBOARDS_START_EDITING(state) {
    const { step } = state.dashboardWelcome
    const replace = { }
    if (step <= 5) {
      replace['step'] = 6
    }
    return updateDashboardWelcome(state, replace)
  },

  DASHBOARDS_STOP_EDITING(state) {
    const { step } = state.dashboardWelcome
    const replace = { }
    if (step <= 7) {
      replace['step'] = 8
    }
    return updateDashboardWelcome(state, replace)
  },

  DISMISS_GUIDE_FULFILLED(state, { payload }) {
    if (payload.guide === 'dashboard_walkthrough') {
      return updateDashboardWelcome(state, { step: 0, run: false })
    }

    return state
  },


}, initialState)

export default walkthroughs

