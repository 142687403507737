import { handleActions } from 'redux-actions'

import { attachUserToErrorReports } from 'utils/errors'

const defaultSession = {
  loading: false,
  ready: false,
  token: null,
  client: null,
  user: null,
  error: null
}

const Session = handleActions({
  SESSION_INIT(state) {
    const loading = true,
          ready = false
    return Object.assign({}, state, { loading, ready })
  },

  SESSION_INIT_DONE(state) {
    const loading = false,
          ready = true
    return Object.assign({}, state, { loading, ready })
  },

  SET_SESSION_TOKEN(state, { payload }) {
    const token = payload
    return Object.assign({}, state, { token })
  },

  SET_SESSION_USER(state, { payload }) {
    const
      user = payload,
      { client } = state

    // So reported errors know who experienced them
    attachUserToErrorReports(user)

    return {
      ...state,
      client: {
        ...client,
        username: user.username
      },
      user
    }
  },

  SET_SESSION_CLIENT(state, { payload }) {
    const client = payload
    return Object.assign({}, state, { client })
  },

  SET_SESSION_ERROR(state, { payload }) {
    const error = payload
    return Object.assign({}, state, { error })
  },

  // clear user, token, client.jwt, and client.username
  REMOVE_USER_SESSION(state) {
    return {
      ...state,
      user: null,
      token: null,
      client: {
        ...state.client || {},
        username: null,
        jwt: null
      }
    }
  }
}, defaultSession)



export default Session
