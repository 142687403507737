import { buildAPIReducers } from 'utils/reducers'


const initialState = {
  dynamicActions: [],
  dynamicAction: null,
}

const dynamicActions = buildAPIReducers('dynamicAction', initialState)

export { dynamicActions }
