import { chain } from 'lodash'
import { handleActions } from 'redux-actions'

import Plans from 'utils/plans'


const initialValue = {
  error: null,
  current: null,
  currentLoading: false,
  sidebar: null,
  throttle: null,
}

export default handleActions({
  PROFILE_SELECT(state, { payload }) {
    return { ...state, current: payload }
  },

  PROFILE_LOAD_PENDING(state) {
    return { ...state, currentLoading: true }
  },

  PROFILE_LOAD_FULFILLED(state, { payload }) {
    return {
      ...state,
      current: {
        ...payload,
        retrievedAt: Date.now()
      },
      currentLoading: false
    }
  },

  PROFILE_LOAD_REJECTED(state, { payload }) {
    return {
      ...state,
      currentLoading: false,
      error: payload.message
    }
  },

  SIDEBAR_LOAD_FULFILLED(state, { payload }) {
    return { ...state, sidebar: payload }
  },

  THROTTLE_LOAD_FULFILLED(state, { payload }) {
    return { ...state, throttle: payload }
  },

  PROMOTION_DISCOUNTS_LOAD_FULFILLED(state, { payload }) {
    return {
      ...state,
      current: {
        ...state.current,
        promotion_discounts: payload
      }
    }
  }
}, initialValue)

const ProfileSelectors = {
  getUserSidebar(state) {
    return state.profiles.sidebar
  },

  getUserThrottle(state) {
    return state.profiles.throttle
  },

  getCurrentUserProfile(state) {
    // only return signed-in user profile record
    if (state.session.user && state.profiles.current?.username === state.session.user.username) {
      return state.profiles.current
    }
  },

  getUserPlan(state) {
    return state.profiles.current?.subscription?.plan || null
  },

  getUserSubscription(state) {
    return state.profiles.current?.subscription || null
  },

  getUserUsageStats(state) {
    const
      { user } = state.session,
      usage = ProfileSelectors.getUserSidebar(state),
      throttle = ProfileSelectors.getUserThrottle(state)

    if (!(user && usage && throttle)) { return {} }

    const
      { subscription } = user,
      { limits } = subscription || Plans.free

    return {
      devices: {
        name: "Devices",
        usage: usage.wipper_device_count,
        limit: limits.wipper_devices
      },
      groups: {
        name: "Groups",
        usage: usage.group_count,
        limit: limits.groups
      },
      feeds: {
        name: "Feeds",
        usage: usage.feed_count,
        limit: limits.feeds
      },
      dashboards: {
        name: "Dashboards",
        usage: usage.dashboard_count,
        limit: limits.dashboards
      },
      dataRate: {
        name: "Data Rate",
        usage: throttle.active_data_rate,
        limit: throttle.data_rate_limit
      },
      smsRate: {
        name: "SMS Rate",
        usage: throttle.active_sms_rate,
        limit: limits.sms_rate
      },
    }
  },

  getUserFeedsAvailable(state) {
    const
      subscription = ProfileSelectors.getUserSubscription(state),
      feedLimit = subscription?.limits?.feeds || 0,
      currentFeedCount = chain(state.groups.groups)
        .flatMap("feeds")
        .uniqBy("id")
        .filter("enabled")
        .size()
      .value()

    return Math.min(feedLimit - currentFeedCount)
  },

  subscriptionSelector: state => ProfileSelectors.getCurrentUserProfile(state)?.subscription
}

export { ProfileSelectors }
