import { handleActions } from 'redux-actions'

const initialValue = {
  messages: [],
  flash: []
}

const MAX_MESSAGE_COUNT = 7

export default handleActions({
  SYSTEM_MESSAGES_LOAD_FULFILLED(state, { payload }) {
    return { ...state, messages: payload }
  },

  SYSTEM_MESSAGES_FLASH(state, { payload }) {
    return { ...state, flash: state.flash.concat([ payload ]) }
  },

  SYSTEM_MESSAGES_UNFLASH(state) {
    let { flash, messages } = state

    if (flash.length > 0) {
      messages = messages.concat(flash)
      return { ...state, flash: [], messages }
    }

    return state
  },

  SYSTEM_MESSAGES_ADD(state, { payload }) {
    let nextMessages = state.messages.concat([ payload ])

    if (nextMessages.length > MAX_MESSAGE_COUNT) {
      nextMessages = nextMessages.slice(nextMessages.length - MAX_MESSAGE_COUNT)
    }

    return { ...state, messages: nextMessages }
  },

  SYSTEM_MESSAGES_DISMISS_NOW(state, { payload }) {
    const without = state.messages.filter(({ id }) => id !== payload.id)

    return { ...state, messages: without }
  },
}, initialValue)
