import { handleActions } from 'redux-actions'

const initialState = {
  show: false,
  name: null, // guide to show
  page: 0, // guide page to show
  available: [],
}

const guides = handleActions({
  SHOW_GUIDE(state, { payload }) {
    return Object.assign({}, state, {name: payload.name, show: true, page: 0})
  },

  SHOW_GUIDE_PAGE(state, { payload }) {
    return Object.assign({}, state, {page: payload.page})
  },

  HIDE_GUIDE(state) {
    return Object.assign({}, state, {show: false, name: null, page: 0})
  },

  GET_GUIDES_FULFILLED(state, { payload }) {
    return Object.assign({}, state, { available: payload })
  },
}, initialState)

export default guides

const GuideSelectors = {
  getAvailableGuides(state) {
    return state.guides.available
  },

  hasDashboardWalkthrough(state) {
    return state.guides.available && state.guides.available.find(guide => guide.key === 'dashboard_walkthrough')
  },
}

export { GuideSelectors }
