import React from 'react'
import { connect } from 'react-redux'

import FeedbackActions from 'actions/feedback'
import FormButtons from './FormButtons'


class _FeedbackSignIn extends React.Component {
  close = () => {
    this.props.dispatch(FeedbackActions.toggleModal(false, this.props.identifier))
  }

  render() {
    return (
      <div>
        <p>
          To make sure we're able to get in touch with you, we require that you
          have an active Adafruit IO account before you can submit feedback. Please
          use the "Sign In" button below to continue.
        </p>

        <p>
          <a className='btn blue large' href={ import.meta.env.VITE_IO_SIGNIN_URL }>Sign In</a>
        </p>

        <FormButtons>
          <button className='btn blue' onClick={ this.close }>Close</button>
        </FormButtons>
      </div>
    )
  }
}

const mapState = ({ feedback }) => ({
  step: feedback.step,
  subject: feedback.subject
})

const Connected_FeedbackSignIn = connect(mapState)(_FeedbackSignIn)
export default Connected_FeedbackSignIn
