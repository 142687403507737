import React from 'react'
import { connect } from 'react-redux'
import $ from 'jquery'

import sized from 'components/Mixins/sized'
import { isFullscreen, setFullscreen } from 'slices/view'
import ToastNotifications from 'components/ToastNotifications'


class RoutedPageContainer extends React.Component {
  // Boostrap container class wrapping the whole app
  containerClass = () => (this.props.isFullscreen || this.props.kiosk)
    ? 'container-fluid'
    : 'container'


  handleFullscreenChange() {
    if (this.props.isFullscreen) {
      $('#main').width('100%').height('100%')
      $('#main').css('padding', '20px 20px 20px 20px').css('margin', '0px')
      $('#subnav-block').hide()
      $('#adafruit-header, #adafruit-footer').hide()

      this._layout_height_timeout = setTimeout(() => {
        $(".grid-layout").css('height', $(window).height() - 50)
      }, 600)

    } else {
      $('#main').removeAttr('style')
      $('#subnav-block').show()
      $('#adafruit-header, #adafruit-footer').show()

      this._layout_height_timeout && clearTimeout(this._layout_height_timeout)
    }
  }

  componentDidMount() {
    // Since this is the highest level Component, put document listeners here.
    $(document).on('fullscreenchange', () => {
      this.props.setFullscreen(document.fullscreenEnabled && document.fullscreenElement)
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.isFullscreen !== prevProps.isFullscreen) {
      this.handleFullscreenChange()
    }
  }

  render() {
    const
      { client, main, heading } = this.props,
      clientReady = !!client

    return (
      <div id="fullscreen">
        <div id="main" role="main">
          <div id="content-container" className={ this.containerClass() }>
            { heading }

            <div className="row">
              { clientReady ? main : 'Loading client...'}
            </div>
          </div>
        </div>
        <ToastNotifications />
      </div>
    )
  }
}

const
  mapState = state => ({
    isFullscreen: isFullscreen(state),
    kiosk: state.browsing.kiosk,
    loading: state.session.loading,
    client: state.session.client,
    user: state.session.user,
  }),

  mapDispatch = dispatch => ({
    setFullscreen: value => dispatch(setFullscreen(value))
  })

const ConnectedRoutedPageContainer = connect(mapState, mapDispatch)(sized(RoutedPageContainer))
export default ConnectedRoutedPageContainer
