import { once } from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'

import BrowsingActions from 'actions/browsing'


const setKioskFromQueryStringOnce = once( revealKiosk => {
  const qs = queryString.parse(window.location.search)

  if(qs.kiosk?.toLowerCase() === 'true') {
    revealKiosk()
  }
})

const useKiosk = () => {
  const
    // wrap the old redux actions
    kiosk = useSelector(state => state.browsing.kiosk),
    dispatch = useDispatch(),
    setKiosk = newKiosk => {
      dispatch(BrowsingActions.browsingSetKiosk(newKiosk))
    },
    // expose a nice api
    kioskVisible = kiosk,
    revealKiosk = () => setKiosk(true),
    hideKiosk = () => setKiosk(false)

  useEffect(() => {
    setKioskFromQueryStringOnce(revealKiosk)
  }, [])

  return { kioskVisible, setKiosk, revealKiosk, hideKiosk }
}

export default useKiosk
