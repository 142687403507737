import React from 'react'
import { createRoot } from 'react-dom/client'
import PropTypes from 'prop-types'

import { Button3d } from 'components/Buttons'
import SimpleModal from './SimpleModal'

export default class ConfirmModal extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    type: PropTypes.string.isRequired,
    confirmLabel: PropTypes.string,
    onConfirm: PropTypes.func,
    onDeny: PropTypes.func,
    onHide: PropTypes.func
  }

  constructor(...args) {
    super(...args)

    this.state = {
      show: true
    }
  }

  hide = () => {
    this.setState({ show: false })
    this.props.onHide?.()
  }

  onConfirm = () => {
    this.props.onConfirm?.()
    this.hide()
  }

  onDeny = () => {
    this.props.onDeny?.()
    this.hide()
  }

  renderFooter() {
    return this.props.type === 'alert'
      ? <Button3d action={ this.hide }>Close</Button3d>
      : this.props.type === 'confirm'
        ? <>
            <Button3d red action={ this.onConfirm }>{ this.props.confirmLabel }</Button3d>
            <Button3d action={ this.onDeny }>Cancel</Button3d>
          </>
        : null
  }

  render() {
    return (
      <SimpleModal
        id='confirmation-modal'
        title={ this.props.title }
        show={ this.state.show }
        onHide={ this.hide }
        footer={ this.renderFooter() }
      >
        <div className='section'>
          { this.props.children }
        </div>
      </SimpleModal>
    )
  }
}

export const popoverAlert = (options) => {
  options = {
    // Defaults
    title: "Alert",
    type: 'alert',
    // Given
    ...options
  }

  const message = options.message
  delete options.message

  renderModal(message, options)
}

export const confirmation = (options={}) => {
  // Must specify what to do upon confirmation
  if(!options.onConfirm) { throw new Error("Must pass onConfirm callback to confirmation()") }

  options = {
    // Defaults
    title: "Warning",
    type: 'confirm',
    confirmLabel: "Confirm",
    // Given
    ...options
  }

  const message = options.message || "Are you sure?"
  delete options.message

  renderModal(message, options)
}

const renderModal = (message, options) => {
  const
    // Where in DOM to mount
    wrapper = document.body.appendChild(document.createElement('div')),
    root = createRoot(wrapper),
    // Clean DOM after closed
    removeWrapper = () => {
      setTimeout(() => {
        root.unmount()
        if (document.body.contains(wrapper)) {
          document.body.removeChild(wrapper)
        }
      }, 600)
    }

  // Mount it!
  root.render(
    <ConfirmModal { ...options } onHide={ removeWrapper }>
      { message }
    </ConfirmModal>
  )
}
