import { find } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { selectDevices } from 'slices/devices'
import Link from 'components/Elements/IOLink'


const
  sharedPropTypes = {
    deviceKey: PropTypes.string.isRequired
  },

  ConnectionNotification = ({ device, deviceKey }) => <span>
    Connection: <Link route="device" params={{ key: deviceKey }}>{ device?.name || deviceKey }</Link>
  </span>,

  DisconnectionNotification = ({ device, deviceKey }) => <span>
    Disconnection: <Link route="device" params={{ key: deviceKey }}>{ device?.name || deviceKey }</Link>
  </span>

DisconnectionNotification.propTypes = sharedPropTypes
ConnectionNotification.propTypes = sharedPropTypes

const
  mapState = (state, { device, deviceKey }) => ({
    device: device || find(selectDevices(state), { key: deviceKey }),
  })

export const
  ConnectedConnectionNotification = connect(mapState)(ConnectionNotification),
  ConnectedDisconnectionNotification = connect(mapState)(DisconnectionNotification)
