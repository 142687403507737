import React from 'react'

import useKiosk from 'effects/useKiosk'
import KioskLayout from 'components/layout/KioskLayout'
import IOHeader from 'components/layout/IOHeader/index'
import IOContent from 'components/layout/IOContent'
import IOFooter from 'components/layout/IOFooter'


const IOLayout = ({ children }) => {
  const { kioskVisible } = useKiosk()

  if(kioskVisible) {
    return <KioskLayout>{ children }</KioskLayout>
  }

  return (
    <div id="outer-wrapper">
      <div id="inner-wrapper">
        <IOHeader />

        <IOContent>{ children }</IOContent>

        <IOFooter />
      </div>
    </div>
  )
}

export default IOLayout
