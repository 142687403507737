import { createActions } from 'redux-actions'

import Session from './session'
import Charts from './charts'
import { buildAPIActions } from '../utils/actions'
import { addTime } from 'utils/dates'
import { Paths } from 'utils/paths'

// build all available API actions
const Feeds = buildAPIActions('feeds');

Object.assign(Feeds, createActions({ },
  // identity function actions
  'FEEDS_SELECT',
  'FEEDS_CLEAR_CURRENT',
  'FEEDS_MAP_CHART_TOGGLE',
  'FEEDS_LINE_CHART_TOGGLE',
  'FEEDS_UPDATE_CHART_FILTER',
  'FEEDS_RESET_CHART_FILTER',
))

// Primary method for loading non-charting dashboard data for feeds.
Feeds.dataLoad = (feed, params={}) => {
  return (dispatch, getState) => {
    const client = Session.getClient(dispatch, getState)
    const actionType = ('resolution' in params) ? 'AGGREGATE_FEED_DATA_LOAD' : 'FEED_DATA_LOAD'

    const url = Paths.api_data({ username: feed.username, key: feed.key, query: params })

    return dispatch({
      type: actionType,
      payload: Session.ajax(client, url).then(resp => {
        return {
          feed,
          params,
          data: resp,
        }
      })
    })
  }
}

// get options (parameters) for loading data for a given feed in the context of
// a given block. In here becuase it's used by multiple actions.
Feeds.feedDataOptionsForBlock = (block) => {
  let options = {
    limit: 1
  }

  if (block.properties && block.properties.historyHours) {
    let historyHours = parseFloat(block.properties.historyHours)
    if (!isNaN(historyHours) && historyHours > 0) {
      options.start_time = addTime(new Date(), -historyHours, 'hours')
      options.limit = 1000
    } else {
      options.start_time = addTime(new Date(), 0, 'hours')
      options.limit = 1
    }
  }

  return options
}

// Gets multiple feeds with filtered results based on the given chart type.
// This is how day / week / month charts are loaded.
Feeds.chartSearch = (chartType) => {
  return (dispatch, getState) => {
    const state = getState()

    const chart = state.feedCharts[chartType]
    const { feeds, filter } = chart
    const range = filter.range

    if (!feeds) {
      // console.error("No feeds for chart", { chart, chartType, feeds, filter })
      return;
    }

    let hours = 0.1;

    switch (range) {
      case 'day':
        hours = 24
        break
      case 'week':
        hours = 24 * 7
        break
      case 'month':
        hours = 24 * 30
        break
      case 'year':
        hours = 24 * 365
        break
    }

    feeds.forEach(feed => {
      const chartID = `feed-${range}-${feed.key}`
      dispatch(Charts.load(feed, chartID, { hours }))
    })
  }
}

export default Feeds;
