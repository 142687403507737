import React from 'react'

import keyFormImage from 'images/guides/public_key_form.png'

const title = "Guide to Public Key Authentication"

const pages = [
  () => <>
    <h2>What is Public Key Authentication?</h2>

    <p>
      <strong>Public Key Authentication</strong> is a special way of signing
      in to Adafruit IO from code without ever sending your password over
      the network. Secrets stay on your device, where they belong!
    </p>
  </>,

  () => <>
    <h2>The Process</h2>

    <ol>
      <li>Generate an RSA key pair on your machine</li>
      <li>Add the public key to the Device in Adafruit IO</li>
      <li>Generate a JSON Web Token (JWT)</li>
      <li>Sign in with username and JWT as password</li>
    </ol>
  </>,

  () => <>
    <h2>Generate an RSA Keypair</h2>

    <p>In your terminal:</p>

    <p>
      <code>ssh-keygen -t rsa -b 4096 -C "your_email@example.com"</code>
    </p>

    <p>Once complete, you should have 2 files:</p>
    <ol>
      <li><strong>Public Key</strong>: something like <code>id_rsa.pub</code>, safe to share</li>
      <li><strong>Private Key</strong>: something like <code>id_rsa</code>, <em>never share</em></li>
    </ol>
  </>,

  () => <>
    <h2>Add the Public Key to your Device in Adafruit IO</h2>

    <p>Paste the contens of your public key file into the Add Public Key form
    for your Device.</p>

    <img src={keyFormImage} className="guide-image" alt="Public Key Form" />

    <p>After submitted, note the "PKID" field of your new Public Key record,
    you'll need it to generate the JWT in the next step.</p>
  </>,

  () => <>
    <h2>How to Generate a JSON Web Token (JWT)</h2>

    <p>Must include <code>pkid</code> (ID of your public key on Adafruit IO) and <code>exp</code>.</p>

    <p><em>More information coming soon.</em></p>
  </>,

  () => <>
    <h2>Signing in with a JWT</h2>

    <p>Use your username and the JWT as a password.</p>

    <p><em>Examples coming soon.</em></p>

    { /*
      <p>Example in Ruby</p>
      <p>Example in Python</p>
      <p>Example in Node.js</p>
    */ }
  </>
]

export {
  title,
  pages
}
