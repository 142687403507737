import React from 'react'

const title = "Guide to Device Access Tokens"

const pages = [
  () => <>
    <h2>What are Adafruit IO Device Access Tokens?</h2>

    <p>
      <strong>Device Access Tokens</strong> are a shared secret, generated by
      Adafruit IO, that you use in place of a password when creating a
      connection to Adafruit IO from a device by HTTP or MQTT.
    </p>
  </>,

  () => <>
    <h2>Keep Them Safe, Like A Password</h2>

    <p>
      Adafruit IO Device Access Tokens are sensitive information! Keep them
      secret and secure, like you would for a password.
    </p>
  </>,

  () => <>
    <h2>Why use this scheme?</h2>

    <p>
      The idea is to use separate access credentials for each of your
      devices on IO. This allows you to manage your devices individually. For
      example, you can revoke a single device without taking all your devices
      offline (which is what happens when you regenerate your AIO key if all
      of your devices are using it!)
    </p>
  </>,

  () => <>
    <h2>Signing in with an Access Token</h2>

    <p>Use your username and the Access Token as a password.</p>

    <p><em>Examples coming soon.</em></p>

    { /*
      <p>Example in Python</p>
      <p>Example in Arduino C++</p>
      <p>Example in Ruby</p>
      <p>Example in Javascript</p>
    */ }
  </>
]

export {
  title,
  pages
}
