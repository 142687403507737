import { pick, snakeCase } from 'lodash'
import { pluralize } from 'inflection'

import log from 'utils/logger' // eslint-disable-line
import Session from '../actions/session'
import { generatePath } from 'utils/paths'

const v2_api = {
  activities: {
    all: '/api/v2/:username/activities',
    get: '/api/v2/:username/activities/:id',
    destroy: '/api/v2/:username/activities',
  },
  dashboards: {
    all: '/api/v2/:username/dashboards',
    create: ['/api/v2/:username/dashboards', ['dashboard']],
    get: '/api/v2/:username/dashboards/:id',
    replace: ['/api/v2/:username/dashboards/:id', ['dashboard']],
    destroy: '/api/v2/:username/dashboards/:id',
  },
  dynamicActions: {
    all: '/api/v2/:username/dynamic_actions',
    create: ['/api/v2/:username/dynamic_actions', ['dynamic_action']],
    get: '/api/v2/:username/dynamic_actions/:id',
    run: '/api/v2/:username/dynamic_actions/:id/run',
    replace: ['/api/v2/:username/dynamic_actions/:id', ['dynamic_action']],
    destroy: '/api/v2/:username/dynamic_actions/:id'
  },
  blocks: {
    all: '/api/v2/:username/dashboards/:dashboard_id/blocks',
    create: ['/api/v2/:username/dashboards/:dashboard_id/blocks', ['block']],
    get: '/api/v2/:username/dashboards/:dashboard_id/blocks/:id',
    replace: ['/api/v2/:username/dashboards/:dashboard_id/blocks/:id', ['block']],
    destroy: '/api/v2/:username/dashboards/:dashboard_id/blocks/:id',
  },
  data: {
    all: '/api/v2/:username/feeds/:feed_key/data',
    create: ['/api/v2/:username/feeds/:feed_key/data', ['datum']],
    get: '/api/v2/:username/feeds/:feed_key/data/:id',
    destroy: '/api/v2/:username/feeds/:feed_key/data/:id',
  },
  groups: {
    all: '/api/v2/:username/groups',
    create: ['/api/v2/:username/groups', ['group']],
    get: '/api/v2/:username/groups/:group_key',
    replace: ['/api/v2/:username/groups/:group_key', ['group']],
    destroy: '/api/v2/:username/groups/:group_key',
    add_feed: ['/api/v2/:username/groups/:group_key/add', ['feed_key']],
    remove_feed: ['/api/v2/:username/groups/:group_key/remove', ['feed_key']]
  },
  feeds: {
    all: '/api/v2/:username/feeds',
    create: ['/api/v2/:username/feeds', ['feed']],
    get: '/api/v2/:username/feeds/:feed_key',
    replace: ['/api/v2/:username/feeds/:feed_key', ['feed']],
    details: '/api/v2/:username/feeds/:feed_key/details',
    destroy: '/api/v2/:username/feeds/:feed_key'
  },
  triggers: {
    all: '/api/v2/:username/triggers',
    create: ['/api/v2/:username/triggers', ['trigger']],
    get: '/api/v2/:username/triggers/:id',
    replace: ['/api/v2/:username/triggers/:id', ['trigger']],
    destroy: '/api/v2/:username/triggers/:id'
  }
}

// Converts the above short-hand strings and array into objects with path generator functions:
// '/api/v2/:username/triggers' becomes ->
//   { url: ({username }) => `/api/v2/${username}/triggers` }
// [ '/api/v2/:username/triggers/:id', ['trigger'] ] becomes ->
//   { url: ({username, id }) => `/api/v2/${username}/triggers/${id}`, args: ['trigger'] }
Object.values(v2_api).forEach(endpoint => {
  Object.keys(endpoint).forEach(action => {
    if (typeof endpoint[action] === 'string') {
      endpoint[action] = {
        url: generatePath(endpoint[action])
      }
    } else {
      endpoint[action] = {
        url: generatePath(endpoint[action][0]),
        args: endpoint[action][1]
      }
    }
  })
})


// request method lookup, defaults to GET
const operationMethods = {
  create: 'POST',
  add_feed: 'POST',
  group_feed_create: 'POST',
  remove_feed: 'POST',
  run: 'POST',
  destroy: 'DELETE',
  replace: 'PUT',
  update: 'PATCH',
}
const methodFor = operation => operationMethods[operation] || 'GET'

const buildAPIAction = (api, operation) => {
  const
    endpoint = v2_api[api][operation],
    actionType = `${snakeCase(api)}_${operation}`.toUpperCase(),
    defaultOptions = {
      method: methodFor(operation),
      includeHeaders: true,
    }

  return (args={}, headers={}) => {
    return (dispatch, getState) => {
      const client = Session.getClient(dispatch, getState)

      if (!client) { return }

      args.username ||= client.username

      const
        url = endpoint.url(args),
        options = {
          ...defaultOptions,
          headers,
          // endpoint.args is an array of keys to pick from given args
          body: endpoint.args && JSON.stringify(pick(args, endpoint.args))
        }

      return dispatch({
        type: actionType,
        payload: Session.ajax(client, url, options)
          .then(({ headers, body }) => ({
              headers, object: body
          }))
      })
    }
  }
}

const ENSURE_FRESHNESS_IN_SECONDS = 10
export const buildAPIActions = name => {
  const
    actions = {},
    operations = v2_api[name] || {},
    plural = pluralize(name),
    PLURAL = snakeCase(plural).toUpperCase()

  Object.keys(operations).forEach(operation => {
    actions[operation] = buildAPIAction(name, operation)
  })

  if(actions.all) {
    actions.ensureAllFresh = () => (dispatch, getState) => {
      const
        state = getState(),
        meta = state[plural][`${plural}_meta`] || {},
        { loading: isLoading, completed_at } = meta,
        isFresh = completed_at
          && (Date.now() - completed_at < ENSURE_FRESHNESS_IN_SECONDS * 1000)

      if(isLoading || isFresh) { return Promise.resolve() }

      return dispatch(actions.all())
    }
  }

  actions.resetErrors = () => ({
    type: `${PLURAL}_RESET_ERRORS`,
    payload: null
  })

  return actions
}
