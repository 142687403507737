import { find, mapValues } from 'lodash'
import { createSlice, createSelector } from '@reduxjs/toolkit'


const { actions, reducer } = createSlice({
  name: 'boards',
  initialState: {
    boards: {}
  },
  reducers: {
    setBoards: (state, { payload: boards }) => ({ ...state, boards }),
  },
})

export const
  // ACTIONS
  setBoards = boards => dispatch => {
    const enhancedBoards = mapValues(boards, board => {
      // chance to add extra frontend info to board defs when they're set
      if(board.mcuName.startsWith('rp2')) {
        board.isRP2 = true // Raspberry Pi 2's have a lot of special firmware instructions
      }

      // new and optional item in the JSON
      // remove if/when all boards get one and it becomes required
      board.bootDiskName = board.bootDiskName || 'boardnameBOOT'

      return board
    })

    dispatch(actions.setBoards(enhancedBoards))
  },

  // SELECTORS
  boardsState = state => state.boards,

  selectAllBoards = createSelector([boardsState], state => state.boards),

  // QUERIES
  getBoardByName = (state, boardName) => {
    const
      allBoards = selectAllBoards(state),
      board = allBoards[boardName]

    return board
  },

  getUARTPinsForBoard = (state, boardName) => {
    const
      pins = getBoardByName(state,boardName)?.components.digitalPins,
      tx = find(pins, "uartTx")?.name,
      rx = find(pins, "uartRx")?.name

    // both or nothing
    return (tx && rx) ? { tx, rx } : null
  }


export default reducer
