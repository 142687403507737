import { toPairs } from 'lodash'

import Feeds from './feeds'
import Blocks from './blocks'
import { buildAPIActions } from 'utils/actions'
import logException from 'utils/errors'
import log from 'utils/logger' // eslint-disable-line

import Session from './session'
import { Paths } from 'utils/paths'

// build all available API actions
const Dashboards = buildAPIActions('dashboards');

// clear current dashboards.dashboard record
Dashboards.select = (params) => ({
  type: 'DASHBOARDS_SELECT',
  payload: params
})

Dashboards.toggleVerticalCompacting = (val) => ({ type: 'DASHBOARDS_TOGGLE_VERTICAL_COMPACTING', payload: val })
Dashboards.startEditing = () => ({type: 'DASHBOARDS_START_EDITING'})
Dashboards.stopEditing =  () => ({type: 'DASHBOARDS_STOP_EDITING'})
Dashboards.layoutSaved =  () => ({type: 'DASHBOARDS_LAYOUT_SAVED'})
Dashboards.updateViewer = (payload) => ({ type: 'DASHBOARDS_VIEWER_CHANGED', payload })
Dashboards.updateLayouts = (layouts) => {
  return {
    type: 'DASHBOARD_LAYOUTS_UPDATE',
    payload: layouts
  }
}

Dashboards.uploadHeaderImage = (record, file) => {
  return (dispatch, getState) => {
    const client = Session.getClient(dispatch, getState)
    const url = Paths.api_dashboard_file({ username: client.username, key: record.key })

    let formData = new FormData()
    formData.set('file', file)

    return dispatch({
      type: 'DASHBOARD_UPLOAD_HEADER_IMAGE',
      payload: Session.ajax(client, url, {
        method: "POST",
        headers: { 'Content-Type': null },
        body: formData
      })
    })
  }
}

Dashboards.clearLayoutViewer =  () => ({type: 'DASHBOARDS_CLEAR_LAYOUT'})

Dashboards.saveLayouts = (dashboard, layouts) => {
  return (dispatch, getState) => {
    const client = Session.getAuthenticatedClient(dispatch, getState)
    const url = Paths.api_dashboard_update_layouts({username: client.username, key: dashboard.key})

    if (client) {
      return dispatch({
        type: 'DASHBOARD_LAYOUTS_REPLACE',
        payload: Session.ajax(client, url, { method: 'POST', body: JSON.stringify({ layouts }) })
      })
    } else {
      return dispatch({
        type: 'DASHBOARD_LAYOUTS_REPLACE_REJECTED',
        payload: { message: 'no client available' }
      })
    }
  }
}

Dashboards.reload = (dashboard) => {
  return (dispatch) => {
    return dispatch(Dashboards.get({ username: dashboard.username, id: dashboard.key }))
  }
}

// fires all feed data requests for a given dashboard
Dashboards.load = ({ username, id }) => {

  return (dispatch) => {
    return dispatch(Dashboards.get({ username, id })).then((getResult) => {
      const dashboard = getResult.action.payload.object

      // Goal: only request each feed once per dashboard.
      //
      // Create a mapping of feeds required for this dashboard, according to
      // blocks + block_feeds.
      const feedRequests = {}

      if (!(dashboard && dashboard.blocks)) {
        return dispatch({
          type: "DASHBOARD_LOAD_ERROR",
          payload: {
            message: 'No blocks available'
          }
        });
      }

      try {
        dashboard.blocks.forEach(block => {
          if (block.visual_type === 'line_chart') {
            // immediately dispatch charting query
            dispatch(Blocks.loadCharts(block))
          } else {

            // collect individual feed requests, immediately trigger unique chart requests
            block.block_feeds.forEach(bf => {
              const { feed } = bf

              // accumulate feed data queries
              if (! feedRequests[feed.key]) {
                feedRequests[feed.key] = {
                  feed,
                  options: Feeds.feedDataOptionsForBlock(block, feed)
                }
              } else {
                // prefer greater limit and earlier start_time
                const { options } = feedRequests[feed.key]
                const newOpts = Feeds.feedDataOptionsForBlock(block, feed)

                if ((newOpts.start_time && !options.start_time) || newOpts.start_time < options.start_time) { options.start_time = newOpts.start_time }
                if ((newOpts.limit && !options.limit) || newOpts.limit > options.limit) { options.limit = newOpts.limit }
              }
            })
          }
        })
      } catch (ex) {
        logException(ex, { dashboard })
      }

      toPairs(feedRequests).forEach((kv) => {
        try {
          const { feed, options } = kv[1]
          // finally dispatch all those feed data requests
          dispatch(Feeds.dataLoad(feed, options))
        } catch (ex) {
          logException(ex, { dashboard })
        }
      })
    })
  }
}

export default Dashboards
