import React from 'react'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'

import SystemMessageActions from 'actions/system_messages'
import log from 'utils/logger' // eslint-disable-line

class SystemMessages extends React.Component {
  dismissButton(message) {
    return message.dismissable &&
      <a className="hide-button" aria-label="Close" onClick={() => { this.props.dismissMessage(message) }}>
        <FontAwesome name="times" />
      </a>
  }

  renderMessage(message) {
    const renderedMessage = message.component ?
      <span>{ message.component }</span> :
      <span dangerouslySetInnerHTML={{ __html: message.message }} />

    return (
      <div key={message.id} className={ `system-message ${message.level}` }>
        <div className="container">
          { renderedMessage }
          { this.dismissButton(message) }
        </div>
      </div>
    )
  }

  render() {
    return (this.props.messages.length > 0) &&
      <div className='system-messages'>
        {this.props.messages.map(this.renderMessage.bind(this))}
      </div>
  }
}

const
  mapState = state => ({
    messages: state.systemMessages.messages
  }),

  mapDispatch = dispatch => ({
    dismissMessage: ({ id, _local }) => {
      dispatch(SystemMessageActions.dismiss({ id, _local: !!_local }))
    },
  })

const ConnectedSystemMessages = connect(mapState, mapDispatch)(SystemMessages)
export default ConnectedSystemMessages
