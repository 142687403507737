import { isString, pick } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Navigation from 'utils/navigation'
import useRoutes from 'effects/useRoutes'

// nice color props that can be passed as a bare key
const COLOR_PROPS = ['blue', 'gray', 'red', 'green', 'white', 'gold']

const colorPropsToClasses = props => COLOR_PROPS.reduce(
  ((acc, color) => props[color] ? `${acc} ${color}` : acc)
, "")

const onClickFromProps = props => {
  const { disabled, action, route, params } = props

  if(disabled) { return undefined }

  if(action && route) {
    throw new Error(`Cannot specify 'action' and 'route' props: ${ action }, ${ route }`)
  }

  if(!(action || route)) {
    console.warn("Button does nothing: Must specify 'action' or 'route' prop, neither were provided and button is not disabled")
    return undefined
  }

  return actionToOnClick(action || useRoutes(route, params))
}

// supports route paths and functions
// TODO: test with a file download link
const actionToOnClick = action => {
  return isString(action)
    ? (action.startsWith("http") || action.startsWith("//"))
      ? () => window.location.href = action // external URL
      : () => Navigation.go(action) // internal path
    : action
}

// allows easy icon props without all the fa- prefixing
const iconNameToElement = (icon, defaultPrefix='fa-', defaultSet='fas') => {
  if(!icon) { return }

  const
    iconParts = icon.split(' '),
    iconSet = (iconParts.length === 2)
      ? iconParts[0]
      : defaultSet,
    iconName = iconParts[1] || iconParts[0],
    iconClassName = iconName.startsWith("fa-")
      ? `${iconSet} ${iconName}`
      : `${iconSet} ${defaultPrefix}${icon}`

  if(iconParts.length > 2) { throw new Error(`iconNameToElement received an icon with more than 1 space: ${icon}`) }

  return <i className={ iconClassName } />
}

// enforce whether label and/or icon are present, or children, never both
const propsToChildren = ({ children, label, icon }, noChildrenElement) => {
  if(children && (label || icon)) { throw new Error(`A Button was passed children AND a label or icon prop. Choose one style or the other.`) }

  return children || noChildrenElement
}

const passThruProps = props => pick(props, [ 'style', 'className' ])

export const
  TinyIconButton = props => {
    const
      { icon, tooltip, disabled } = props,
      iconElement = iconNameToElement(icon, 'fa-circle-'),
      colorClasses = colorPropsToClasses(props),
      onClick = onClickFromProps(props),
      classes = clsx('tiny-icon-button', colorClasses, { disabled })

    return <a title={ tooltip } className={ classes } onClick={ onClick } { ...passThruProps(props) }>
        { iconElement }
    </a>
  },

  LargeIconButton = props => {
    const
      { icon, tooltip, disabled } = props,
      iconElement = iconNameToElement(icon),
      colorClasses = colorPropsToClasses(props),
      onClick = onClickFromProps(props),
      classes = clsx('btn btn-icon', colorClasses, { disabled })

    return <button title={ tooltip } className={ classes } onClick={ onClick } { ...passThruProps(props) }>
      { iconElement }
    </button>
  },

  RoundedButton = props => {
    const
      { label, icon, disabled } = props,
      iconElement = iconNameToElement(icon, 'fa-circle-'),
      colorClasses = colorPropsToClasses(props),
      onClick = onClickFromProps(props),
      classes = clsx('btn rounded', colorClasses, { disabled }),
      children = propsToChildren(props, <>{ iconElement } { label }</>)

    return <button className={ classes } onClick={ onClick } { ...passThruProps(props) }>
      { children }
    </button>
  },

  SquareButton = props => {
    const
      { label, icon, disabled } = props,
      iconElement = iconNameToElement(icon, 'fa-circle-'),
      colorClasses = colorPropsToClasses(props),
      onClick = onClickFromProps(props),
      classes = clsx('btn btn-square', colorClasses, { disabled }),
      children = propsToChildren(props, <>{ iconElement } { label }</>)

    return <button className={ classes } onClick={ onClick } { ...passThruProps(props) }>
      { children }
    </button>
  },

  SmallButton3d = props => {
    const
      { label, disabled } = props,
      colorClasses = colorPropsToClasses(props),
      onClick = onClickFromProps(props),
      classes = clsx('btn btn-3d-small', colorClasses, { disabled }),
      children = propsToChildren(props, <>{ label }</>)

    return <button className={ classes } onClick={ onClick } { ...passThruProps(props) }>
      { children }
    </button>
  },

  Button3d = props => {
    const
      { label, disabled } = props,
      colorClasses = colorPropsToClasses(props),
      onClick = onClickFromProps(props),
      classes = clsx('btn btn-3d', colorClasses, { disabled }),
      children = propsToChildren(props, <>{ label }</>)

    return <button className={ classes } onClick={ onClick } { ...passThruProps(props) }>
      { children }
    </button>
  }

// Set proptypes on all buttons
const
  actionPropType = { action: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string
  ])},
  disabledPropType = { disabled: PropTypes.bool },
  iconPropType = { icon: PropTypes.string },
  labelPropType = { label: PropTypes.node },
  tooltipPropType = { tooltip: PropTypes.string },
  colorPropTypes = COLOR_PROPS.reduce((acc, color) => {
    acc[color] = PropTypes.bool
    return acc
  }, {})

TinyIconButton.propTypes = {
  ...actionPropType,
  ...disabledPropType,
  icon: iconPropType.icon.isRequired,
  tooltip: tooltipPropType.tooltip.isRequired,
}

LargeIconButton.propTypes = {
  ...actionPropType,
  ...disabledPropType,
  icon: iconPropType.icon.isRequired,
  tooltip: tooltipPropType.tooltip.isRequired,
  ...colorPropTypes
}

RoundedButton.propTypes = {
  ...actionPropType,
  ...disabledPropType,
  ...iconPropType,
  ...labelPropType,
  ...colorPropTypes
}

SquareButton.propTypes = {
  ...actionPropType,
  ...disabledPropType,
  ...labelPropType,
  ...colorPropTypes
}

SmallButton3d.propTypes = {
  ...actionPropType,
  ...disabledPropType,
  ...labelPropType,
  ...colorPropTypes
}

Button3d.propTypes = {
  ...actionPropType,
  ...disabledPropType,
  ...labelPropType,
  ...colorPropTypes
}
