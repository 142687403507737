import React from 'react'

import GuideLink from 'components/GuideViewer/GuideLink'

const title = "Quick Guide to Feeds"

const pages = [
  () => (
    <>
      <h2>What is a feed?</h2>

      <p>Feeds hold <GuideLink page={1}>data</GuideLink> and <GuideLink page={2}>metadata</GuideLink> about the values you send to Adafruit IO. Everything your code and devices save to Adafruit IO goes into a feed.</p>
    </>
  ),
  () => (
    <>
      <h2>What is feed &quot;data&quot;?</h2>

      <p>Data is the collection of values you send to a feed. If you send numerical data, we can put it on a chart for you. If you send words, color values, or blobs of text, we'll store those too!</p>

      <p>If your feed history is turned on, we save every single value you send, but you're limited to 1 kilobytes (1024 bytes) per value. If you turn off feed history, we'll only store the most recent value you've sent, but you can store up to 100 kilobytes (102400 bytes) at a time.</p>

      <p>You can change what kind of values you save to your feeds at any time.</p>
    </>
  ),
  () => (
    <>
      <h2>What is feed &quot;metadata&quot;?</h2>

      <p>Not only do feeds contain the <em>value</em> you sent to Adafruit IO - they contain information about when the value such as what group the data belongs to, when the data was created, and who can see the data.</p>
    </>
  ),
  () => (
    <>
      <h2>How can I send data to a feed?</h2>

      <p>You can send data to a feed with your own code using either a client library (we have libraries for <a href="https://github.com/adafruit/Adafruit_IO_Arduino">Arduino</a>, <a href="https://github.com/adafruit/Adafruit_IO_Python">Python</a>, and <a href="https://github.com/adafruit/io-client-go">Go</a>), the <a href="https://io.adafruit.com/api/docs/#feeds">Adafruit IO API</a>, or the <a href="https://learn.adafruit.com/welcome-to-adafruit-io/mqtt-api-documentation-2#topics-16-3">Adafruit IO MQTT API</a>.</p>

      <p>Or, you can send data from <a href="https://io.adafruit.com">io.adafruit.com</a> using dashboards or the "+ Add Data" button on your feed's home page.</p>
    </>
  ),
  () => (
    <>
      <h2>What else should I know about feeds?</h2>

      <p><em>Need to change the name of your feed, or other information about it?</em> <a href="https://learn.adafruit.com/adafruit-io-basics-feeds/editing-a-feed">You can edit your feeds through the Adafruit IO web panel</a>. <a href="https://learn.adafruit.com/adafruit-io-basics-feeds/sharing-a-feed">You can also share a feed with friends</a> or <a href="https://learn.adafruit.com/adafruit-io-basics-feeds/downloading-feed-data">download all the data from the feed at once</a>.</p>
    </>
  )
]

export {
  title,
  pages
}
