import React from 'react'

// Custom routing components
import { IORootRoute, IOIndexRootRoute, IORoute, IOIndexRoute,  IORedirect,
         IOIndexRedirect, IOShortcut, IOUsernameError } from 'routes/components'

// Non-App Routes
import Landing from 'views/Landing'
import Maintenance from 'views/Maintenance'
import NotFound from 'views/NotFound'
import UsernameError from 'views/UsernameError'

// Routed Pages
import RoutedPageContainer from 'components/router/RoutedPageContainer'


export default
  <IORootRoute path="/">
    <IOIndexRootRoute component={ Landing } />
    <IORoute path="/welcome" component={ Landing } />

    {/* Public shortcuts into the authenticated app */}
    <IORoute authOnly>
      <IOShortcut path="/public" to='public_user' />
      <IOShortcut path="/overview" to='overview' />
      <IOShortcut path="/privacy" to='privacy' />
      <IOShortcut path="/my-data" to='my_data' />
      <IOShortcut path="/activity" to='activity' />
      <IOShortcut path="/my-plan" to='my_plan' />

      <IOShortcut path="/dashboards" to='dashboards' />
      <IOShortcut path="/feeds" to='feeds' />
      <IOShortcut path="/devices" to='devices' />
      <IOShortcut path="/devices/new" to='new_device' />
      <IOShortcut path="/devices/new/:boardName" to='new_device_board' />
      <IOShortcut path="/devices/new/:boardName/step-:stepNumber" to='new_device_board_step' />
      <IOShortcut path="/devices/new/:boardName/:installMethod/step-:stepNumber" to='new_device_board_method_step' />
      <IOShortcut path="/actions" to='actions' />

      <IOShortcut path="/services" to='services' />
      <IOShortcut path="/services/time" to='service_time' />
      <IOShortcut path="/services/ifttt" to='service_ifttt' />
      <IOShortcut path="/services/weather" to='service_weather' />
      <IOShortcut path="/services/words" to='service_words' />
      <IOShortcut path="/services/zapier" to='service_zapier' />

      {/* redirect old route shortcuts to new */}
      <IORedirect from="profile" to="overview" />
      <IORedirect from="sharing" to="privacy" />
      <IORedirect from="monitor" to="overview" />
      <IORedirect from="activities" to="activity" />
      <IORedirect from="billing" to="my-plan" />
      <IORedirect from="triggers" to='actions' />
      <IORedirect from="wippersnapper*" to='devices*' />
    </IORoute>

    <IORoute component={ RoutedPageContainer }>
      {/* non-username pages */}
      <IORoute path="/contact"  page={() => import('views/Landing/support')}/>
      <IORoute path="/support"  page={() => import('views/Landing/support')}/>
      <IORoute path="/feedback" page={() => import('views/Landing/support')}/>

      <IORoute path="/faq"         page={() => import('views/Landing/faq')}/>
      <IORoute path="/plus"        page={() => import('views/Landing/plus')}/>
      <IORoute path="/icons-faq"   page={() => import('views/Landing/icons-faq')}/>
      <IORoute path="/help"        page={() => import('views/Landing/faq')}/>
      <IORoute path="/promotions"  page={() => import('views/Landing/promotions')}/>
      <IORoute path="/terms"       page={() => import('views/Landing/terms')}/>
      <IORoute path="/unsubscribe" page={() => import('views/Unsubscribe/index')}/>

      <IORoute path="/maintenance" components={{ main: Maintenance }} />
      <IORoute path="/notfound" components={{ main: NotFound }}/>
      <IOUsernameError path="/username-error" components={{ main: UsernameError }} />

      <IORoute authOnly>
        <IORoute path="/upgrade"         page={() => import('views/Subscriptions/payment_form_container')}/>
        <IORoute path="/upgrade/cancel"  page={() => import('views/Subscriptions/cancellation_form')}/>
        <IORoute path="/upgrade/payment" page={() => import('views/Subscriptions/payment_change_container')}/>
        <IORoute path="/upgrade/receipt" page={() => import('views/Subscriptions/payment_receipt_container')}/>

        <IORoute path="/sign_out"        page={() => import('views/Authentication/sign_out')}/>
      </IORoute>

      <IORoute path=":username">
        <IOIndexRedirect to="overview"/>

        <IORoute path="public" page={() => import('views/Profile/PublicPage')}/>

        <IORoute ownerOnly> {/* Profile */}
          <IORoute path="overview" page={() => import('views/Profile/OverviewPage')}/>
          <IORoute path="privacy" page={() => import('views/Profile/PrivacyAndSharingPage')}/>
          <IORoute path="privacy/share/:token" page={() => import('views/Profile/privacy_and_sharing/ShareReviewPage')}/>
          <IORoute path="my-plan" page={() => import('views/Profile/MyPlanPage')}/>
          <IORoute path="my-plan/invoice/:key" page={() => import('views/Profile/my_plan/Invoice')}/>
          <IORoute path="my-data" page={() => import('views/Profile/MyDataPage')}/>
          <IORoute path="activity" page={() => import('views/Profile/ActivityPage')}/>
        </IORoute>

        <IORoute ownerOnly path="devices">
          <IOIndexRoute page={() => import('views/WipperSnapper/list')}/>

          {/* Firmware Installer */}
          <IORoute path="new" page={() => import('views/WipperSnapper/new/index')}/>
          <IORedirect path="new/:boardName" to="new/:boardName/step-2" />
          <IORedirect path="new/:boardName/step-1" to="new" />
          <IORedirect path="new/:boardName/:installMethod/step-1" to="new" />
          <IORoute path="new/:boardName/step-:stepNumber" page={() => import('views/WipperSnapper/new/index')}/>
          <IORedirect path="new/:boardName/:installMethod" to="new/:boardName/:installMethod/step-2" />
          <IORoute path="new/:boardName/:installMethod/step-:stepNumber" page={() => import('views/WipperSnapper/new/index')}/>

          <IORoute path=":key" page={() => import('views/WipperSnapper/show')}/>

          {/* Firmware Updater */}
          <IORedirect path=":key/update-firmware" to=":key/update-firmware/step-2" />
          <IORedirect path=":key/update-firmware/step-1" to="/:username/devices" />
          <IORedirect path=":key/update-firmware/:installMethod/step-1" to="/:username/devices" />
          <IORoute path=":key/update-firmware/step-:stepNumber" page={() => import('views/WipperSnapper/new/index')}/>
          <IORedirect path=":key/update-firmware/:installMethod" to=":key/update-firmware/:installMethod/step-2" />
          <IORoute path=":key/update-firmware/:installMethod/step-:stepNumber" page={() => import('views/WipperSnapper/new/index')}/>
        </IORoute>

        <IORoute path="groups">
          <IOIndexRedirect to="/:username/feeds" />
          <IORoute path=":key" page={() => import('views/Groups/show')}/>
          <IORedirect path=":group_key/feeds/:key" to="/:username/feeds/:group_key.:key" />
        </IORoute>

        <IORoute path="feeds">
          <IOIndexRoute ownerOnly page={() => import('views/Feeds/list')}/>
          <IORoute path=":key" page={() => import('views/Feeds/show')}/>
        </IORoute>

        <IORoute path="dashboards">
          <IOIndexRoute ownerOnly page={() => import('views/Dashboards/list')}/>
          <IORoute path=":id" page={() => import('views/Dashboards/dashboard_container')}/>
        </IORoute>

        <IORoute ownerOnly path="actions">
          <IOIndexRoute page={() => import('views/Triggers/list')}/>
          <IORoute path="new" page={() => import('views/Triggers/form')}/>
          <IORoute path=":id/edit" page={() => import('views/Triggers/form')}/>
          <IORoute path="blockly/new" page={() => import('views/Triggers/blockly')}/>
          <IORoute path="blockly/:id/edit" page={() => import('views/Triggers/blockly')}/>
        </IORoute>

        <IORoute ownerBlocklyBetaOnly path="blockly-actions">
          <IOIndexRoute page={() => import('views/BlocklyActions/list')}/>
          <IORoute path=":id/edit" page={() => import('views/BlocklyActions/blockly')}/>
        </IORoute>

        <IORoute ownerOnly path="services">
          <IOIndexRoute page={() => import('views/Services/index')}/>
          <IORoute path="ifttt" page={() => import('views/Services/ifttt')}/>
          <IORoute path="zapier" page={() => import('views/Services/zapier')}/>
          <IORoute path="weather" page={() => import('views/Services/weather')}/>
          <IORoute path="messages" page={() => import('views/Services/messages')}/>
          <IORoute path="words" page={() => import('views/Services/words')}/>
          <IORoute path="time" page={() => import('views/Services/time')}/>
        </IORoute>

        <IORoute guideAccessOnly path="design_guide" page={() => import('views/DesignGuide/list')}/>

        {/* Legacy Redirects */}
        <IORedirect from="profile" to="overview" />
        <IORedirect from="sharing" to="privacy" />
        <IORedirect from="block/:user_to_block" to="privacy" />
        <IORedirect from="share/:token" to="privacy/share/:token" />
        <IORedirect from="monitor" to="overview" />
        <IORedirect from="activities" to="activity" />
        <IORedirect from="billing*" to="my-plan*" />
        <IORedirect from="triggers*" to="actions*" />
        <IORedirect from="wippersnapper*" to="devices*" />
        <IORedirect from=":dashboard_id" to="dashboards/:dashboard_id" />

        {/* Unmatched by now? Not Found! */}
        <IORoute ownerOnly path="*" components={{ main: NotFound }} />
      </IORoute>
    </IORoute>
  </IORootRoute>
