import { createActions } from 'redux-actions'

import { selectCurrentDevice, hasDeviceByKey, selectCurrentDeviceI2CSettings } from 'slices/devices'
import GroupActions from 'actions/groups'
import DataActions from 'actions/data'


const WprSnpr = {
  ...createActions({ },
    "WPRSNPR_CLEAR_CHECKIN",
    "WPRSNPR_HIDE_POPOVER",
    "WPRSNPR_CLEAR_I2C_ADDRESSES",
  ),

  wprsnprCheckin: payload => async (dispatch, getState) => {
    // ensure groups have been fetched
    await dispatch(GroupActions.ensureAllFresh())

    // check if we already have this wipper
    const deviceExists = hasDeviceByKey(getState(), payload.deviceId)

    // conditionally do the checkin process for new wippers
    return deviceExists || dispatch({ type: 'WPRSNPR_CHECKIN', payload })
  },

  i2cBusError: busError => dispatch => {
    const { type, message } = busError

    dispatch({ type: 'WPRSNPR_I2C_BUS_ERROR', payload: busError })

    // type tells what was happening when the error occurred, act appropriately
    switch(type) {
      case "scan":
        return dispatch({ type: 'WPRSNPR_SET_I2C_SCANNING', payload: false })

      case "deviceInit":
        // TODO: display an explanation why it was deleted
        break

      // These shouldn't be happening yet:
      // case "deviceUpdate":
      // case "deviceDeinit":

      default:
        throw new Error(`Unrecognized Bus Error Type: ${type}: ${message}`)
    }
  },

  i2cClearBusError: () => dispatch => {
    dispatch({ type: 'WPRSNPR_I2C_BUS_ERROR', payload: {} })
  },

  startI2CScan: i2cPortSettings => (dispatch, getState) => {
    const state = getState()
    i2cPortSettings = i2cPortSettings || selectCurrentDeviceI2CSettings(state)

    if(!i2cPortSettings) { throw new Error('No I2C Port Settings given or found for scan') }

    const key = selectCurrentDevice(state)?.key
    if(!key) { throw new Error('Cannot start I2C scan without a device selected') }

    // Clear what we have
    dispatch({ type: 'WPRSNPR_CLEAR_I2C_ADDRESSES' })
    dispatch(WprSnpr.i2cClearBusError())
    dispatch({ type: 'WPRSNPR_SET_I2C_SCANNING', payload: true })

    // Tell the broker to start the scan
    // (Comment this line out if using a mock, below)
    dispatch(DataActions.publishToWipperDeviceAction(key, "i2cScanAll", i2cPortSettings))

    // setTimeout(() => {
    //   // Mock a bus scan response
    //   dispatch(WprSnpr.setI2cAddressesFound([ 0x38, 0x76, 0x61, 0x20, 0x12 ]))
    //
    //   // Mock a bus scan error
    // }, 800)
    //   dispatch(WprSnpr.i2cBusError({ type: "scan", message: "THIS IS A BUS ERROR" }))
  },

  setI2cAddressesFound: addressesFound => dispatch => {
    dispatch({ type: 'WPRSNPR_SET_I2C_ADDRESSES', payload: addressesFound })
    dispatch({ type: 'WPRSNPR_SET_I2C_SCANNING', payload: false })
  }
}

export default WprSnpr
