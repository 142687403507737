import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { connect } from 'react-redux'

import { Paths } from 'utils/paths'


class Breadcrumbs extends React.PureComponent {
  static propTypes = {
    currentUsername: PropTypes.string,
    urlUsername: PropTypes.string,
    breadcrumbs: PropTypes.shape({
      set: PropTypes.bool.isRequired,
      options: PropTypes.shape({
        title: PropTypes.string,
        children: PropTypes.arrayOf(PropTypes.shape({
          to: PropTypes.string,
          label: PropTypes.string
        })),
        noHelp: PropTypes.bool,
        noUserLink: PropTypes.bool,
      })
    })
  }

  crumb = ({ to, label }) => (to
    ? <Link to={ to }>{ label }</Link>
    : <span className='title'>{ label }</span>
  )

  append(crumbs, sep, child) {
    if(crumbs.length > 0) { crumbs.push(<span key={ sep }>&nbsp;/&nbsp;</span>) }
    crumbs.push(<React.Fragment key={ sep + '-child' }>{ this.crumb(child) }</React.Fragment>)
  }

  userLink() {
    const { currentUsername, urlUsername, breadcrumbs: { set } } = this.props

    if (!set) {
      return <span>Loading...</span>

    } else if (currentUsername && urlUsername) {
      const
        pather = (urlUsername === currentUsername)
          ? Paths.profile
          : Paths.public_user,
        path = pather({ username: urlUsername })

      return <Link to={ path } key='user'>{ urlUsername }</Link>

    } else if (!currentUsername) {
      return <span className='title' key='user'>{ urlUsername }</span>
    }

    return <span className='title' key='user'></span>
  }

  renderCrumbs(options={ userLink: false }) {
    const
      { breadcrumbs } = this.props,
      { set } = breadcrumbs,
      breadcrumbContent = options.userLink
        ? [ this.userLink() ]
        : []

    if(!set) { return breadcrumbContent }

    const { children=[], title } = breadcrumbs.options

    children.forEach((child, idx) => {
      this.append(breadcrumbContent, `sep-${idx}`, child)
    })

    if (title) {
      this.append(breadcrumbContent, 'sep-title', { label: title })
    }

    return breadcrumbContent
  }

  renderHelp() {
    return !this.props.breadcrumbs.options.noHelp &&
      <a className="btn rounded gray" target="_blank" href="https://learn.adafruit.com/welcome-to-adafruit-io">
        <i className="fas fa-circle-question"/> Help
      </a>
  }

  render() {
    if (!this.props.breadcrumbs.set) { return null }

    const
      { noUserLink } = this.props.breadcrumbs.options,
      links = this.renderCrumbs({ userLink: !noUserLink }),
      mobile = this.renderCrumbs()

    return (
      <div className="breadcrumbs">
        <div className="container hidden-xs">
          <div className="row">
            <div className="col-sm-9 content">
              { links }
            </div>

            <div className="col-sm-3 help">
              { this.renderHelp() }
            </div>
          </div>
        </div>

        <div className="container hidden-sm visible-xs mobile">
          <div className="row">
            <div className="col-xs-12">
              <div className="content">
                  { mobile }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = state => ({
  breadcrumbs: state.breadcrumbs
})

const ConnectedBreadcrumbs = connect(mapState)(Breadcrumbs)
export default ConnectedBreadcrumbs
