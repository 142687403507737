import { createAction } from 'redux-actions'

import BlockTypes from 'components/Blocks/block_types'
import Charts from './charts'
import { allGroups } from 'selectors/groups'
import { buildAPIActions } from 'utils/actions'
import { iso8601 } from 'utils/dates'
import log from 'utils/logger' // eslint-disable-line

// build all available API actions
const Blocks = buildAPIActions('blocks');

Blocks.createWithForm = () => {
  return (dispatch, getState) => {
    const { dashboard } = getState().dashboards
    const { form } = getState().blockEditor

    // form stores feeds: [], but we need feed_ids: []
    const final = Object.assign({}, form)

    delete final['feeds'] // remove feeds
    delete final['id'] // no ID permitted

    // only keep required fields
    final.block_feeds = final.block_feeds.map(block_feed => {
      return {
        group_id: block_feed.group_id,
        feed_id: block_feed.feed_id
      }
    })

    // set from currently loaded Dashboard
    final.dashboard_id = dashboard.id

    return dispatch(Blocks.create({
      dashboard_id: dashboard.key,
      block: final
    }))
  }
}

Blocks.updateWithForm = () => {
  return (dispatch, getState) => {
    const { dashboard } = getState().dashboards
    const { form } = getState().blockEditor

    const final = Object.assign({}, form)
    const blockID = final['id']

    // drop unpermitted fields
    delete final['id'] // no id permitted
    delete final['feeds'] // remove feeds
    delete final['created_at'] // not updateable
    delete final['updated_at'] // not updateable

    // cleanup block_feeds
    final.block_feeds = final.block_feeds.map(block_feed => {
      return {
        group_id: block_feed.group_id,
        feed_id: block_feed.feed_id
      }
    })

    // set from currently loaded Dashboard
    final.dashboard_id = dashboard.id

    return dispatch(Blocks.replace({
      id: blockID,
      dashboard_id: dashboard.key,
      block: final
    })).then(({ action }) => {
      // take response from Blocks.replace and immediately reload charts
      if (action.payload && action.payload.object) {
        const block = action.payload.object
        if (block.visual_type === 'line_chart') {
          return dispatch(Blocks.loadCharts(action.payload.object))
        }
      }
    })
  }
}

Blocks.updateProperties = (block, newProperties) => {
  return (dispatch, getState) => {
    // assume current dashboard
    const { dashboard } = getState().dashboards
    const properties = Object.assign({}, block.properties, newProperties)

    return dispatch(Blocks.replace({
      id: block.id,
      dashboard_id: dashboard.key,
      block: { properties }
    })).then(({ action }) => {
      // take response from Blocks.replace and immediately reload charts
      if (action.payload && action.payload.object) {
        const block = action.payload.object
        if (block.visual_type === 'line_chart' || block.visual_type === 'map') {
          return dispatch(Blocks.loadCharts(action.payload.object))
        }
      }
    })
  }
}

Blocks.toggleModal = createAction('BLOCKS_TOGGLE_MODAL', isVisible => isVisible)

Blocks.setEditorBlock = createAction('BLOCKS_SET_EDITOR_BLOCK', block => {
  return {
    block,
    definition: BlockTypes[block.visual_type]
  }
})

Blocks.selectEditorStep = createAction('BLOCKS_SELECT_EDITOR_STEP', step => step)
Blocks.resetSelectedFeeds = createAction('BLOCKS_RESET_SELECTED_FEEDS')

Blocks.showGroup = createAction('BLOCKS_EDITOR_SHOW_GROUP', group => group.id)
Blocks.hideGroup = createAction('BLOCKS_EDITOR_HIDE_GROUP', group => group.id)
Blocks.resetGroupHiding = () => {
  return (dispatch, getState) => {
    const state = getState()
    const user = state.session.user
    const groups = allGroups(state)

    return dispatch({
      type: 'BLOCKS_EDITOR_RESET_GROUP_HIDING',
      payload: { groups, user }
    })
  }
}

Blocks.setCreateType = (visual_type) => {
  return {
    type: 'BLOCKS_SET_CREATE_TYPE',
    payload: {
      blockType: visual_type,
      block: BlockTypes[visual_type]
    }
  }
}

// the form block_feed records must take when passed into the IO API
Blocks.toBlockFeed = ({ group, feed }) => ({
  group,
  feed,
  group_id: group.id,
  feed_id: feed.id
})

// assigning feeds to a block
Blocks.selectFeeds = createAction('BLOCKS_SELECT_FEEDS', group_feeds => group_feeds)

// updating form fields in block editor
Blocks.updateBlockName = createAction('BLOCKS_UPDATE_BLOCK_NAME', name => name)
Blocks.updateBlockProperty = createAction('BLOCKS_EDITOR_UPDATE_BLOCK_PROPERTY', prop => prop)

Blocks.resetEditor = createAction('BLOCKS_RESET_EDITOR')

Blocks.loadCharts = (block) => {
  return (dispatch) => {
    block.block_feeds.forEach(bf => {
      const { feed } = bf
      const chartID = `block-${block.id}-feed-${feed.key}`

      let chartParams = {}

      let end_time = iso8601(new Date())
      chartParams['end_time'] = end_time

      // get hours property from block settings
      let hours = 0
      let historyHours = parseInt(block.properties.historyHours)
      if (historyHours && !isNaN(historyHours)) { hours = historyHours }
      chartParams['hours'] = hours

      // handle raw data requests
      let rawDataOnly = !!block.properties.rawDataOnly
      if (rawDataOnly) {
        chartParams['raw'] = 'true'
      }

      return dispatch(Charts.load(feed, chartID, chartParams))
    })
  }
}

export default Blocks
