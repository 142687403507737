import { createActions } from 'redux-actions'

import log from 'utils/logger'
import Session from './session'
import { Paths } from 'utils/paths'
import ProfileActions from 'actions/profiles'


const Plans = createActions({
},
  'GET_SUBSCRIPTION_PENDING',
  'CANCEL_SUBSCRIPTION_PENDING',
  'CANCEL_SUBSCRIPTION_FULFILLED')

Plans.getSubscription = () => {
  return (dispatch, getState) => {
    const client = Session.getAuthenticatedClient(dispatch, getState)

    if (client) {
      dispatch(Plans.getSubscriptionPending())
      return dispatch({
        type: 'GET_SUBSCRIPTION',
        payload: Session.ajax(client, Paths.api_subscription({ username: client.username}), { method: 'GET' })
      })
    }

    return {}
  }
}

Plans.cancelSubscription = () => {
  return (dispatch, getState) => {
    const
      client = Session.getAuthenticatedClient(dispatch, getState),
      { username } = client

    log('[Plans cancelSubscription]')
    return dispatch({
      type: 'CANCEL_SUBSCRIPTION',
      payload: Session.ajax(client, Paths.api_subscription({ username }), {
        method: 'DELETE'
      })
    }).then(() => {
      dispatch(ProfileActions.reload({ username }))
    })
  }
}

Plans.createSubscriptionWithStripeToken = (token, planID) => {
  return (dispatch, getState) => {
    const
      client = Session.getAuthenticatedClient(dispatch, getState),
      { username } = client

    log('[Plans createSubscriptionWithStripeToken]')
    return dispatch({
      type: 'CREATE_SUBSCRIPTION',
      payload: Session.ajax(client, Paths.api_subscription({ username }), {
        method: 'POST',
        body: JSON.stringify({
          plan_id: planID,
          token: token
        })
      })
    }).then(() => {
      dispatch(ProfileActions.reload({ username }))
    })
  }
}

Plans.updateSubscriptionWithStripeToken = (token) => {
  return (dispatch, getState) => {
    const
      client = Session.getAuthenticatedClient(dispatch, getState),
      { username } = client

    log('[Plans createSubscriptionWithStripeToken]')
    return dispatch({
      type: 'UPDATE_SUBSCRIPTION',
      payload: Session.ajax(client, Paths.api_subscription({ username }), {
        method: 'PUT',
        body: JSON.stringify({
          token: token
        })
      })
    }).then(() => {
      dispatch(ProfileActions.reload({ username }))
    })
  }
}

Plans.changeSubscriptionPlan = (stripe_id) => {
  return (dispatch, getState) => {
    const
      client = Session.getAuthenticatedClient(dispatch, getState),
      { username } = client

    log("DOING subscription plan change to", stripe_id)
    return dispatch({
      type: 'CHANGE_SUBSCRIPTION_PLAN',
      payload: Session.ajax(client, Paths.api_subscription_change({ username }), {
        method: 'PUT',
        body: JSON.stringify({plan_id: stripe_id})
      })
    }).then(() => {
      dispatch(ProfileActions.reload({ username }))
    })
  }
}

export default Plans
