import React from 'react'
import { connect } from 'react-redux'

import SimpleModal from 'components/Elements/SimpleModal'
import FeedbackActions from 'actions/feedback'


const
  FeedbackModal = props =>
    <SimpleModal id='aio-feedback-modal' title="Adafruit IO Feedback" show={ props.show } onHide={ props.close }>
      { props.children }
    </SimpleModal>,

  mapState = (state, { identifier }) => ({
    show: (state.feedback.showModal.identifier === identifier && state.feedback.showModal.show)
  }),

  mapDispatch = (dispatch, { identifier }) => ({
    close: () => dispatch(FeedbackActions.toggleModal(false, identifier))
  })

const ConnectedFeedbackModal = connect(mapState, mapDispatch)(FeedbackModal)
export default ConnectedFeedbackModal
