import React from 'react'
import { useSelector } from 'react-redux'

import { usernameSelector, hasBlocklyAccess } from 'selectors/users'
import { useNavigateOnClick } from 'effects/useRoutes'


const HeaderNavigation = () => {
  const showBlockly = useSelector(hasBlocklyAccess)

  if (!useSelector(usernameSelector)) { return }

  return (
    <ul className="main-nav-links">
      <li><HeaderNavButton text="Devices" /></li>
      <li><HeaderNavButton text="Feeds" /></li>
      <li><HeaderNavButton text="Dashboards" /></li>
      <li>
        <HeaderNavButton text="Actions" route="legacy_actions" />
        { showBlockly && <HeaderNavButton text="🧩" route="blockly_actions" title="Blockly Actions Beta" /> }
      </li>
      <li><HeaderNavButton text="Power-Ups" route="services" /></li>
    </ul>
  )
}

export default HeaderNavigation

const HeaderNavButton = ({ title, text, route }) => {
  if(!text) { throw new Error("text prop required for HeaderNavButton") }

  const navigate = useNavigateOnClick()

  route = route || text.toLowerCase()

  return (
    <button onClick={navigate(route)} title={ title || text }>
      { text }
    </button>
  )
}
