import React from 'react'
import { Toaster } from 'react-hot-toast'


const ToastNotifications = () =>
  <Toaster
    position="bottom-right"
    toastOptions={{
      duration: 5000
    }}
  />

export default ToastNotifications
