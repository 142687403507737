import { useEffect, useState } from 'react'

/* very simple effect that takes 2 values for a piece of state,
   the first value gets set immediately
   the next value is set a moment later */
export default (initialValue, delayedValue, delay=1) =>{
  const [delayedState, setDelayedState] = useState(initialValue)

  useEffect(() => {
    const timeoutId = setTimeout(() => setDelayedState(delayedValue), delay)
    return () => clearTimeout(timeoutId)
  })

  return delayedState
}
