import { createSlice } from '@reduxjs/toolkit'

const { actions, reducer } = createSlice({
  name: 'view',
  initialState: {
    fullscreen: false,
  },
  reducers: {
    setFullscreen: (state, payload=true) => ({ ...state, fullscreen: !!payload })
  },
})

export const
  { setFullscreen } = actions,
  isFullscreen = state => state.fullscreen

export default reducer
