import { handleActions } from 'redux-actions'

const initialState = {
  error: null,
  kiosk: false
}

export default handleActions({
  BROWSING_NOT_FOUND: state => ({ ...state, error: 'not_found' }),

  BROWSING_RESET: state => ({ ...state, error: null }),

  BROWSING_SET_KIOSK: (state, { payload }) => ({ ...state, kiosk: payload }),
}, initialState)
