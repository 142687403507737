import { createActions } from 'redux-actions'
import { buildAPIActions } from '../utils/actions'

const Groups = {
  // build all available API actions
  ...buildAPIActions('groups'),

  // enable select non-api actions
  ...createActions(
    'GROUPS_CLEAR_CURRENT',
    'GROUP_ADD_FEED_TOGGLE'
  )
}

export default Groups
