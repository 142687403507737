import Cookies from 'universal-cookie';
import { createActions } from 'redux-actions'
import { isInteger } from 'lodash'

const cookies = new Cookies();

const Cart = createActions({
  CART_COUNT() {
    const cart_count = parseInt(cookies.get('cart_count'), 10);

    return {
      count: isInteger(cart_count) ? cart_count : 0
    }
  }
})

export default Cart
