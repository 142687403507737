// paths.json should reflect the contents of src/routes.js
import Paths from './paths.json'

// conversion to Path helper function
//
//    Paths = {
//      'dashboard': '/:username/dashboards/:key'
//    }
//
//  produces a function:
//
//    Paths.dashboard({username: 'test', key: 'welcome'})
//    // => '/test/dashboards/welcome'
//
//  throws an error if a required param is missing
function generatePath(uri_pattern, key) {
  const pattern = uri_pattern
  const params = uri_pattern.match(/:([^/]+)/g) || []

  const replacer = (attrs) => {
    let result = pattern

    if (params && params.length > 0) {
      for (var pat of params) {
        let pkey = pat.replace(/^:/, '')
        if (attrs[pkey]) {
          result = result.replace(pat, attrs[pkey])
        } else {
          throw new Error(`Failed to generate path.\nName: "${ key }"\nPattern: "${uri_pattern}"\nGiven: ${ JSON.stringify(attrs, null, 2) }\nMissing: ${ pat }`)
        }
      }
    }

    if (attrs) {
      if (attrs.full_url) {
        if (attrs.host) {
          result = attrs.host + result
        } else {
          result = import.meta.env.VITE_IO_URL + result
        }
      }

      if (attrs.query && Object.keys(attrs.query).length > 0) {
        const query = Object.keys(attrs.query)
          .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(attrs.query[k]))
          .join('&')
        result = `${result}?${query}`
      }
    }

    return result
  }

  replacer.pattern = uri_pattern
  replacer.params = params

  // replace :param tokens with regexp literals, compile to matcher
  const match_pattern = "^" + uri_pattern.replace(/:([^/]+)/g, '[^/]+') + "$"
  replacer.matcher = new RegExp(match_pattern)

  return replacer
}

// convert to Path helpers
for (var key in Paths) {
  if (!(/^\/\//.test(key))) {
    Paths[key] = generatePath(Paths[key], key)
  }
}

// nothing hidden up its sleeve. Test a given pathname against a safelist of
// all known paths. This can be used to prevent unsafe redirect attacks on sign
// in: https://www.owasp.org/index.php/Unvalidated_Redirects_and_Forwards_Cheat_Sheet
Paths.isSafe = pathname => {
  for (var key in Paths) {
    const path = Paths[key]

    if (path?.matcher?.test(pathname)) {
      return true // pathname matches a known, declared path
    }
  }
  return false
}

// post sign in home path
Paths.home = params => Paths.overview(params)

export { Paths, generatePath }
