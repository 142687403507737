import { handleActions } from 'redux-actions'

import log from 'utils/logger'

const initialState = {
  all: [],
  by_id: {}
}

const words = handleActions({
  GET_ALL_WORD_FULFILLED(state, { payload }) {
    return Object.assign({}, state, {all: payload})
  },

  GET_WORD_FULFILLED(state, { payload }) {
    const nextStore = Object.assign({}, state.by_id)
    nextStore[payload.id] = payload
    return Object.assign({}, state, {by_id: nextStore})
  },

  CREATE_WORD_FULFILLED(state, { payload }) {
    log("created word record", payload)
    return Object.assign({}, state, {all: state.all.concat(payload)})
  },

  CREATE_WORD_FAILED(state, { payload }) {
    log("failed to create word record", payload)
    return state
  },

  DELETE_WORD_FULFILLED(state, { payload }) {
    let nextStore = state.all.map(word => {
      if (word.id === payload.id) return null
      else return word
    }).filter(record => record !== null)

    return Object.assign({}, state, {all: nextStore})
  },
}, initialState)


export default words

const WordSelectors = {
  all(state) {
    return state.words.all || []
  },

  get(state, id) {
    return state.words.by_id[id]
  }
}

export { WordSelectors }
