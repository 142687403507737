import { isUndefined, mapKeys } from 'lodash'
import { error } from 'utils/logger'


// original idea was to make fetch throw:
// https://github.com/github/fetch#handling-http-error-statuses

export const ioFetch = async (requestUrl, requestOptions, responseOptions={}) => {
  // wrap fetch in our own layers that throw on error and auto-parse json on success
  return collectJSON(
    throwOnError(
      fetch(requestUrl, requestOptions), requestOptions
    ),
    responseOptions?.includeHeaders
  )
}

const throwOnError = async (request, requestOptions={}) => {
  // create the error here for a cleaner stack trace
  const ajaxError = new Error()

  // happy path: await the request and return the ok response
  const response = await request
  if (response.ok) { return response }
  // otherwise we've got an error

  // log it if it's serverside
  if (response.status === 500) {
    error('Captured request error from response:', response)
  }

  ajaxError.name = "IOAjaxError"
  ajaxError.message = `${requestOptions.method || 'GET'} ${response.url} returned ${response.status}: ${response.statusText}`
  ajaxError.response = response.clone()
  ajaxError.obj = {}

  if (ajaxError.response.json) {
    try {
      // error handlers across the codebase check .obj for details
      ajaxError.obj = await ajaxError.response.json()

    } catch(nestedError) {
      if(!nestedError.message.startsWith("JSON.parse:")) { throw nestedError }
    }
  }

  // heap everything on for Sentry until we figure out what the right thing is
  // collaborate with our Sentry wrapper in errors.js
  ajaxError.context = {
    ajax: {
      url: response.url,
      ...mapKeys(requestOptions, (value, key) => `request_${key}`),
      status: response.status,
      statusText: response.statusText,
      response_body: ajaxError.obj,
    }
  }

  throw ajaxError
}

const collectJSON = async (request, includeHeaders=false) => {
  const response = await request
  let json

  try {
    json = await response.json()

  } catch (ex) {
    if (response.status >= 200 && response.status < 300) {
      json = null

    } else {
      error('parsing JSON failed:', ex.message)
    }
  }

  if (isUndefined(json)) {
    throw `ERROR, failed to parse request, received JSON is undefined`
  }

  return (includeHeaders
    ? { headers: response.headers, body: json }
    : json
  )
}
