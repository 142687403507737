import mqtt from 'mqtt'
import EventEmitter from 'eventemitter3'

import log from 'utils/logger'

const TRACING = false

class SubscriptionsManager extends EventEmitter {
  constructor(username, password) {
    super()

    this.username = username
    this.password = password

    // use config var or...
    const mqttProtocol = import.meta.env.VITE_MQTT_PROTOCOL ||
      // ...match ws(s) to http(s)
      (location.protocol === 'https:' ? 'wss' : 'ws')

    this.mqttUrl = `${mqttProtocol}://${import.meta.env.VITE_MQTT_HOST}:${import.meta.env.VITE_MQTT_PORT}`

    this.connected = false
    this.client = null

    // active subscriptions, keyed by ID
    this._subscriptions = {}
    this._init()
  }

  _genID() {
    let id = Math.random().toString(16).substr(2, 8)
    return `io-browser-${id}`
  }

  _init() {
    if (window.Cypress) {
      // make a dummy client for now, tests don't wrangle mqtt/websockets well, yet
      this.client = {
        on: () => {},
        subscribe: () => {},
        unsubscribe: () => {},
        end: () => {},
        publish: () => {},
        options: {}
      }
      return
    }
    if (!(this.username && this.password)) { return }

    let options = {
      clientId: this._genID(),
      connectTimeout: 60 * 1000,
      keepalive: 60,
      resubscribe: false,
      reconnectPeriod: 6000, // wait between connection attempts
    }

    this.reconnect = {
      timeout: 6000,
      stretch: 1.2,
      max: 180000
    }

    this.reconnectStretch = 1.2
    this.reconnectMax = 180000

    if(this.username && this.password) {
      options.username = this.username
      options.password = this.password
    }

    if (TRACING) {
      log('[SubscriptionsManager _init] connecting with options', options)
    }

    this.client = mqtt.connect(this.mqttUrl, options)

    this.client.on('connect', () => {
      this.connected = true
      this.emit('connected')

      if (TRACING) {
        log('[SubscriptionsManager _init on connect] connected')
      }

      //// resubscribe all subscriptions, 'connect' is fired on initial connection _and_ on reconnection
      for (var topic in this._subscriptions) {
        if (TRACING) {
          log('[SubscriptionsManager _init on connect] resubscribing to', topic)
        }
        this.client.subscribe(topic)
      }
    })

    this.client.on('reconnect', () => {
      this.connected = this.client.connected

      if (!this.connected) {
        this.backoffTimeout()
      }

      if (TRACING) {
        log('[SubscriptionsManager on reconnect]', this.connected)
      }

      this.emit('reconnect')
    })

    this.client.on('error', (error, ...args) => {
      log('[SubscriptionsManager on error]', error, ...args)
      this.emit('error', { error, timeout: this.client.options.reconnectPeriod } )
    })

    this.client.on('offline', () => {
      this.connected = false

      this.backoffTimeout()

      if (this.client && this.client.reconnecting) {
        this.emit('reconnecting')
      } else {
        this.emit('disconnected')
      }
    })

    this.client.on('close', () => {
      this.connected = false
      if (this.client && this.client.reconnecting) {
        this.emit('reconnecting')
      } else {
        this.emit('disconnected')
      }
    })

    // should be the only time we need to bind to the MQTT client's 'message' event
    this.client.on('message', (topic, message) => {
      if (TRACING || window.MQTT_JS_DEBUG) {
        log('[SubscriptionsManager _init on message] <', topic, '>', message)
      }

      if (this._subscriptions[topic]) {
        // hand off to registered subscription
        this._subscriptions[topic].message(message)
      } else {
        if (TRACING || window.MQTT_JS_DEBUG) {
          log('[SubscriptionsManager on message] unhandled message!', topic, message)
        }
        this.emit('unhandled_message', topic, message)
      }
    })
  }

  // Subscribe
  subscribe(topic, callbacks) {
    if (! this.client) {
      this.connect()
    }

    if(! this.username)
      topic += '/public'

    // already subscribed? return without callback
    if (this.isSubscribed(topic)) return

    // save callbacks
    if (TRACING) {
      log("SubscriptionsManager subscribing to", topic)
    }
    this._subscriptions[topic] = callbacks

    if (this.client) {
      this.client.subscribe(topic, function () {
        callbacks.subscribed(topic)
      })
    }

    return
  }

  isConnected() {
    return this.connected
  }

  isSubscribed(topic) {
    return this._subscriptions[topic]
  }

  unsubscribe(topic, cb) {
    if (this._subscriptions[topic]) {
      // send unsub message
      this.client.unsubscribe(topic, cb)

      // remove from collection
      delete this._subscriptions[topic]
    }
  }

  disconnect(cb) {
    this.client.end(false, cb)
    delete this.client
  }

  publish(topic, value, cb) {
    if(! this.username) return
    this.client.publish(topic, value, cb)
  }

  connect() {
    this._init()
  }

  // reconnection
  backoffTimeout() {
    if (this.client.options.reconnectPeriod < this.reconnect.max) {
      const next_timeout = Math.min(this.client.options.reconnectPeriod * this.reconnect.stretch, this.reconnect.max)
      this.client.options.reconnectPeriod = next_timeout
    }

    if (TRACING) {
      log('[SubscriptionsManager backoffTimeout] reconnect in', this.client.options.reconnectPeriod)
    }
  }

  resetTimeout() {
    if (TRACING) {
      log('[SubscriptionsManager resetTimeout]', this.client.options.reconnectPeriod)
    }
    this.client.options.reconnectPeriod = this.reconnect.timeout
  }
}

export default SubscriptionsManager
