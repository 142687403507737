import React from 'react';
import { connect } from 'react-redux'

import sized from 'components/Mixins/sized'
import { DataSelectors } from 'reducers/data'
import log from 'utils/logger'
import { shortDayTime, secondsToHumanReadable } from 'utils/dates'

const STATUS_CHECK_INTERVAL = 1000 * 10
const ACTIVE_TIMEOUT = 1000 * 3

const STEP_SIZE = 5
const STEP_PAD = 0
const MAX_RECEIPTS = 3440 / (STEP_SIZE + STEP_PAD)

class ConnectionStatusBar extends React.Component {
  constructor(props, ctx) {
    super(props, ctx)

    this.canvas = null
    this.ctx = null // canvas drawing context
    this.step = STEP_SIZE // width of indicator squares
    this.pad = STEP_PAD

    this.state = {
      active: false,
      lastCheck: new Date(),
      receipts: []
    }

    this.status = React.createRef()
  }

  componentDidUpdate(prevProps) {
    if (this.props.anonymous && prevProps.anonymous) return
    if (this.canvas && this.ctx) return

    if (prevProps.anonymous && !this.props.anonymous) {
      this.canvas = this.status.current
      if (this.canvas.getContext) {
        this.ctx = this.canvas.getContext('2d')
      }

      // only render status if we have access to canvas API
      if (this.ctx) {
        this.interval = setInterval(this.checkStatus.bind(this), STATUS_CHECK_INTERVAL)

        // give MQTT connection a chance to establish before flashing the bar red
        this._starterTimeout = setTimeout(() => {
          this.setState({active: true})
        }, ACTIVE_TIMEOUT)

        this.renderStatus()
      } else {
        log("ERROR: no canvas context for status bar")
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    if (this._starterTimeout) clearTimeout(this._starterTimeout)
  }

  addReceipt(val) {
    let receipts = [ val, ...this.state.receipts ]
    if (receipts.length > MAX_RECEIPTS) {
      receipts = receipts.slice(0, MAX_RECEIPTS)
    }
    this.setState({ receipts, lastCheck: new Date() }, () => this.renderStatus())
  }

  checkStatus() {
    const lastCheck = this.state.lastCheck
    const lastUpdate = this.props.lastUpdatedAt

    // was the last update receipt more recent than the last receipt check?
    const hasData = (lastUpdate && lastCheck) ?
      (lastUpdate > lastCheck) :
      false

    this.addReceipt(hasData)
  }

  renderStatus() {
    let x

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)

    // draw from right to left
    this.state.receipts.forEach((r, idx) => {
      if (r) {
        // no data
        this.ctx.fillStyle = "rgb(0,98,199)";
      } else {
        // data
        this.ctx.fillStyle = "rgb(85,85,85)";
      }
      x = this.canvas.width - (idx * (this.step + this.pad)) - (this.step + this.pad)
      this.ctx.fillRect(x, 0, this.step, this.canvas.height)
    })
  }

  error() {
    if (this.props.error) {
      const { error, timeout } = this.props.error
      return (
        <div className='mqtt-stream-error'>
          MQTT ERROR, disconnected at {shortDayTime(this.props.disconnectedAt, { seconds: true })}.
          {error.message}, retrying in {secondsToHumanReadable(timeout/1000)}.
          <a href="https://io.adafruit.com/api/docs/mqtt.html#adafruit-io-mqtt-api">Visit the MQTT troubleshooting
          guide</a> for information about MQTT errors.
        </div>
      )
    }
    return null
  }

  render() {
    if (this.props.anonymous) {
      return null
    }

    let className = 'connected'
    if (this.state.active && !this.props.connected) {
      className = 'disconnected'
    }

    return (
      <>
        <canvas ref={this.status} id='status-bar' className={className} width={this.props.size.width} height={STEP_SIZE} />
        { this.error() }
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    anonymous: !state.session.user,
    connected: DataSelectors.connected(state),
    error: DataSelectors.getStreamingError(state),
    disconnectedAt: DataSelectors.getDisconnectTime(state),
    lastUpdatedAt: DataSelectors.getLastUpdatedAt(state),
  }
}

const ConnectedConnectionStatusBar = connect(mapStateToProps)(sized(ConnectionStatusBar))
export default ConnectedConnectionStatusBar
