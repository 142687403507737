import Session from './session'
import { Paths } from 'utils/paths'

const Charts = {}

Charts.soloFeedChartID =  function (feed) {
  return `feed-show-${feed.key}`
}

// Primary method for loading chart data for feeds
Charts.load = (feed, chartID, params={}) => {
  return (dispatch, getState) => {
    const client = Session.getClient(dispatch, getState)
    const url = Paths.feed_chart({username: feed.username, key: feed.key, query: params})

    dispatch({
      type: 'CHART_DATA_SETUP',
      payload: { chartID }
    })

    return dispatch({
      type: 'CHART_DATA',
      payload: Session.ajax(client, url).then(chart => {
        return {
          chartID,
          chart
        }
      })
    })
  }
}

export default Charts;
