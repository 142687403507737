
import { handleActions } from 'redux-actions'

const initialState = {
  current: null,   // active invoice
  currentReady: false,

  invoices: [],  // all past invoices
  invoicesReady: false,
}

const invoices = handleActions({

  GET_INVOICE_PENDING(state) {
    return Object.assign({}, state, { current: null, currentReady: false })
  },

  GET_INVOICE_FULFILLED(state, { payload }) {
    return Object.assign({}, state, { current: payload, currentReady: true })
  },

  GET_INVOICE_FAILED(state) {
    return Object.assign({}, state, { currentReady: true })
  },

  //---------

  GET_INVOICES_PENDING(state) {
    return Object.assign({}, state, { invoicesReady: false })
  },

  GET_INVOICES_FULFILLED(state, { payload }) {
    return Object.assign({}, state, { invoices: payload, invoicesReady: true })
  },

  GET_INVOICES_FAILED(state) {
    return Object.assign({}, state, { invoicesReady: true })
  },

}, initialState)

export default invoices
