import React from 'react'

import GuideLink from 'components/GuideViewer/GuideLink'

const title = "Quick Guide to Actions"

const pages = [
  () => (
    <>
      <h2>Doing work automatically!</h2>

      <p>Wouldn&#39;t it be great if Adafruit IO automatically performed actions based on the values you send?</p>

      <p>Using Adafruit IO&#39;s <strong>actions</strong>, you can be alerted of data values over email (<a href="/plus">IO+</a> Users Only), over a webhook, or by another feed&#39;s value.</p>
    </>
  ),
  () => (
    <>
      <h2>Types of Actions</h2>

      <p>Adafruit IO has two types of triggers: <GuideLink page={2}>reactive triggers</GuideLink> and <GuideLink page={3}>scheduled actions</GuideLink>.</p>
    </>
  ),

  () => (
    <>
      <h3>Reactive Actions</h3>

      <p>Reactive actions <strong>react to feed values</strong>. You can perform a comparison between feeds, or a static value. Whenever the conditional is met, the action will perform an action such as emailing you, or setting another feed.</p>

      <img className="guide-image" src="https://i.imgur.com/Tnbk782.png" alt="reactive-trigger" />
    </>
  ),

  () => (
    <>
      <h3>Scheduled Actions</h3>

      <p>You can also schedule a action to email values of a feed at a specific time. This is especially useful if you want to check the state of a sensor.</p>

      <img className="guide-image" src="https://i.imgur.com/lmI5qtw.png" alt="scheduled-trigger" />
    </>
  ),
]

export {
  title,
  pages
}



