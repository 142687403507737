import { browserHistory } from 'react-router'

import { Paths } from 'utils/paths'
import { error } from 'utils/logger'
import { includes } from 'lodash'


const Navigation = {
  // force browse to path
  go(path) {
    browserHistory.push(path)
  },

  // wrap initial page load API requests
  catchNotFound(given, props={}) {
    const actions = Array.isArray(given) ? given : [ given ]

    return Promise.all(actions).catch( err => {
      error("CATCHING NAVIGATION ERROR", err)

      const
        { action, response, status } = err,
        possibleStatusLocations = [
          action?.payload.status,
          response?.status,
          status
        ],
        is404 = includes(possibleStatusLocations, 404)

      if (is404) {
        props.notfound?.() ||
          props.router?.push(Paths.notfound()) ||
          Navigation.go(Paths.notfound())
      }

      return Promise.reject(err)
    })
  }
}

export default Navigation
