import React from 'react'
import { useSelector } from 'react-redux'

import { selectAllBoards } from 'slices/boards'

import 'stylesheets/board_image.scss'
import unknown_board from 'images/unknown_board.png'


const BoardImage = ({ machineName }) => {
  const
    allBoards = useSelector(selectAllBoards),
    board = allBoards[machineName],
    imageSrc = board?.imageUrl || unknown_board

  return <img loading="lazy" className="board-image" src={ imageSrc } />
}

export default BoardImage
