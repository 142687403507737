import { buildAPIActions } from 'utils/actions'

// build all available API actions
const Triggers = buildAPIActions('triggers');

Triggers.select = (params) => {
  return {
    type: 'TRIGGERS_SELECT',
    payload: params
  }
};

Triggers.initForm = (trigger) => {
  return {
    type: 'INIT_TRIGGER_FORM',
    payload: trigger
  }
}

Triggers.clearForm = () => {
  return {
    type: 'CLEAR_TRIGGER_FORM'
  }
}

Triggers.clear = () => {
  return {
    type: 'TRIGGERS_CLEAR_CURRENT'
  }
}

Triggers.updateProperty = (field) => {
  return {
    type: 'UPDATE_TRIGGER_PROPERTY',
    payload: field
  }
}

export default Triggers;
