import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FontAwesome from 'react-fontawesome'
import hotkeys from 'hotkeys-js'
import { get } from 'lodash'

import GuideActions from 'actions/guides'
import SimpleModal from 'components/Elements/SimpleModal'
import { Spinner } from 'components/Elements/Loading'
import log, { error } from 'utils/logger' // eslint-disable-line

import * as AccessTokensGuide from './guides/access_tokens'
import * as ArduinoGuide from './guides/arduino'
import * as DashboardsGuide from './guides/dashboards'
import * as FeedsGuide from './guides/feeds'
import * as MetaGuide from './guides/meta'
import * as PublicKeysGuide from './guides/public_keys'
import * as PythonGuide from './guides/python'
import * as TriggersGuide from './guides/triggers'

import 'stylesheets/guides.scss'


const GUIDES = {
  'access_tokens': AccessTokensGuide,
  'arduino': ArduinoGuide,
  'dashboards': DashboardsGuide,
  'feeds': FeedsGuide,
  'meta': MetaGuide,
  'public_keys': PublicKeysGuide,
  'python': PythonGuide,
  'triggers': TriggersGuide,
}

class GuideViewer extends React.Component {
  static propTypes = {
    show: PropTypes.bool
  }

  state = {
    guide: null,
    loading: false,
  }

  loadGuide() {
    const
      { name } = this.props,
      guide = GUIDES[name]

    // No Guide by this name? Error.
    if(!guide) { return error("NO GUIDE WITH NAME", name) }

    this.setState({ guide, loading: false })
    log('loaded guide')
    // Start the load
  }

  componentDidMount() {
    if (this.props.name) {
      this.loadGuide()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.name && this.props.name !== prevProps.name) {
      this.loadGuide()
    }

    // becoming visible
    if (!prevProps.show && this.props.show) {
      hotkeys('right', this.nextPage)
      hotkeys('left', this.prevPage)

    // becoming invisible
    } else if (prevProps.show && !this.props.show) {
      hotkeys.unbind('right')
      hotkeys.unbind('left')
    }
  }

  prevPage = () => {
    const previousPage = this.props.page - 1

    if (previousPage < 0) { return }

    this.props.showGuidePage(previousPage)
  }

  nextPage = () => {
    const
      nextPage = this.props.page + 1,
      guideLength = get(this, "state.guide.pages.length", 0)

    if (nextPage >= guideLength) { return }

    this.props.showGuidePage(nextPage)
  }

  renderPage() {
    const
      { name, page } = this.props,
      { guide } = this.state

    if (guide && !isNaN(page)) {
      const GuidePage = guide.pages[page]
      if (typeof GuidePage === 'string') {
        return <div dangerouslySetInnerHTML={{__html: GuidePage}} />
      } else if (GuidePage) {
        return <GuidePage />
      }
    } else {
      return (
        <>
          <p>unknown content:</p>
          <pre>{JSON.stringify({ name, page }, null, '  ')}</pre>
        </>
      )
    }
  }

  renderLoading() {
    return (
      <div className="placeholder">
        <Spinner blue />
      </div>
    )
  }

  renderFooter() {
    const
      page = this.props.page + 1,
      isMetaGuide = this.props.name === "meta",
      length = get(this, "state.guide.pages.length", 0),
      hasPrev = page > 1,
      hasNext = page < length,
      counter = length > 1 && `${page} of ${length}`,
      otherGuidesClass = "btn left" + ( isMetaGuide ? " disabled" : "" ),
      prevClass = "btn right " + ( hasPrev ? "blue" : "disabled" ),
      nextClass = "btn right " + ( hasNext ? "blue" : "disabled" )

    return (
      <div className="guide-buttons">
        { counter &&
          <span className="page-count">{ counter }</span>
        }
        <a disabled={ isMetaGuide } className={ otherGuidesClass } onClick={ this.props.showMetaGuide }>See other guides</a>
        <a disabled={ !hasNext } className={ nextClass } onClick={this.nextPage}>Next <FontAwesome name="chevron-right" /></a>
        <a disabled={ !hasPrev } className={ prevClass } onClick={this.prevPage}><FontAwesome name="chevron-left" /> Prev</a>
      </div>
    )
  }

  render() {
    const { guide, loading } = this.state

    if (guide?.Walkthrough) {
      return <guide.Walkthrough />
    }

    const title = guide?.title || "Loading..."

    return (
      <SimpleModal show={ this.props.show } id='guides-modal' title={ title } onHide={ this.props.hide } footer={ this.renderFooter() }>
        { guide && this.renderPage() }
        { loading && this.renderLoading() }
      </SimpleModal>
    )
  }
}

const
  mapState = state => (state.guides || {}), // { show, name, page }

  mapDispatch = dispatch => ({
    hide: () => dispatch(GuideActions.hideGuide()),
    showMetaGuide: () => dispatch(GuideActions.showGuide('meta')),
    showGuidePage: (page) => dispatch(GuideActions.showGuidePage(page)),
  })

const ConnectedGuideViewer = connect(mapState, mapDispatch)(GuideViewer)
export default ConnectedGuideViewer
