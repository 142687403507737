/* eslint no-case-declarations: 0 */
import { handleActions } from 'redux-actions';

import { buildAPIReducers } from 'utils/reducers';
import { isDashboardStream } from './data'

const initialState = {
  groups: [],
  group: {}
};

const groupsAPI = buildAPIReducers('group', initialState);

const groups = (state, action) => {
  switch(action.type) {
    case 'GROUPS_ADD_FEED_FULFILLED':
    case 'GROUPS_REMOVE_FEED_FULFILLED':
      const { payload } = action
      if (state.group.id === payload.id) {
        return Object.assign({}, state, { group: payload })
      }
      return state

    case 'DATA_SUBSCRIPTION_MESSAGE_RECEIVED':
      const { topic, message } = action.payload

      if (isDashboardStream(topic)) {
        const nextGroups = state.groups

        nextGroups.forEach(g => {
          g.feeds.forEach(f => {
            if (f.id === message.feed_id) {
              f.last_value = message.value
            }
          })
        })

        let nextGroup = state.group
        if (nextGroup && nextGroup.feeds) {
          const ngFeeds = nextGroup.feeds.map(f => {
            if (f.id === message.feed_id) {
              return Object.assign({}, f, {last_value: message.value})
            } else {
              return f
            }
          })

          nextGroup = Object.assign({}, state.group, {feeds: ngFeeds})
        }

        return Object.assign({}, state, { groups: nextGroups, group: nextGroup })
      }

      return state

    default:
      return groupsAPI(state, action)
  }
}

const addFeedState = {
  modal: {
    visible: false
  }
}

const groupAddFeedForm = handleActions({
  GROUP_ADD_FEED_TOGGLE(state, { payload }) {
    const visible = payload
    return Object.assign({}, state, { modal: { visible } })
  },
}, addFeedState)

export { groups, groupAddFeedForm }
