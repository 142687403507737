// import log from 'utils/logger'
import Session from './session'
import { Paths } from 'utils/paths'

const Sharing = { }

Sharing.getNotifications = () => {
  return (dispatch, getState) => {
    const client = Session.getAuthenticatedClient(dispatch, getState)

    if (client) {
      return dispatch({
        type: 'GET_SHARING_NOTIFICATIONS',
        payload: Session.ajax(client, Paths.api_sharing_notifications({
          username: client.username
        }), { method: 'GET' })
      })
    }

    return dispatch({
      type: 'GET_SHARING_NOTIFICATIONS',
      payload: Promise.reject('authenticated client not available')
    })
  }
}

Sharing.getFeedShares = (feed) => {
  return (dispatch, getState) => {
    const client = Session.getAuthenticatedClient(dispatch, getState)

    if (client) {

      const url = Paths.api_feed_shares({username: feed.username, key: feed.key})
      const options = { method: 'GET' }

      // wrap Session.ajax response to make sure reducer knows which feed these shares belong to
      return dispatch({
        type: 'GET_FEED_SHARES',
        payload: Session.ajax(client, url, options).then((response) => {
          return {
            feed: feed,
            feed_shares: response
          }
        })
      })
    }

    return dispatch({
      type: 'GET_FEED_SHARES',
      payload: Promise.reject('authenticated client not available')
    })
  }
}

Sharing.getSharesPage = (client) => {
  const username = client.username
  const url = Paths.api_sharing({ username })
  return Session.ajax(client, url)
}

Sharing.shareFeed = (data) => {
  const { feed, email, level } = data
  return (dispatch, getState) => {
    const client = Session.getAuthenticatedClient(dispatch, getState)

    if (client) {

      const url = Paths.api_feed_shares({username: feed.username, key: feed.key})
      const feed_share = {
        level,
        user_email: email,
      }
      const options = { method: 'POST', body: JSON.stringify(feed_share) }

      return dispatch({
        type: 'CREATE_FEED_SHARE',
        payload: Session.ajax(client, url, options)
      })
    }

    return dispatch({
      type: 'CREATE_FEED_SHARE',
      payload: Promise.reject('authenticated client not available')
    })
  }
}

Sharing.updateFeedShare = (data) => {
  const { feed, id, level } = data

  return (dispatch, getState) => {
    const client = Session.getAuthenticatedClient(dispatch, getState)
    if (client) {
      const url = Paths.api_feed_share({username: feed.owner.username, key: feed.key, id: id})
      const feed_share = {
        level: level
      }
      const options = {
        method: 'PUT',
        body: JSON.stringify(feed_share)
      }

      // wrap Session.ajax response to make sure reducer knows which feed these shares belong to
      return dispatch({
        type: 'UPDATE_FEED_SHARE',
        payload: Session.ajax(client, url, options).then((response) => {
          return {
            feed: feed,
            feed_share: response
          }
        })
      })
    }

    return dispatch({
      type: 'UPDATE_FEED_SHARE',
      payload: Promise.reject('authenticated client not available')
    })
  }
}

Sharing.deleteFeedShare = (data) => {
  const { feed, id } = data
  return (dispatch, getState) => {
    const client = Session.getAuthenticatedClient(dispatch, getState)
    if (client) {
      const url = Paths.api_feed_share({username: feed.username, key: feed.key, id: id})
      const options = { method: 'DELETE' }

      // wrap Session.ajax response to make sure reducer knows which feed these shares belong to
      return dispatch({
        type: 'DELETE_FEED_SHARE',
        payload: Session.ajax(client, url, options).then((response) => {
          return {
            feed: feed,
            feed_share: response
          }
        })
      })
    }

    return dispatch({
      type: 'DELETE_FEED_SHARE',
      payload: Promise.reject('authenticated client not available')
    })
  }
}

Sharing.toggleModal = (isVisible, qrCode) => ({
  type: 'SHARING_TOGGLE_MODAL',
  payload: {
    isVisible: isVisible,
    qrCode: qrCode
  }
})


export default Sharing
