import { configureStore } from '@reduxjs/toolkit'
import reduxCatch from 'redux-catch'
import reduxPromise from 'redux-promise-middleware'

import logException from 'utils/errors'
import rootReducer from 'reducers'

const middleware = [
  reduxCatch((error, getState, lastAction) => {
    // consider extracting some non-sensitive state here
    logException(error, { state: {}, lastAction })
  }),
  reduxPromise
]

export default configureStore({
  reducer: rootReducer,
  middleware:
    getDefaults => getDefaults({
      serializableCheck: false,
    }).concat(middleware)
})
