// Modal dialog for interacting with secret keys

import PropTypes from 'prop-types'
import React, { createRef } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { Keys as KeysActions } from 'actions'
import SimpleModal from 'components/Elements/SimpleModal'
import { confirmation } from 'components/Elements/ConfirmModal'

const BLANK_120x120_PNG = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAB4CAYAAAA5ZDbSAAABp0lEQVR4Xu3TAREAAAiDQNe/tD3+sAHgdh1tYDRdcFdg/AkKXGDcAI7XgguMG8DxWnCBcQM4XgsuMG4Ax2vBBcYN4HgtuMC4ARyvBRcYN4DjteAC4wZwvBZcYNwAjteCC4wbwPFacIFxAzheCy4wbgDHa8EFxg3geC24wLgBHK8FFxg3gOO14ALjBnC8Flxg3ACO14ILjBvA8VpwgXEDOF4LLjBuAMdrwQXGDeB4LbjAuAEcrwUXGDeA47XgAuMGcLwWXGDcAI7XgguMG8DxWnCBcQM4XgsuMG4Ax2vBBcYN4HgtuMC4ARyvBRcYN4DjteAC4wZwvBZcYNwAjteCC4wbwPFacIFxAzheCy4wbgDHa8EFxg3geC24wLgBHK8FFxg3gOO14ALjBnC8Flxg3ACO14ILjBvA8VpwgXEDOF4LLjBuAMdrwQXGDeB4LbjAuAEcrwUXGDeA47XgAuMGcLwWXGDcAI7XgguMG8DxWnCBcQM4XgsuMG4Ax2vBBcYN4HgtuMC4ARyvBRcYN4DjteAC4wZwvBZcYNwAjteCC4wbwPFaMB74AS1+AHmsOhpiAAAAAElFTkSuQmCC"

class UsernameKeySamples extends React.Component {
  static propTypes = {
    // can't call it "key" or React will freak out
    value: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  }

  constructor(...args) {
    super(...args)
    this.state = { show: true }
  }

  circuitPythonText() {
    return `ADAFRUIT_AIO_USERNAME = "${this.props.username}"\n` +
           `ADAFRUIT_AIO_KEY      = "${this.props.value}"`
  }

  arduinoText() {
    return `#define IO_USERNAME  "${this.props.username}"\n` +
           `#define IO_KEY       "${this.props.value}"`
  }

  shellText() {
    return `export IO_USERNAME="${this.props.username}"\n` +
           `export IO_KEY="${this.props.value}"`
  }

  scriptingText() {
    return `ADAFRUIT_IO_USERNAME = "${this.props.username}"\n` +
           `ADAFRUIT_IO_KEY      = "${this.props.value}"`
  }

  render() {
    if (this.state.show) {
      return (
        <div>
          <p><a onClick={() => this.setState({show: false})}>Hide Code Samples</a></p>

          <p><strong>CircuitPython</strong></p>
          <pre><code>{this.circuitPythonText()}</code></pre>

          <p><strong>Arduino</strong></p>
          <pre><code>{this.arduinoText()}</code></pre>

          <p><strong>Linux Shell</strong></p>
          <pre><code>{this.shellText()}</code></pre>

          <p><strong>Scripting</strong></p>
          <pre><code>{this.scriptingText()}</code></pre>
        </div>
      )
    } else {
      return (
        <div>
          <p><a onClick={() => this.setState({show: true})}>Show Code Samples</a></p>
        </div>
      )
    }
  }
}

class KeysModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    username: PropTypes.string,
    value: PropTypes.string,
    init: PropTypes.bool,
  }

  constructor(...args) {
    super(...args)

    this.state = {
      value: '',
    }

    this.key_field = createRef()
  }

  componentDidUpdate(prevProps) {
    // becoming visible
    if (this.props.show && !prevProps.show) {
      this.props.reloadKeys()
    }
  }

  componentDidMount() {
    if(this.props.show) {
      this.props.reloadKeys()
    }
  }

  regenerate = (evt) => {
    evt.preventDefault()
    if (this.props.regenerating) { return }

    confirmation({
      message: <p>Are you sure you want to regenerate your Adafruit IO Key?</p>,

      onConfirm: () => {
        this.props.regenerateKey().then(this.props.reloadKeys)
      }
    })
  }

  select = () => this.key_field.current.select()

  render() {
    const
      aioKey = this.props.aioKey || "Loading...",
      qrCode = this.props.qrCode || BLANK_120x120_PNG,
      regenerateLabel = this.props.regenerating ? "REGENERATING..." : "REGENERATE KEY"

    return (
      <SimpleModal id='aio-key-modal' title="YOUR ADAFRUIT IO KEY" show={this.props.show} onHide={this.props.closeModal} onEntered={this.select}>
        <div className='section'>
          <span className="aio-qr-code">
            <img src={qrCode} />
          </span>
          <p>
            Your Adafruit IO Key should be kept in a safe place and treated
            with the same care as your Adafruit username and password. People who
            have access to your Adafruit IO Key can view all of your data, create new feeds
            for your account, and manipulate your active feeds.
          </p>
          <p>
            If you need to regenerate a new Adafruit IO Key, all of your existing
            programs and scripts will need to be manually changed to the new key.
          </p>
        </div>

        <div className='section values'>
          <form className='form-inline' onSubmit={this.regenerate}>
            <div className="form-group">
              <label htmlFor="aio-user">Username</label>
              <input className='form-control' type="text" name="username" value={this.props.username} readOnly />
            </div>
            <div className="form-group">
              <label htmlFor="aio-key">Active Key</label>
              <input className='form-control' ref={this.key_field} type="text" name="aio_key" value={aioKey} readOnly />
            </div>
            <input type="submit" disabled={this.props.regenerating} value={regenerateLabel} />
          </form>
        </div>

        <div className='section code-samples'>
          { this.props.username && <UsernameKeySamples value={aioKey} username={this.props.username} /> }
        </div>
      </SimpleModal>
    )
  }
}

const
  mapState = state => {
    const
      { keys, session } = state,
      currentKey = keys.keys[0]

    return {
      aioKey: get(currentKey, "key"),
      qrCode: get(currentKey, "qr_code"),
      username: get(session, "user.username"),
      loading: keys.loading,
      regenerating: keys.regenerating,
      show: keys.modal.visible
    }
  },

  mapDispatch = dispatch => ({
    reloadKeys: () => dispatch(KeysActions.all()),
    closeModal: () => dispatch(KeysActions.toggleModal(false)),
    regenerateKey: () => dispatch(KeysActions.create())
  })

const ConnectedKeysModal = connect(mapState, mapDispatch)(KeysModal)
export default ConnectedKeysModal
