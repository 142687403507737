import { chain, isArray, keyBy, reduce, reject, snakeCase } from 'lodash'
import { handleActions } from 'redux-actions'
import { singularize, pluralize } from 'inflection'

import log, { error } from 'utils/logger' // eslint-disable-line


// used by ALL APIs to merge new records
// id-key, else key, else id
const hashKey = ({ id, key }) => (id && key) ? `${id}-${key}` : key || id

const handleError = (state, action) => {
  const
    { payload } = action,
    errors = []

  if (!(payload?.obj)) {
    log("API reducers empty response error.", action)
    errors.push(`API response is empty for URL: ${payload?.url}`)

  } else if(isArray(payload.obj.error)) {
    errors.push(...payload.obj.error)

  } else {
    errors.push(payload.obj.error)
  }

  return { ...state, errors }
}

const parsePaginationHeaders = headers => {
  const
    limit = headers['x-pagination-limit'],
    count = headers['x-pagination-count']

  return {
    start_time: headers['x-pagination-start'],
    end_time: headers['x-pagination-end'],
    limit: limit && parseInt(limit, 10),
    count: count && parseInt(count, 10),
  }
}

// Request state transition reducers. These are generic reducers that update API
// request related metadata for a given action type.
//
// For example, FEEDS_GET leads to corresponding FEEDS_GET_PENDING and
// FEEDS_GET_FULFILLED actions that directly update stored data (feed records),
// these reducers update stored meta-data. Request time, error messages,
// pagination headers, etc.
const handleRequestState = {
  pending: key => state => ({
    ...state,
    [ key ]: {
      ...state[key],
      loading: true,
      state: 'pending',
      started_at: new Date(),
      error: null,
    }
  }),

  fulfilled: key => (state, { headers }) => ({
    ...state,
    [ key ]: {
      ...state[key],
      loading: false,
      state: 'fulfilled',
      completed_at: new Date(),
      pagination: parsePaginationHeaders(headers),
      error: null
    }
  }),

  rejected: key => (state, error) => ({
    ...state,
    [ key ]: {
      ...state[key],
      loading: false,
      state: 'rejected',
      completed_at: new Date(),
      error
    }
  })
}

const generateReducers = (single, plural, PLURAL, request, transformCb) => {
  const reducers = {
    // clear the member
    [`${PLURAL}_CLEAR_CURRENT`]: state => ({ ...state, [single]: {} }),

    [`${PLURAL}_ALL_PENDING`]: state => request.pending.collection(state),

    // merge the payload into the collection
    [`${PLURAL}_ALL_FULFILLED`]: (state, { payload }) => {
      let next = state[plural]

      if(isArray(payload.object)) {
        if(isArray(next)) {
          // create hash of existing records by id + key if available
          const byID = keyBy(next, hashKey)

          // create new or merge with existing
          next = payload.object.map(record => ({
            ...byID[hashKey(record)],
            ...(transformCb?.(record) || record)
          }))

        } else {
          // no records exist, just use payload, optionally transformed
          next = transformCb
            ? payload.object.map(transformCb)
            : payload.object
        }
      }

      return {
        ...request.fulfilled.collection(state, payload),
        [plural]: next,
      }
    },

    // set the member to the payload
    [`${PLURAL}_SELECT`]: (state, { payload }) => ({
      ...state,
      [single]: { ...payload }
    }),

    [`${PLURAL}_GET_PENDING`]: state => request.pending.member(state),

    // merge the member with the payload object
    [`${PLURAL}_GET_FULFILLED`]: (state, { payload }) => ({
      ...request.fulfilled.member(state, payload),
      [single]: {
        ...state[single],
        ...payload.object
      }
    }),

    [`${PLURAL}_REPLACE_PENDING`]: state => request.pending.member(state),

    // update the collection and member by id
    [`${PLURAL}_REPLACE_FULFILLED`]: (state, { payload }) => {
      const
        // the full collection with the one item changed
        all = state[plural].map( record => (record.id === payload.object.id
          ? { ...record, ...payload.object }
          : record)
        ),
        current = state[single],
        // also modify the current item, if it matches
        singular = (current && current.id === payload.object.id)
          ? { ...current, ...payload.object }
          : current

      return {
        ...request.fulfilled.collection(state, payload),
        [single]: singular,
        [plural]: all
      }
    },

    [`${PLURAL}_CREATE_PENDING`]: state => request.pending.collection(state),

    // insert newly-created item into collection and sort by id
    [`${PLURAL}_CREATE_FULFILLED`]: (state, { payload }) => ({
      ...request.fulfilled.collection(state, payload),
      [plural]:
        chain(state[plural])
          .concat(payload.object)
          .sortBy('id')
        .value(),
    }),

    [`${PLURAL}_DESTROY_PENDING`]: state => request.pending.collection(state),

    // remove the newly-deleted item from the collection by id
    [`${PLURAL}_DESTROY_FULFILLED`]: (state, { payload }) => ({
      ...request.fulfilled.collection(state, payload),
      [plural]: reject(state[plural], { id: payload.object.id })
    }),

    [`${PLURAL}_RESET_ERRORS`]: state => ({ ...state, errors: null })
  }

  // details is get with extra fields
  reducers[`${PLURAL}_DETAILS_PENDING`] = reducers[`${PLURAL}_GET_PENDING`]
  reducers[`${PLURAL}_DETAILS_FULFILLED`] = reducers[`${PLURAL}_GET_FULFILLED`]

  return reducers
}

export const buildAPIReducers = (name, initialState, transformCb) => {
  const
    // storage locations
    single = singularize(name),
    plural = pluralize(name),

    // action types
    PLURAL = snakeCase(plural).toUpperCase(),

    // request state handling
    request = reduce(handleRequestState, (acc, statusHandler, status) => ({
      ...acc,
      [status]: {
        member: statusHandler(`${single}_meta`),
        collection: statusHandler(`${plural}_meta`)
      }
    }), {}),

    reducers = generateReducers(single, plural, PLURAL, request, transformCb),

    handler = handleActions(reducers, initialState),

    // intercept and handle all _REJECTED actions the same
    rejectedMatcher = new RegExp(`^${PLURAL}_([A-Z_]+)_REJECTED$`)

  return (state, action) => {
    const matchedRejection = rejectedMatcher.exec(action.type)

    // no rejection, forward along the happy path
    if(!matchedRejection) { return handler(state, action) }

    // route the rejection to the appropriate reducer
    const requestReducer = (matchedRejection[1] === 'GET')
      ? request.rejected.member
      : request.rejected.collection

    return handleError(requestReducer(state, action.payload), action)
  }
}
