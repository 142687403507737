import React from 'react'
import clsx from 'clsx'
import { useSelector } from 'react-redux'

import { hasIoPlusSelector } from 'selectors/users'

import ioPlusLogo from "images/io-plus-logo-black.svg"


const Alert = ({ children, info, warning, danger, ioPlus, alwaysShow, iconOverride, onDismiss }) => {
  // early out for ioPlus teasers if user already has it
  const hasIoPlus = useSelector(hasIoPlusSelector)
  if(ioPlus && hasIoPlus && !alwaysShow) { return }

  const
    alertClass = clsx('alert', {
      "alert-danger": danger,
      "alert-warning": warning,
      "alert-io-plus": ioPlus,
      "alert-info": info || (!danger && !warning && !ioPlus)
    }),
    exclamatory = danger || warning,
    iconClass = iconOverride || clsx("fas", {
      "fa-circle-exclamation": exclamatory,
      "fa-circle-info": !exclamatory
    }),
    icon = ioPlus
      ? <img src={ ioPlusLogo }/>
      : <i className={ iconClass }/>

  return (
    <div className={ alertClass }>
      <div className='alert-icon'>{ icon }</div>

      <div className='alert-content'>{ children }</div>

      { onDismiss &&
        <div className='alert-icon alert-dismiss' onClick={ onDismiss }>
          <i className="fas fa-times" />
        </div>
      }
    </div>
  )
}

export default Alert
