import { buildAPIReducers } from '../utils/reducers';
import { handleActions } from 'redux-actions'

const initialState = {
  dashboards: [],
  dashboard: {},
};

const dashboardsAPI = buildAPIReducers('dashboard', initialState);

function destroyBlock(state, action) {
  const block = action.payload.object
  const nextDashboard = Object.assign({}, state.dashboard)

  let bIndex = -1
  nextDashboard.blocks.forEach((_block, idx) => {
    if (_block.id === block.id) {
      bIndex = idx
    }
  })

  if (bIndex > -1) {
    // remove block from array
    nextDashboard.blocks.splice(bIndex, 1)
  }

  return Object.assign({}, state, { dashboard: nextDashboard })
}

function updateBlock(state, action) {
  const block = action.payload.object
  const nextDashboardBlocks = state.dashboard.blocks.slice(0)

  let bIndex = -1
  nextDashboardBlocks.forEach((_block, idx) => {
    if (_block.id === block.id) {
      bIndex = idx
    }
  })

  if (bIndex > -1) {
    nextDashboardBlocks[bIndex] = block
  }

  const nextDashboard = Object.assign({}, state.dashboard, { blocks: nextDashboardBlocks })

  return Object.assign({}, state, { dashboard: nextDashboard })
}

// wrap buildAPIReducers output so that other action types can affect dashboard state
const dashboards = (state, action) => {
  let nextDashboard, nextLayouts, updatedState;

  switch(action.type) {
    case 'BLOCKS_DESTROY_FULFILLED':
      return destroyBlock(state, action)

    case 'BLOCKS_REPLACE_FULFILLED':
      return updateBlock(state, action)

    case 'DASHBOARD_LAYOUTS_REPLACE_FULFILLED':
      // update layouts record of current loaded dashboard via manual update call
      nextLayouts = Object.assign({}, state.dashboard.layouts, action.payload.layouts)
      nextDashboard = Object.assign({}, state.dashboard, { layouts: nextLayouts })
      updatedState = Object.assign({}, state, { dashboard: nextDashboard })
      return dashboardsAPI(updatedState, action)

    case 'DASHBOARDS_REPLACE_FULFILLED':
      // update current loaded dashboard via API call
      nextDashboard = Object.assign({}, state.dashboard, action.payload.object)
      updatedState = Object.assign({}, state, { dashboard: nextDashboard })
      return dashboardsAPI(updatedState, action)

    case 'DASHBOARD_UPLOAD_HEADER_IMAGE_FULFILLED':
      // header image API returns new dashboard as payload rather than payload.object
      nextDashboard = Object.assign({}, state.dashboard, action.payload)
      updatedState = Object.assign({}, state, { dashboard: nextDashboard })
      return dashboardsAPI(updatedState, action)

    default:
      // pass everything else to the API reducers
      return dashboardsAPI(state, action)
  }
}


const initialViewer = {
  breakpoint: 'lg',
  width: 1300,
  editing: false,
  compacting: false,

  // pending layouts modifications from react-grid-layout. Should always be
  // browser local, may require modification when blocks are modified.
  layouts: {},
}

const layoutIncludesBlock = (layout, block) => {
  return layout.some(b => {
    return b.i === block.i
  })
}

// merge layouts from API-based dashboard record into the pending layouts
const mergePendingLayouts = (state, dashboard) => {
  const pending  = state.layouts
  const existing = (dashboard.layouts || {})
  const keys = Object.keys(existing)

  const nextLayouts = {}

  keys.forEach(key => {
    // may include new blocks
    let apiLayout = (existing[key] || [])

    // pending layout may include those blocks, but we have to check
    nextLayouts[key] = (pending[key] || [])

    // add each block in the API-retrieved layout that is not already in
    // the local pending layout
    apiLayout.forEach(block => {
      if (!layoutIncludesBlock(nextLayouts[key], block)) {
        nextLayouts[key] = nextLayouts[key].concat(block)
      }
    })
  })

  return Object.assign({}, state, { layouts: nextLayouts })
}

// update layouts from received
const updatePendingLayouts = (state, dashboard) => {
  const nextLayouts = Object.assign({}, state.layouts, dashboard.layouts)
  return Object.assign({}, state, { layouts: nextLayouts })
}

const dashboardViewer = handleActions({

  // -----------------------------------
  // pending layouts updaters

  DASHBOARD_LAYOUTS_REPLACE_FULFILLED(state, { payload }) {
    return updatePendingLayouts(state, payload)
  },

  DASHBOARDS_REPLACE_FULFILLED(state, { payload }) {
    return updatePendingLayouts(state, payload.object)
  },

  // when a block is added or removed
  DASHBOARDS_GET_FULFILLED(state, { payload }) {
    return mergePendingLayouts(state, payload.object)
  },

  DASHBOARDS_CLEAR_LAYOUT(state) {
    return Object.assign({}, state, { layouts: {} })
  },

  // end pending layouts updaters
  // -----------------------------------

  DASHBOARDS_VIEWER_CHANGED(state, { payload }) {
    return Object.assign({}, state, payload)
  },

  DASHBOARD_LAYOUTS_UPDATE(state, { payload }) {
    return Object.assign({}, state, { layouts: payload })
  },

  DASHBOARDS_START_EDITING(state) {
    return Object.assign({}, state, {
      editing: true,
      layoutSaved: false
    })
  },

  DASHBOARDS_TOGGLE_VERTICAL_COMPACTING(state, { payload }) {
    return Object.assign({}, state, {
      compacting: payload,
    })
  },

  DASHBOARDS_STOP_EDITING(state) {
    return Object.assign({}, state, {
      editing: false,
      compacting: false // vertical compacting should only be active during editing
    })
  }
}, initialViewer)

export { dashboards, dashboardViewer }
