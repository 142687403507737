import React from 'react'
import DocumentTitle from 'components/TopLevel/DocumentTitle'
import {connect} from 'react-redux'
import { Link } from 'react-router'
import $ from 'jquery'

import sized from 'components/Mixins/sized'
import { Paths } from 'utils/paths'

import adafruit_logo from 'images/landing/adafruit-compact-256.png'
import circuitpython_logo from 'images/landing/circuitpython-logo-words.png'
import arduino_logo from 'images/landing/arduino-icon.png'
import microbit_logo from 'images/landing/micro-bit-icon.png'
import raspi_logo from 'images/landing/raspi-icon.png'
import dashboard_chart from 'images/landing/dashboard-chart.png'

import 'stylesheets/landing.scss'

class Landing extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      user: false,
    }

    this.subjects = []
    this._positioned = false
  }

  componentDidUpdate(prevProps) {
    if ((!this._positioned && this.props.size) || !(this.props.size && prevProps.size) || this.props.size.width !== prevProps.size.width) {
      const bb = $(this.blue_backer),
            eo = $(this.everyone),
            eo_left = eo.offset().left,
            li = $(this.landing_image),
            st = $(this.subtext),
            sl = $(this.slogan),
            subs = []

      this.subjects.forEach(el => {
        subs.push($(el))
      })

      let st_pad = -18,
          eo_bot_pad = -16,
          eo_top_pad = 0
      if (this.props.size.width < 1024) {
        st_pad = 0
        eo_bot_pad = -4
        eo_top_pad = 4
      }

      bb.css({display: 'block'})
      st.css({display: 'block'})
      subs.forEach(el => el.css({display: 'block'}))

      bb.offset({left: eo_left, top: li.offset().top})
      bb.height(li.height())

      // subtext
      st.offset({left: sl.offset().left, top: sl.offset().top + sl.height() + st_pad})
      st.width(bb.offset().left - st.offset().left)

      const bb_height = bb.height(),
            eo_top = eo.offset().top,
            eo_top_height = eo_top - bb.offset().top,
            eo_height = eo.height(),
            eo_bot = eo_top + eo_height,
            eo_bot_height = bb_height - eo_height - eo_top_height,
            eo_top_step = eo_top_height / 3,
            eo_bot_step = eo_bot_height / 3

      // subject placment and styling
      let step = 1

      while (step < 4) {
        let a = subs.pop()
        a.offset({left: eo_left, top: eo_top - (eo_top_step * step) + eo_top_pad})
        a.addClass(`sub-${step}`)

        let b = subs.pop()
        b.offset({left: eo_left, top: eo_bot + (eo_bot_step * (step - 1)) + eo_bot_pad})
        b.addClass(`sub-${step}`)

        step += 1
      }

      this._positioned = true
    }
  }

  freeSignUpLink() {
    const { user } = this.props

    return user ?
      <Link to={ Paths.home({ username: user.username }) } className='btn'>
        Get started
      </Link> :

      <a href={ import.meta.env.VITE_IO_SIGN_UP_URL } className='btn'>
        Sign Up Now
      </a>
  }

  plusSignUpLink() {
    const { user } = this.props

    return user ?
      <Link to={ Paths.upgrade() } className='btn'>
        Upgrade to IO+
      </Link> :

      <a href={ import.meta.env.VITE_IO_SIGN_UP_URL } className='btn'>
        Sign Up Now
      </a>
  }

  render() {
    return (
      <div className="landing" >
        <DocumentTitle title="Welcome to Adafruit IO" />

        <div className="landing-image" ref={el => this.landing_image = el}>
          <div className="title">
            <div className='slogan' ref={el => this.slogan = el}>
              <span>The internet of things for <span className='everyone' ref={el => this.everyone = el}>everyone</span></span>
            </div>
          </div>
          <div className='blue-backer' ref={el => this.blue_backer = el}></div>
          <span className='subtext' ref={el => this.subtext = el}>The easiest way to stream, log, and interact with your data.</span>
          <div className='subjects'>
            <div ref={el => this.subjects.push(el)}>tinkerers</div>
            <div ref={el => this.subjects.push(el)}>scientists</div>
            <div ref={el => this.subjects.push(el)}>makers</div>
            <div ref={el => this.subjects.push(el)}>engineers</div>
            <div ref={el => this.subjects.push(el)}>teachers</div>
            <div ref={el => this.subjects.push(el)}>students</div>
          </div>
        </div>

        <div className='corporate'>
          <div className="container">
            <div className="row">
              <div className="col-xs-12 product-icons">
                 {/* adafruit, circuitpython, raspberry pi, micro:bit, arduino */}
                 <img src={adafruit_logo} alt="Adafruit Logo" title="Adafruit" />
                 <img src={circuitpython_logo} alt="CircuitPython Logo" title="CircuitPython" />
                 <img src={raspi_logo} alt="Raspberry Pi Logo" title="Raspberry Pi" />
                 <img src={microbit_logo} alt="Microbit Logo" title="Microbit" />
                 <img src={arduino_logo} alt="Arduino Logo" title="Arduino" />
              </div>
            </div>
          </div>
        </div>

        <div className='play-nice'>
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <h2>We play nice with any device.</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <p>Our simple client libraries work with the most popular
                   devices such as the Adafruit Feather Huzzah, ESP8266, Raspberry Pi, Arduino, and
                   more. <a href='https://learn.adafruit.com/welcome-to-adafruit-io'>Learn more.</a></p>
              </div>
              <div className="col-md-8">
                <img src={dashboard_chart} />
              </div>
            </div>
          </div>
        </div>

        <div className='ground-up'>
          <div className="container">
            <div className="row">
              <div className="col-xs-12 heading">
                <h2>Built from the ground up to be <strong>easy to use.</strong></h2>
                <h4>We do the hard work so you can focus on <strong>the fun stuff.</strong></h4>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-6 col-xs-12 promo">
                <h4>Powerful API</h4>
                <p>The same API that drives our user interface is available to you.  We <a href='https://io.adafruit.com/api/docs'>provide documentation</a> so you can build a library in your favorite language to talk to IO, or use one of ours.</p>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12 promo">
                <h4>It's Your Data</h4>
                <p>The data you store with IO is yours to manage and control. You can download it all anytime and we will never sell or give it away to another company.  We feel strongly enough about this that we put it in our <a href='https://www.adafruit.com/iotbor/'>IoT Bill of Rights</a>.</p>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12 promo">
                <h4>Beautiful Dashboards</h4>
                <p>Data won't help you make better decisions or understand the world you're living in unless you can <em>see</em> it.  Charts, graphs, gauges, logging, and more are available from anywhere in our carefully designed web-based dashboards.</p>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12 promo">
                <h4>Private &amp; Secure</h4>
                <p>We won't share your account information with anyone without your permission and everything you store on Adafruit IO is <strong>private by default</strong>, whether you're a paying customer or not.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-6 col-xs-12 promo">
                <h4>Documentation</h4>
                <p>We've written a lot more about Adafruit IO than just <a href='https://io.adafruit.com/api/docs'>our web API</a>.  Teaching is at the heart of Adafruit, and our <a href='https://learn.adafruit.com/category/adafruit-io'>Learning System</a> has all the projects and guides you need to get started or to level up!</p>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12 promo">
                <h4>Actions</h4>
                <p>Keep yourself and your systems up to date by automatically responding to changes. Actions monitor your data to notify you when your temperature sensor gets too hot or when your DIY security system goes offline.</p>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12 promo">
                <h4>Hardware</h4>
                <p>We have been building <a href='https://www.adafruit.com/iot'>Internet of Things devices and kits</a> since long before Adafruit IO ever existed. Start your project with the right equipment or pick up the one last thing you need to finish. We've got hardware for all types of makers.</p>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12 promo">
                <h4>Amazing Community</h4>
                <p><strong>We Love Makers!</strong> Join us <a href='https://forums.adafruit.com/viewforum.php?f=56'>on the forums</a>, on <a href='https://discord.gg/adafruit'>our Discord chat server</a>, stop by <a href='https://blog.adafruit.com/category/show-and-tell/'>the weekly Show and Tell live stream</a> to share your project, and <a href='https://youtube.com/adafruit'>subscribe to our YouTube channel</a> to see what we're up to.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="desk-image">
          <div className="container">
            <div className="row">
              <div className="col-md-6 hidden-sm"></div>
              <div className="col-md-6 col-sm-12 hero">
                <div className='box'>
                  <p>
                    <strong>Shop for hardware</strong> designed to work great with Adafruit IO.
                    <br />
                    <a className='btn blue' href={import.meta.env.VITE_SHOP_URL}>Shop Hardware</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="plans">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 heading">
                <h2>Our pricing is as simple as our API.</h2>
                <h4><strong>Try Adafruit IO for free.</strong> Unlock its full potential for $10 per month.</h4>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 hidden-sm"></div>
              <div className="col-md-3 col-sm-6">
                <div className='plan'>
                  <div className='header'>Get Started</div>
                  <div className='prices'>
                    <div className='price'>
                      <div className='primary'>FREE</div>
                      <div className='sub'>forever</div>
                    </div>
                  </div>
                  <div className='benefits'>
                    <ul>
                      <li><strong>30</strong> data points per minute</li>
                      <li><strong>30</strong> days of data storage</li>
                      <li>Actions every <strong>15 minutes</strong></li>
                      <li><strong>5</strong> dashboard limit</li>
                      <li><strong>2</strong> WipperSnapper device limit</li>
                      <li><strong>5</strong> group limit</li>
                      <li><strong>10</strong> feed limit</li>
                    </ul>
                  </div>
                  <div className='signup'>
                    { this.freeSignUpLink() }
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className='plan'>
                  <div className='header'>Power Up</div>

                  <div className='prices'>
                    <div className='price'>
                      <div className='primary'>$10</div>
                      <div className='sub'>per month</div>
                    </div>
                    <div className='or'>or</div>
                    <div className='price'>
                      <div className='primary'>$99</div>
                      <div className='sub'>per year</div>
                    </div>
                  </div>

                  <div className='benefits'>
                    <ul>
                      <li><strong>60</strong> data points per minute</li>
                      <li><strong>60</strong> days of data storage</li>
                      <li>Actions every <strong>5 seconds</strong></li>
                      <li><strong>25</strong> sms messages per day (UTC) for actions and the Messages Power-Up (USA and Canada)</li>
                      <li><strong>Unlimited</strong> dashboards</li>
                      <li><strong>Unlimited</strong> WipperSnapper devices</li>
                      <li><strong>Unlimited</strong> groups</li>
                      <li><strong>Unlimited</strong> feeds</li>
                      <li><a href={Paths.plus()}>Learn more about IO+</a></li>
                    </ul>
                  </div>
                  <div className='signup'>
                    { this.plusSignUpLink() }
                  </div>
                </div>
              </div>
              <div className="col-md-3 hidden-sm"></div>
            </div>
          </div>
        </div>

        <div className="closer">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 heading">
                <h2>Last but not least</h2>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 col-sm-6">
                <p> Don't forget that we have some really great documentation.</p>
                <p><a href={import.meta.env.VITE_DOCUMENTATION_URL}>Check out the API Docs</a></p>
                <p><a href={import.meta.env.VITE_BEGINNERS_URL}>Check out the beginners' guides</a></p>
              </div>
              <div className="col-md-3 col-sm-6">
                <p>All our IoT products are ready to work with Adafruit IO.</p>
                <p><a href='https://www.adafruit.com/product/2680'>Huzzah Adafruit IO Starter Kit</a></p>
                <p><a href='https://www.adafruit.com/product/2821'>Adafruit Feather Huzzah</a></p>
                <p><a href='https://github.com/adafruit/Adafruit_IO_Arduino'>Adafruit IO Arduino Library</a></p>
              </div>
              <div className="col-md-3 col-sm-6">
                <p>We believe Internet of Things devices should come with a well established expectation of what they will and will not do with consumer’s data.</p>
                <p><a href='https://www.adafruit.com/iotbor/'>View our IoT Bill of Rights</a></p>
                <p><a href='/terms'>Our Terms of Service</a></p>
                <p><a href='https://www.adafruit.com/privacy'>Our Privacy Policy</a></p>
              </div>
              <div className="col-md-3 col-sm-6">
                <p>Still confused about all of this Internet of Things Adafruit IO Stuff?</p>
                <p><Link to='/faq'>We answer a lot of questions in our FAQ</Link> but if that's not enough you can <Link to='/contact'>get in touch with us</Link>.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapState = state => ({
  loading: state.session.loading,
  client: state.session.client,
  user: state.session.user,
})

const ConnectedLanding = connect(mapState)(sized(Landing))
export default ConnectedLanding
