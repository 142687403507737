// curl https://io.adafruit.com/api/v2/plans
export default {
  "free": {
    "name": "Free",
    "limits": {
      "feeds": 10,
      "dashboards": 5,
      "groups": 5,
      "wipper_devices": 2,
      "data_ttl": 2592000,
      "data_rate": 30,
      "sms_rate": 0
    },
    "interval": "month",
    "price_in_cents": 0.0,
    "price_formatted": "$0"
  },
  "io-plus-1": {
    "name": "IO Plus",
    "stripe_id": "io-plus-1",
    "limits": {
      "feeds": 1000000,
      "dashboards": 1000000,
      "groups": 1000000,
      "wipper_devices": 1000000,
      "data_ttl": 5184000,
      "data_rate": 60,
      "sms_rate": 25
    },
    "interval": "month",
    "interval_label": "Monthly",
    "price_in_cents": 1000.0,
    "price_formatted": "$10.00"
  },
  "io-plus-12": {
    "name": "IO Plus",
    "stripe_id": "io-plus-12",
    "limits": {
      "feeds": 1000000,
      "dashboards": 1000000,
      "groups": 1000000,
      "wipper_devices": 1000000,
      "data_ttl": 5184000,
      "data_rate": 60,
      "sms_rate": 25
    },
    "interval": "year",
    "interval_label": "Yearly",
    "price_in_cents": 9900.0,
    "price_formatted": "$99.00"
  }
}
