import Immutable from 'immutable'
import { handleActions } from 'redux-actions'

import { logException } from 'utils/errors'
import { errorsTopic, throttlesTopic, clientsTopic } from 'utils/streaming'
import { buildAPIReducers } from 'utils/reducers'
import log from 'utils/logger'
import { parseDate, addTime } from 'utils/dates'
import { nanoid } from 'utils/strings'

const
  TRACING = false,
  trace = (...args) => (TRACING || window.MQTT_DEBUG) && log(...args)

function isDashboardStream(topic) {
  return topic.indexOf('/dashboard/stream/') >= 0
}

function isErrorsStream(topic) {
  return /\/errors$/.test(topic)
}

function isClientsStream(topic) {
  return topic.split('/').length === 2 && topic.indexOf('/clients') >= 0
}

function isFeedStream(topic) {
  return topic.split('/').length === 4 && topic.indexOf('/feeds/') >= 0
}

function feedMessageToDashboardFormat(message) {
  // Inbound:
  // {
  //   "id": 3,
  //   "name": "Welcome Feed",
  //   "last_value": "15.745213769680065",
  //   "created_at": "2018-05-16 19:39:23 UTC",
  //   "updated_at": "2018-07-05 19:06:41 UTC",
  //   "key": "welcome-feed",
  //   "username": "paste",
  //   "data": {
  //     "value": "15.745213769680065",
  //     "location": [],
  //     "id": "0DXGV69JXD2G3P0M9NV60R4DT8"
  //   }
  // }

  // data messages should have feed_id (number),
  return {
    feed_id: message.id,
    feed_key: message.key,
    client_received_at: message.client_received_at,
    id: message.data.id,
    value: message.data.value,
    created_at: message.data.created_at,
  }
}

const initialState = {
  data: [],
  datum: [],
  tableOptions: {},
  tablePagination: {}
};

const dataAPI = buildAPIReducers('data', initialState);

function updateDataTableOptions(state, action) {
  const { feed, options } = action.payload
  return Object.assign({}, state, {
    tableOptions: Object.assign({}, state.tableOptions, {
      [feed.id]: options
    })
  })
}

function updateDataTablePagination(state, action) {
  const { feed, pagination } = action.payload

  let nextTablePagination = Object.assign({}, state.tablePagination)
  nextTablePagination[feed.id] = pagination

  return Object.assign({}, state, {
    tablePagination: nextTablePagination
  })
}

export const data = (state, action) => {
  switch (action.type) {
    case 'DATA_UPDATE_FEED_TABLE_OPTIONS':
      return updateDataTableOptions(state, action)

    case 'DATA_UPDATE_FEED_TABLE_PAGINATION':
      return updateDataTablePagination(state, action)

    default:
      // pass everything else to the API reducers
      return dataAPI(state, action)
  }
}

// When receiving new feed data, we don't actually care what group the datum /
// message specifies, since it all ends up in the Feed's stream, regardless of
// which group it was sent to or whether it was sent to a group at all.
// Therefore, we should always return the feed_id alone as the streamID.
function dataStreamID(record) {
  if (record.feed_id) {
    return record.feed_id.toString()
  } else if (record.id) {
    return record.id.toString()
  } else {
    return null
  }
}

function newFeedStore() {
  return Immutable.fromJS({
    _ids: {}, // for faster ID lookup
    live: [],

    // for fast uniqueness checking
    ranges: {}
  })
}

function newSubscriptionStore() {
  return Immutable.fromJS({
    connected: true,
    lastUpdatedAt: null,
    values: []
  })
}

// function newClientConnection() {
//   return Immutable.fromJS({
//     connected: true,
//     connectedAt: new Date(),
//     disconnectedAt: null,
//     description: []
//   })
// }

// live feed data, the record stored in Redux { dataSubscriptions } is an Immutable.Map
const defaultSubscriptions = Immutable.fromJS({
  lastUpdatedAt: null,
  streamingConnected: false,

  // Data by generic MQTT topic.
  subscriptions: {},

  // streaming client connect / disconnect
  clients: {},

  newErrors: false,
  globalFeed: [],

  // Data by feed. This piece of global state is used by blocks and on feed show pages.
  //
  // {
  //  [ feed_id ]: {
  //    // Searches without resolution and subscriptions end up here.
  //    // Order should be: 'ORDER BY created_at DESC'.
  //    // Every datum in this list MUST have an ID, all IDs MUST be unique.
  //    live: [],
  //
  //    // searches by resolution, assume it will be refreshed every time you query
  //    // for feed data with a given resolution.
  //    resolution: {
  //      [ $resolution ]: [],
  //      [ ... ]: []
  //    }
  //  }
  //
  byFeedID: {}
})

const dataSubscriptions = handleActions({
  // this is used for non-feed subscriptions such as errors and throttle
  SUBSCRIBE_TO_TOPIC_FULFILLED(state, { payload }) {
    const { topic } = payload

    if (state.hasIn(['subscriptions', topic])) {
      // topic has been subscribed in the past
      return state.setIn(['subscriptions', topic, 'connected'], true)
    } else {
      // first time we've seen this topic
      return state.setIn(['subscriptions', topic], newSubscriptionStore())
    }
  },

  UNSUBSCRIBE_FROM_TOPIC_FULFILLED(state, { payload }) {
    const { topic } = payload
    return state.setIn(['subscriptions', topic, 'connected'], false)
  },

  // since data is all stored in dataSubscriptions, we need to intercept some Data API actions
  DATA_DESTROY_FULFILLED(state, { payload }) {
    const { feed_id, id } = payload.object

    if(!(feed_id && id)) {
      logException(new Error(`DATA_DESTROY_FULFILLED: 'id' and 'feed_id' must both be present in response.\nGot: ${ JSON.stringify(payload.object, null, 2) }.`))
      return state
    }

    // return feed live stream without given datum
    return state.updateIn(liveSelector(feed_id), live =>
      live
        ? live.filter(d => d.get('id').toString() !== id.toString())
        : live
    )
  },

  // handle inbound arrays of data associated with a given live feed
  FEED_DATA_LOAD_FULFILLED(state, action) {
    const payload = action.payload
    const { feed, data } = payload

    if (!('id' in feed) && data[0] && ('feed_id' in data[0])) {
      // this is probably a data load for a Feed/show page, get feed_id from first datum
      feed.id = data[0].feed_id
    }

    return bulkImportData(state, feed, data)
  },

  // handle inbound arrays of aggregate data associated with a given feed
  AGGREGATE_FEED_DATA_LOAD_FULFILLED(state, { payload }) {
    const { feed, data, params } = payload
    const range = params.range
    const streamID = dataStreamID(feed)

    if (streamID === null) {
      logException(new Error('AGGREGATE_FEED_DATA_LOAD_FULFILLED failed to get a stream ID'), { feed, params })
      return state
    }

    let feedIn = feedSelector(streamID),
        _state = state

    if (!_state.hasIn(feedIn))
      _state = _state.setIn(feedIn, newFeedStore())

    return _state.setIn(rangeSelector(streamID, range), Immutable.fromJS(data))
  },

  DATA_SUBSCRIPTION_MESSAGE_RECEIVED(state, { payload }) {
    let { topic, message } = payload

    trace("DATA_SUBSCRIPTION_MESSAGE_RECEIVED on topic", topic, 'with message', message)

    if (!message.id) {
      // set unique ID on message
      message.id = nanoid(12)
      trace("DATA_SUBSCRIPTION_MESSAGE_RECEIVED set id on message", message.id, message)
    }

    if (isDashboardStream(topic) || isFeedStream(topic)) {
      trace("DATA_SUBSCRIPTION_MESSAGE_RECEIVED with feed or dashboard topic", topic)

      if (isFeedStream(topic)) {
        // {username}/feeds/{key}/json produces data in a different format than
        // {username}/dashboard/streams/create, we need to handle that
        message = feedMessageToDashboardFormat(message)
        trace("DATA_SUBSCRIPTION_MESSAGE_RECEIVED converted message", message)
      }

      // use bulk import but only add a single message/data point. immediately
      // add raw point to global feed for live monitor
      const nextState = state.update('globalFeed', list => list.push(message).takeLast(100))

      // add to specific feed
      return bulkImportData(nextState, message, [ message ])

    } else if (isClientsStream(topic)) {
      // normal subscription data store, sorted newest first
      return state.
        updateIn(['subscriptions', topic, 'values'], values => {
          return values.unshift(message)
        })

    } else {
      let isError = /throttle$/.test(topic) || /errors$/.test(topic)

      // throttle, error, etc. sorted newest first
      return state.
        set('newErrors', isError).
        updateIn(['subscriptions', topic, 'values'], values => {
          return values.unshift(message)
        })
    }
  },

  DATA_SUBSCRIPTION_MESSAGE_FAILED(state, { payload }) {
    const { topic, error } = payload
    return state.mergeDeep(Immutable.fromJS({
      [ topic ]: { error }
    }))
  },

  SUBSCRIPTION_MANAGER_CONNECTED(state) {
    return state
      .set('streamingConnected', true)
      .set('streamingError', null)
  },

  SUBSCRIPTION_MANAGER_DISCONNECTED(state) {
    return state
      .set('streamingConnected', false)
      .set('streamingDisconnectedAt', new Date())
  },

  SUBSCRIPTION_MANAGER_CONNECTION_FAILED(state) {
    return state.set('streamingConnected', false)
  },

  SUBSCRIPTION_MANAGER_ERROR(state, { payload }) {
    return state.set('streamingError', payload)
  },

  // clear live feed data
  DATA_RESET_FEED_SUBSCRIPTION(state, { payload }) {
    const { username, feed } = payload
    const streamID = dataStreamID(feed)

    if (streamID === null) {
      logException(new Error('DATA_RESET_FEED_SUBSCRIPTION failed to get a stream ID'), { username, feed })
      return state
    }

    return state.setIn(liveSelector(streamID), new Immutable.List())
  },

  CLEAR_ERRORS_DATA(state, { payload }) {
    const { username } = payload
    return state.
      set('newErrors', false).
      setIn(['subscriptions', errorsTopic(username), 'values'], new Immutable.List()).
      setIn(['subscriptions', throttlesTopic(username), 'values'], new Immutable.List())
  },

}, defaultSubscriptions)

// we want NEWEST FIRST
function sortByID(list) {
  const field = 'id'
  return list.sort((a, b) => {
    if (a.get(field) > b.get(field)) {
      return -1
    } else if (a.get(field) < b.get(field)) {
      return 1
    } else {
      return 0
    }
  })
}

// update date fields for Feed messages
function preprocessReceivedMessage(preMessage) {
  preMessage.created_at = parseDate(preMessage.created_at)
  preMessage.stream_id  = dataStreamID(preMessage)

  if (!Object.prototype.hasOwnProperty.call(preMessage, 'id')) {
    preMessage.id = nanoid(12)
  }

  return Immutable.fromJS(preMessage)
}

function feedSelector(id) {
  return ['byFeedID', id.toString()]
}

function liveSelector(id) {
  return ['byFeedID', id.toString(), 'live']
}

function rangeSelector(id, range) {
  return ['byFeedID', id.toString(), 'ranges', range]
}

// filter out JS data records that appear in current Immutable.js collection
function filteredData(currentIDs, points) {
  return points.filter(jsPoint => {
    return !currentIDs.has(jsPoint.id)
  })
}

// Add incoming data to the appropriate feed live collection without limit.
//
// ALL DATA MUST PASS THROUGH THIS METHOD.
//
// feedLike should be a feed with an `id` property or a datum with `feed_id` property.
function bulkImportData(state, feedLike, data) {
  const streamID = dataStreamID(feedLike)

  if (Array.isArray(data) && data.length === 0 || !Array.isArray(data)) {
    // ignore everything else
    trace('empty Array or non-Array data received, data:', data)
    return state;
  }

  if (streamID === null) {
    trace('no streamID available from feedLike record:', feedLike)
    logException(new Error('bulkImportData failed to add data to feedLike record'), { feedLike, data })
    return state
  }

  const feedIn = feedSelector(streamID)

  // make sure state has a place for this feed's data
  let feedStore

  // if data size > 1024 bytes, the feed has history turned off, so we can replace the feed store
  const noHistoryFeed = data[0] && data[0].value && data[0].value.length > 1024;

  if (!state.hasIn(feedIn) || noHistoryFeed) {
    trace('state has no place for feedIn', feedIn, '... creating')
    feedStore = newFeedStore()
  } else {
    trace('storing feedIn', feedIn)
    feedStore = state.getIn(feedIn)
  }

  let existing = feedStore.get('live')
  let existingIDs = feedStore.get('_ids')

  // get list of data points not already stored
  let fresh = filteredData(existingIDs, data)

  trace('prepared fresh data for insert', fresh)

  // use immutable.js batch update to add data to collection and add IDs to cache
  let feedData = existing.withMutations(list => {
    fresh.forEach(point => {
      trace('appending point', point)
      list.push(preprocessReceivedMessage(point))
    })
  })

  let feedIDs = existingIDs.withMutations(map => {
    fresh.forEach(point => {
      trace('appending existing ID', point.id)
      map.set(point.id, true)
    })
  })

  trace('next feedData in', feedIn, sortByID(feedData).toJSON())

  feedStore = feedStore.merge({
    'live': sortByID(feedData),
    '_ids': feedIDs
  })

  return state.setIn(feedIn, feedStore).set('lastUpdatedAt', new Date())
}


/// SELECTORS
const DataSelectors = {
  getStreamWithGroupAndFeed(state, group, feed) {
    const streamID = dataStreamID(feed)
    if (streamID)
      return state.dataSubscriptions.getIn(liveSelector(streamID), new Immutable.List()).toJS()
    else
      return []
  },

  getAggregateStreamWithChartAndFeed(state, chart, feed) {
    const range = chart.filter.range
    const chartID = `feed-${range}-${feed.key}`
    return state.charts[chartID]
  },

  getStreamWithTopic(state, topic) {
    return state.dataSubscriptions.getIn(['subscriptions', topic], newSubscriptionStore()).toJS()
  },

  getLastUpdatedAt(state) {
    return state.dataSubscriptions.get('lastUpdatedAt')
  },

  getErrorMessages(state, username) {
    return {
      errors: state.dataSubscriptions.getIn(['subscriptions', errorsTopic(username), 'values']).toJS(),
      throttle: state.dataSubscriptions.getIn(['subscriptions', throttlesTopic(username), 'values']).toJS(),
    }
  },

  getDeviceStream(state, device_id) {
    return state.dataSubscriptions.get('globalFeed').filter(datum => {
      return datum.device && datum.device.id === device_id
    }).toJS()
  },

  getGlobalStream(state) {
    return state.dataSubscriptions.get('globalFeed').toJS()
  },

  getClients(state, username) {
    const vals = state.dataSubscriptions.getIn(['subscriptions', clientsTopic(username), 'values'])
    if (vals)
      return vals.toJS()
    else
      return []
  },

  hasNewErrors(state) {
    return state.dataSubscriptions.get('newErrors')
  },

  connected(state) {
    return !!state.dataSubscriptions.get('streamingConnected')
  },

  getStreamingError(state) {
    return state.dataSubscriptions.get('streamingError')
  },

  getDisconnectTime(state) {
    return state.dataSubscriptions.get('streamingDisconnectedAt')
  },

  filterStreamWithBlock(stream, block) {
    // max for charts on Adafruit IO
    let earliest = addTime(new Date(), -24, 'hours')

    // blocks describe hours of history to retain, ignore data that lies outside the selected range
    const { historyHours } = block.properties
    const fHours = parseFloat(historyHours)
    if (!isNaN(fHours) && fHours > 0.01) {
      earliest = addTime(new Date(), -fHours, 'hours')
    }

    return stream.filter(datum => {
      return earliest < datum.created_at
    })
  },

  getTableOptions(state, feed) {
    if (!feed)
      return {}
    else
      return state.data.tableOptions[feed.id] || {}
  },

  getTablePagination(state, feed) {
    if (!feed)
      return {}
    else
      return state.data.tablePagination[feed.id] || {}
  },
}

export {
  dataSubscriptions, DataSelectors,
  isErrorsStream, isDashboardStream, isFeedStream,
  feedMessageToDashboardFormat
}
