import { handleActions } from 'redux-actions'

const initialState = {
  set: false,
  options: {},   // active bc state
}

const breadcrumbs = handleActions({
  SET_BREADCRUMBS(state, { payload }) {
    return Object.assign({}, state, {options: payload, set: true})
  },

  CLEAR_BREADCRUMBS() {
    return initialState
  }
}, initialState)

export default breadcrumbs
