import { handleActions } from 'redux-actions'

import log from 'utils/logger'

const initialState = {
  all: [],
  by_id: {}
}

const messages = handleActions({
  GET_ALL_MESSAGE_FULFILLED(state, { payload }) {
    return Object.assign({}, state, {all: payload})
  },

  GET_MESSAGE_FULFILLED(state, { payload }) {
    const nextStore = Object.assign({}, state.by_id)
    nextStore[payload.id] = payload
    return Object.assign({}, state, {by_id: nextStore})
  },

  CREATE_MESSAGE_FULFILLED(state, { payload }) {
    log("created message record", payload)
    return Object.assign({}, state, {all: state.all.concat(payload)})
  },

  CREATE_MESSAGE_FAILED(state, { payload }) {
    log("failed to create message record", payload)
    return state
  },

  DELETE_MESSAGE_FULFILLED(state, { payload }) {
    let nextStore = state.all.map(message => {
      if (message.id === payload.id) return null
      else return message
    }).filter(record => record !== null)

    return Object.assign({}, state, {all: nextStore})
  },
}, initialState)


export default messages

const MessageSelectors = {
  all(state) {
    return state.messages.all || []
  },

  get(state, id) {
    return state.messages.by_id[id]
  }
}

export { MessageSelectors }

