import React from 'react'
import { connect } from 'react-redux'

import FeedbackModal from './FeedbackModal'
import PickFeedbackType from './PickFeedbackType'
import ForumLink from './ForumLink'
import FeedbackForm from './FeedbackForm'
import FeedbackSignIn from './FeedbackSignIn'
import FeedbackThanks from './FeedbackThanks'

import 'stylesheets/feedback-creator.scss'


class FeedbackCreator extends React.Component {
  view() {
    const { id, step, selectedType, user } = this.props

    if(!user) { return <FeedbackSignIn identifier={ id }/> }

    switch (step) {
      case 'pick_type':
        return <PickFeedbackType identifier={ id } />
      case 'forum':
        return <ForumLink identifier={ id } />
      case 'feedback':
        return <FeedbackForm identifier={ id } selectedType={ selectedType } />
      case 'thanks':
        return <FeedbackThanks identifier={ id } />
      default:
        return (
          <strong>NO STEP</strong>
        )
    }
  }

  render() {
    return (
      <FeedbackModal title='Feedback? Need Help?' id='feedback-create-modal' identifier={ this.props.id }>
        <div className='feedback-creator'>
          { this.view() }
        </div>
      </FeedbackModal>
    )
  }
}

const mapState = (state, { selectedType }) => ({
  user: state.session?.user,
  step: selectedType
    ? (state.feedback.step === 'thanks') ? 'thanks' : 'feedback'
    : state.feedback.step
})

const ConnectedFeedbackCreator = connect(mapState)(FeedbackCreator)
export default ConnectedFeedbackCreator
