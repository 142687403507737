import React from 'react'

import DocumentTitle from 'components/TopLevel/DocumentTitle'
import { SmallButton3d } from 'components/Buttons'


// Legacy Adafruit accounts may not have a username associated with them.
// We redirect such users to accounts.adafruit. Once they've created a username
// they'll be redirected here and forwarded along to their home on IO.
const UsernameError = () => (
  <div id="fullscreen">
    <DocumentTitle title="Adafruit IO - Username Error" />

    <div id="main" role="main">
      <div id="content-container" className="container">
        <div className="row">
          <div className="col-lg-8 col-md-8 col-lg-push-2 col-md-push-2 col-sm-12 col-xs-12">
            <h1>Oh no!</h1>

            <p>
              It looks like your account doesn't have a username setup. You won't
              be able to use Adafruit IO until you've created a username. Go
              to <a href={ import.meta.env.VITE_ACCOUNTS_URL }>your account settings page</a> to create a username.
            </p>

            <p>
              <SmallButton3d action={ import.meta.env.VITE_ACCOUNTS_URL }>Update Account Settings</SmallButton3d>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default UsernameError
