import { toPairs } from 'lodash'
import { handleActions } from 'redux-actions';

import { isDashboardStream, isFeedStream, feedMessageToDashboardFormat } from './data'
// import log from 'utils/logger'
import { parseDate } from 'utils/dates'

const MAX_CHART_LENGTH = 640

function calculateRange(data) {
  let range = { min: Infinity, max: -Infinity };

  data.forEach(({ value }) => {
    if (value < range.min) range.min = value;
    if (value > range.max) range.max = value;
  })

  return range
}

const initialState = { };

export default handleActions({
  // handle inbound live data, add to all charts displaying the feed
  DATA_SUBSCRIPTION_MESSAGE_RECEIVED(state, { payload }) {

    let { message, topic } = payload;

    if (isFeedStream(topic)) {
      message = feedMessageToDashboardFormat(message)
    }

    const value = parseFloat(message.value);

    if ((isDashboardStream(topic) || isFeedStream(topic)) && !isNaN(value)) {
      const feedId = message.feed_id
      const point = { date: parseDate(message.created_at), value: value }

      toPairs(state).forEach(([chartID, chart]) => {
        if (chart.feed.id.toString() === feedId.toString()) {
          // add point in place to all charts with feed having the given id
          state[chartID].data.push(point)

          while (state[chartID].data.length > MAX_CHART_LENGTH) {
            state[chartID].data.shift()
          }

          // recalculate range and modify in place
          Object.assign(state[chartID]['range'], calculateRange(state[chartID].data))
        }
      })
    }

    return state
  },

  CHART_DATA_FULFILLED(state, { payload }) {
    const nextCharts = {}

    // convert [ string, string ] to [ date, number ] or null
    const normalized = payload.chart.data.map((point) => {
      if (point[1] === '') return null;

      const date = parseDate(point[0]),
            value = parseFloat(point[1])

      if (isNaN(value)) return null;

      return { date, value }
    })

    let range = calculateRange(normalized)

    nextCharts[payload.chartID] = {
      feed: payload.chart.feed,
      data: normalized.filter((p) => p !== null),
      range: range,
      parameters: payload.chart.parameters
    }

    return Object.assign({}, state, nextCharts);
  },
}, initialState);
