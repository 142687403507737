import React from 'react'
import { Provider } from 'react-redux'

// FIXME: race condition.
// actions/data MUST be imported before store
import 'actions/data'
import store from 'store'


const IODataProvider = ({ children }) =>
  <Provider store={ store }>
    { children }
  </Provider>

export default IODataProvider
