import React from 'react'

import { SquareButton } from 'components/Buttons'
import logException from 'utils/errors'
import Alert from 'components/Alerts'


class ErrorBoundary extends React.PureComponent {
  state = {
    hasError: false,
    error: null,
    showError: false
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, info) {
    logException(error, {
      'ErrorBoundary': this.props.errorPrefix,
      ...info
    })
  }

  renderOptionalErrorDetail() {
    const { name, message, stack } = this.state.error

    return this.state.showError
      ?  <code style={{ whiteSpace: 'pre', overflowX: 'auto', justifySelf: 'normal' }}>
            Name: { name }<br/>
            Message: "{ message }"<br/>
            Stacktrace:<br/>
              { stack }
          </code>
      : <SquareButton blue tooltip="Show Error" action={ () => this.setState({ showError: true }) }>
          Show Error Details
        </SquareButton>
  }

  render() {
    if(!this.state.hasError) { return this.props.children }

    const { error } = this.state

    return <div style={{ margin: '40px 20px 0' }}>
      <Alert danger>
        <h2>Oops! An error occurred on this page...</h2>

        <ul>
          <li>🔄 You can try refreshing your browser</li>
          <li>👆 Other pages may be unaffected, try the links above</li>
          <li>💌 The IO team has been automatically notified about this error</li>
        </ul>

        <p>
          If you'd like to report this error to us directly,
          please <a href="/support" title="Contact Support">contact support!</a>
        </p>

        { error && this.renderOptionalErrorDetail() }
      </Alert>
    </div>
  }
}

export default ErrorBoundary
