import { toPairs } from 'lodash'
import { handleActions } from 'redux-actions'

import { buildAPIReducers } from '../utils/reducers';

const initialState = {
  // default API
  feeds: [],
  feed: {},
  downloadModal: {show: false, feed: null}
};

const feedsAPI = buildAPIReducers('feed', initialState);

function showDownloadModal(state, action) {
  const { feed } = action.payload
  return Object.assign({}, state, { downloadModal: {show: true, feed: feed}})
}

function hideDownloadModal(state) {
  return Object.assign({}, state, { downloadModal: {show: false, feed: null}})
}

function createFeedWebhookReceiver(state, action) {
  const webhookReceiver = action.payload

  let nextFeed = state.feed
  if (Array.isArray(nextFeed.feed_webhook_receivers)) {
    nextFeed.feed_webhook_receivers.push(webhookReceiver)
  }

  return Object.assign(state, { feed: nextFeed })
}

function deleteFeedWebhookReceiver(state, action) {
  const webhookReceiver = action.payload

  let nextFeed = state.feed
  let nextReceivers = []
  if (Array.isArray(nextFeed.feed_webhook_receivers)) {
    for (var i=0; i < nextFeed.feed_webhook_receivers.length; i++) {
      if (nextFeed.feed_webhook_receivers[i].id !== webhookReceiver.id) {
        nextReceivers.push(nextFeed.feed_webhook_receivers[i])
      }
    }
  }

  return Object.assign({}, state, {
    feed: Object.assign({}, nextFeed, { feed_webhook_receivers: nextReceivers })
  })
}

export const feeds = (state, action) => {
  switch (action.type) {
    case 'FEEDS_SHOW_DOWNLOAD_MODAL':
      return showDownloadModal(state, action)

    case 'FEEDS_HIDE_DOWNLOAD_MODAL':
      return hideDownloadModal(state, action)

    case 'CREATE_FEED_WEBHOOK_RECEIVER_FULFILLED':
      return createFeedWebhookReceiver(state, action)

    case 'DELETE_FEED_WEBHOOK_RECEIVER_FULFILLED':
      return deleteFeedWebhookReceiver(state, action)

    default:
      // pass everything else to the API reducers
      return feedsAPI(state, action)
  }
}

const initialChartState = () => ({
  // single map chart appearing on a single page, map charts require location
  // information which means they have to get actual data records and not
  // aggregate data.
  map_chart: {
    filter: { range: 'live' },
    feeds: [],
    visible: false
  },
  // single line chart appearing on a single page.
  line_chart: {
    filter: { range: 'day' },
    feeds: [],
    visible: false
  },
})

const initialCharts = initialChartState()

const nextChart = (chart) => {
  return (state, { payload }) => {
    const nextChart = Object.assign({}, state[chart], payload)
    return Object.assign({}, state, { [ chart ]: nextChart })
  }
}

export const feedCharts = handleActions({
  FEEDS_LINE_CHART_TOGGLE(state, action) {
    return nextChart('line_chart')(state, action)
  },

  FEEDS_MAP_CHART_TOGGLE(state, action) {
    return nextChart('map_chart')(state, action)
  },

  FEEDS_UPDATE_CHART_FILTER(state, action) {
    // unpack { chart, filter } payload
    const payload = { filter: action.payload }
    return nextChart(action.payload.chart)(state, { payload })
  },

  FEEDS_RESET_CHART_FILTER() {
    // unpack { chart, filter } payload
    return initialChartState()
  },

}, initialCharts)

export const FeedSelectors = {
  // return unique feeds from a given collection, for example, if given a list
  // of feeds-by-group, ignore feeds that appear in multiple groups after we've
  // already seen them once.
  unique(feeds) {
    const fmap = {}

    feeds.forEach(feed => {
      const existing = fmap[feed.id]
      // prefer feeds with shorter
      if (!existing || (existing.key.length > feed.key.length)) {
        fmap[feed.id] = feed
      }
    })

    return toPairs(fmap).map(kv => kv[1]).sort((a, b) => {
      if (a.name < b.name) {
        return -1
      } else if (a.name > b.name) {
        return 1
      } else {
        return 0
      }
    })
  }
}
