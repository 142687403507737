import { compact, find, includes, map, uniq } from 'lodash'
import { createSelector } from '@reduxjs/toolkit'
import semverGte from 'semver/functions/gte'

import { selectAllBoards } from 'slices/boards'
import { selectDevices, selectCurrentDeviceComponents } from 'slices/devices'
import { selectCurrentComponentType } from 'slices/components'


export const
  // DEVICE CHECKINS
  currentCheckin = state => state.wprsnpr?.checkin || {},

  currentCheckinBoard = createSelector([currentCheckin, selectAllBoards], (checkin, boards) => {
    return checkin.machineName ?
      boards[checkin.machineName] :
      {}
  }),

  // FIRMWARE SEMVER
  currentSemver = state => state.wprsnpr.currentSemver,

  isUpToDate = (state, deviceKey) => {
    const
      device = find(selectDevices(state), { key: deviceKey }),
      deviceSemver = device.wipper_semver,
      latestSemver = currentSemver(state)

    return semverGte(deviceSemver, latestSemver)
  },

  // I2C
  currentComponentTypeI2CAddresses = createSelector([selectCurrentComponentType], type => {
    // they're string hex in the JSON, parse to ints
    return map(type.i2cAddresses || [], address => parseInt(address, 16))
  }),

  i2cScanning = state => state.wprsnpr.i2cScanning,
  i2cScanError = state => {
    const { type, message } = state.wprsnpr.i2cBusError

    return (type === "scan") && message
  },
  foundI2CAddresses = state => state.wprsnpr.foundI2CAddresses,

  usedI2CAddresses = createSelector([selectCurrentDeviceComponents], components => {
    return uniq(compact(map(components, "wipper_pin_info.i2cAddress")))
  }),

  i2cAddressStatus = createSelector([foundI2CAddresses], foundAddresses => {
    const addresses = {}
    for(let i = 7; i <= parseInt('77', 16); i++) {
      const
        hexidecimal = i.toString(16),
        address = i < 16 ?
          `0${hexidecimal}` :
          hexidecimal

      addresses[address] = !!includes(foundAddresses, i)
    }

    return addresses
  }),

  i2cDeviceInitError = state => {
    const { type } = state.wprsnpr.i2cBusError

    return (type === "deviceInit") && state.wprsnpr.i2cBusError
  }
