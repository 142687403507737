import React from 'react'
import { connect } from 'react-redux'

import { Paths } from 'utils/paths'
import { usernameSelector } from 'selectors/users'
import { LargeIconButton, RoundedButton } from 'components/Buttons'
import KeysModal from 'components/Keys/KeysModal'
import KeysActions from 'actions/keys'


function HeaderActions(props) {
  const { username, openKeyModal } = props
  if (!username) { return null }

  return (
    <ul className="main-action-links">
      <li>
        <KeysModal />
        <LargeIconButton gold icon="key" action={ openKeyModal } tooltip="API Key" />
      </li>
      <li>
        <RoundedButton white icon="plus" label="New Device" action={ Paths.new_device({ username }) }/>
      </li>
    </ul>
  )
}

const
  mapState = state => ({
    username: usernameSelector(state)
  }),

  mapDispatch = dispatch => ({
    openKeyModal: () => dispatch(KeysActions.toggleModal(true))
  })

const ConnectedHeaderActions = connect(mapState, mapDispatch)(HeaderActions)
export default ConnectedHeaderActions
