import { createActions } from 'redux-actions'

import { error } from 'utils/logger'

const Breadcrumbs = createActions({
  SET_BREADCRUMBS: crumbOptions => {
    if (!Object.prototype.hasOwnProperty.call(crumbOptions, 'title')) {
      error('expected setBreadcrumbs options to include title object')
    }

    return crumbOptions
  }
}, "CLEAR_BREADCRUMBS")

export default Breadcrumbs
