const NUMBER_MATCHER = /^-?\d+(\.\d+)?$/

export const isNumeric = function(strval) {
  if (typeof strval === 'undefined')
    return false

  if (typeof strval !== 'string')
    strval = strval.toString()

  return NUMBER_MATCHER.test(strval) && !Number.isNaN(parseFloat(strval))
}

export const toCamelCase = function(original, separator='_') {
  const matcher = new RegExp(`${separator}([a-z])`, 'g')
  const cased = original.replace(matcher, function (match, letter) { return letter.toUpperCase(); });
  return cased[0].toUpperCase() + cased.slice(1, cased.length)
}

// string to fixed place decimal number string
export const formatFloat = function (value, places=2, fixed=false) {
  let f = parseFloat(value)
  if (Number.isNaN(f)) {
    return "0"
  } else {
    if (places > 0) {
      f = f.toFixed(places)
      if (fixed) return f
      else {
        if (f.match(/\./)) {
          return f.replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/,'$1') // trim trailing 0s after decimal point
        } else {
          return f
        }
      }
    } else {
      return parseInt(value).toFixed(0)
    }
  }
}

export const toSentence = function (items) {
  const suffix = items.length === 2 ? ' and $1' : ', and $1'
  return items.join(", ").replace(/,\s([^,]+)$/, suffix)
}

// Takes strings, returns safe DOM ID values
export const safeID = function (...s) {
  return s.map((_s) => _s.replace(/([^a-z]|[^\w:.-])/gi, '-')).join('')
}

export const pluralize = (count, word, ending='s') => {
  return (`${count} ${word}` + (count === 1 ? '' : ending))
}

export const capitalize = (word) => {
  return word[0].toUpperCase() + word.slice(1, word.length)
}

const REL_MATCHER = /rel="(.*)"/
const URL_MATCHER = /<(.*)>/

export const parseLinkHeader = (text) => {
  let links = {}
  let parts = text.split(';').map(str => str.trim())

  parts.forEach(part => {
    if (part.length === 0) return

    let rel, url
    if (part.indexOf(',') >= 0) {
      let [ _rel, _url ] = part.split(',')
      rel = _rel.trim()
      url = _url.trim()
    } else {
      url = part.trim()
    }

    if (!rel) {
      rel = 'rel="current"'
    }

    let m_name = REL_MATCHER.exec(rel)
    let m_href = URL_MATCHER.exec(url)

    if (m_name && m_href) {
      let name = m_name[1]
      let href = m_href[1]

      links[name] = href
    }
  })

  return links
}

// based on: https://github.com/ai/nanoid#readme
const crypto = window.crypto || window.msCrypto || {
  getRandomValues: function (array) {
    for (var i=0; i < array.length; i++) {
      array[i] = Math.floor(Math.random() * 256)
    }
    return array
  }
}

const url_safe_characters = 'Uint8ArdomValuesObj012345679BCDEFGHIJKLMNPQRSTWXYZ_cfghkpqvwxyz-'

export const nanoid = function (size) {
  size = isNaN(parseInt(size)) ? 21 : parseInt(size)
  var id = ''
  var bytes = crypto.getRandomValues(new Uint8Array(size))
  while (0 < size--) {
    id += url_safe_characters[bytes[size] & 63]
  }
  return id
}

