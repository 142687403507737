import { useSelector } from 'react-redux'

import { Paths } from 'utils/paths'
import { usernameSelector } from 'selectors/users'
import Navigation from 'utils/navigation'


const buildRoute = (routeName, username, routeParams={}) => {
  if(!routeName) { throw new Error("buildRoute() requires a routeName") }

  const routeGenerator = Paths[routeName]

  if(!routeGenerator) {
    throw new Error(`No route entry in Paths named "${ routeName }"`)
  }

  // routeUsername may not be set on initial load
  if(!username && !routeParams?.username) {
    throw new Error(`routing hooks must have access to a session user or be passed a 'username' route param`)
  }

  // username may be overriden by routeParams prop
  const params = { username, ...routeParams }

  return routeGenerator(params)
}

export const
  useRoutes = (routeName, routeParams={}) => {
    const sessionUsername = useSelector(usernameSelector)

    return buildRoute(routeName, sessionUsername, routeParams)
  },

  useNavigation = () => {
    const sessionUsername = useSelector(usernameSelector)

    return (routeName, routeParams={}) => {
      const destination = buildRoute(routeName, sessionUsername, routeParams)

      Navigation.go(destination)
    }
  },

  // for use with onClick, handles "open in new tab" hotkeys
  useNavigateOnClick = () => {
    const sessionUsername = useSelector(usernameSelector)

    return (routeName, routeParams={}) => {
      const destination = buildRoute(routeName, sessionUsername, routeParams)

      return e => {
        // open in new tab: windows uses ctrl, mac uses meta
        const requestNewTab = e.ctrlKey || e.metaKey

        if(requestNewTab) {
          const
            origin = window.location.origin,
            externalDestination = `${origin}${destination}`

          window.open(externalDestination, '_blank')

        } else {
          Navigation.go(destination)
        }
      }
    }
  }

export default useRoutes
