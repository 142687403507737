import PropTypes from 'prop-types'
import React from 'react'
import cx from 'clsx'

import { formatFloat } from 'utils/strings';

import 'stylesheets/loading-spinner.scss'

export const Spinner = ({ blue }) => (
  <div className={ cx("loader", { "blue": blue }) }>Loading...</div>
)

function Loading({ requestState }) {
  if (!requestState) { return null }

  let message, errorMessage

  if (requestState.loading) {
    message = <div className='loading-message pending'>Loading...</div>
  } else if (requestState.started_at && requestState.completed_at) {
    const duration = (requestState.completed_at.getTime() - requestState.started_at.getTime()) / 1000;
    message = (
      <div className='loading-message'>
        Loaded in { formatFloat(duration) } seconds.
      </div>
    )
  } else {
    message = null
  }

  if (requestState.error) {
    errorMessage = <div className='error-message'>{ requestState.error.message }</div>
  }

  return (
    <div className='loading-container'>
      {message}
      {errorMessage}
    </div>
  )
}

Loading.propTypes = {
  requestState: PropTypes.shape({
    loading: PropTypes.bool,
    started_at: PropTypes.object, // date
    completed_at: PropTypes.object, // date
    error: PropTypes.object // Error
  })
}


export default Loading
