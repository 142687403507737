import React from 'react'

import WipperForm from 'components/WipperSnapper/Form'
import GuideViewer from 'components/GuideViewer/Viewer'
import FeedbackCreator from 'components/Feedback/FeedbackCreator'
import SystemMessages from 'components/Elements/SystemMessages';


const IOContent = ({ children }) => {

  return (
    <main id="main-content">
      <SystemMessages /> {/* appears at top of content, when present */}

      { children } {/* current page content */}

      {/* Modals: could be opened from anywhere */}
      <GuideViewer />
      <FeedbackCreator id="root" />
      <WipperForm />
    </main>
  )
}

export default IOContent
