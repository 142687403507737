import React from 'react'

// Dead simple function component that:
// - sets the document title as a side effect
// - sets it back to what it was upon cleanup
const DocumentTitle = ({ title }) => {
  React.useEffect(() => {
    const documentTitle = document.title
    document.title = title

    return () => {
      document.title = documentTitle
    }
  }, [title])

  return <></>
}

export default DocumentTitle
