import { withSize } from 'react-sizeme'

export default function (component, options={}) {
  // our react-sizeme defaults
  const config = Object.assign({}, {
    monitorHeight: true,
    refreshRate: 100,  // frequency of updates in ms, default is 16
    refreshMode: 'throttle'
  }, options)

  // Call SizeMe with the config to get back the Higher Order Component (HOC)
  return withSize(config)(component)
}
