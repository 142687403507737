import { handleActions } from 'redux-actions'

import log from 'utils/logger'

const initialState = {
  all: [],
  by_id: {}
}

const weathers = handleActions({
  GET_ALL_WEATHER_FULFILLED(state, { payload }) {
    return Object.assign({}, state, {all: payload})
  },

  GET_WEATHER_FULFILLED(state, { payload }) {
    const nextStore = Object.assign({}, state.by_id)
    nextStore[payload.id] = payload
    return Object.assign({}, state, {by_id: nextStore})
  },

  CREATE_WEATHER_FULFILLED(state, { payload }) {
    log("created weather record", payload)
    return Object.assign({}, state, {all: state.all.concat(payload)})
  },

  CREATE_WEATHER_FAILED(state, { payload }) {
    log("failed to create weather record", payload)
    return state
  },

  DELETE_WEATHER_FULFILLED(state, { payload }) {
    let nextStore = state.all.map(weather => {
      if (weather.id === payload.id) return null
      else return weather
    }).filter(record => record !== null)

    return Object.assign({}, state, {all: nextStore})
  },
}, initialState)


export default weathers

const WeatherSelectors = {
  all(state) {
    return state.weathers.all || []
  },

  get(state, id) {
    return state.weathers.by_id[id]
  }
}

export { WeatherSelectors }
