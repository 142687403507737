import { combineReducers } from '@reduxjs/toolkit'
import { routerReducer } from 'react-router-redux'

import breadcrumbs from './breadcrumbs'
import browsing from './browsing'
import cart from './cart'
import charts from './charts'
import feedback from './feedback'
import guides from './guides'
import invoices from './invoices'
import keys from './keys'
import navigation from './navigation'
import plans from './plans'
import profiles from './profiles'
import quotes from './quotes'
import session from './session'
import sharing from './sharing'
import systemMessages from './system_messages'
import view from 'slices/view'
import boards from 'slices/boards'
import componentTypes from 'slices/component_types'
import devices from 'slices/devices'
import components from 'slices/components'
import firmware_wizard from 'slices/firmware_wizard'
import device_autoconfig from 'slices/device_autoconfig'
import walkthroughs from './walkthroughs'
import weathers from './services/weather'
import messages from './services/messages'
import words from './services/words'
import wprsnpr from './wprsnpr'
import { activities } from './activities'
import { blocks, blockEditor } from './blocks'
import { dashboards, dashboardViewer } from './dashboards'
import { dynamicActions } from './dynamic_actions'
import { data, dataSubscriptions } from './data'
import { feeds, feedCharts } from './feeds'
import { groups, groupAddFeedForm } from './groups'
import { triggers, triggerEditor } from './triggers'


// This is what the top-level state looks like (in DevTools, for instance)
export default combineReducers({
  // Account
  cart,
  session,

  // Routing
  routing: routerReducer,

  // UI
  breadcrumbs,
  data,
  view,
  browsing,
  dataSubscriptions,
  dashboardViewer,
  blockEditor,
  quotes,
  walkthroughs,

  // Service Integrations
  weathers,
  messages,
  words,
  wprsnpr,
  firmware_wizard,
  device_autoconfig,

  // API records
  activities,
  blocks,
  charts,
  dashboards,
  dynamicActions,
  boards,
  componentTypes,
  devices,
  components,
  feedCharts,
  feedback,
  feeds,
  groupAddFeedForm,
  groups,
  guides,
  invoices,
  keys,
  navigation,
  plans,
  profiles,
  sharing,
  systemMessages,
  triggerEditor,
  triggers,
})
