import { extendRoute, extendIndexRoute, extendRedirect, extendIndexRedirect } from './react_router_v3_helpers'
import { forwardToHome, checkEveryPage, ownerOnly, authOnly, guideAccessOnly,
         ownerBlocklyBetaOnly, wrapPage, redirectToSessionRoute } from './route_helpers'

// These are components to use in routes.js
export const
  IORedirect = extendRedirect(),
  IOIndexRedirect = extendIndexRedirect(),

  IORootRoute = extendRoute({
    props: { onEnter: checkEveryPage }
  }),

  IOIndexRootRoute = extendIndexRoute({
    props: { onEnter: forwardToHome }
  }),

  IORoute = extendRoute({
    onEnterProps: { ownerOnly, authOnly, guideAccessOnly, ownerBlocklyBetaOnly },
    getComponentsProps: { page: wrapPage }
  }),

  IOIndexRoute = extendIndexRoute({
    onEnterProps: { ownerOnly, authOnly },
    getComponentsProps: { page: wrapPage }
  }),

  IOUsernameError = extendRoute({ props: { onEnter: forwardToHome }}),

  IOShortcut = extendRoute({ onEnterProps: { to: redirectToSessionRoute }})
