import { handleActions } from 'redux-actions'

import log from 'utils/logger' // eslint-disable-line

const initialState = {
  modal: {visible: false},
};

const blocks = handleActions({
  BLOCKS_TOGGLE_MODAL(state, { payload }) {
    const visible = payload
    return Object.assign({}, state, { modal: { visible } })
  },
}, initialState)


const blockEditorInitialState = {
  step: 'type',
  definition: {},
  hiddenGroupsByID: [],
  modifyHidden: false,
  saveError: null,

  // if existing block is given, we're editing. If not, we're creating.
  block: null,
  form: {
    id: null,
    dashboard_id: null,
    visual_type: null,
    feeds: [],
    // In a Block record, block_feeds is a list of { feed, group } objects, in
    // a block editor form record it's a list of { group_id, feed_id } records
    // where each _id field is a unique integer ID
    block_feeds: [],
    properties: {},
    name: null,
    column: null,
    row: null,
    size_x: null,
    size_y: null
  }
}

const blockEditor = handleActions({
  BLOCKS_SELECT_EDITOR_STEP(state, { payload }) {
    log("BLOCKS_SELECT_EDITOR_STEP", payload, "for definition", state.definition, 'from', state.step)

    // don't show feeds step when max_feeds == 0
    if (state.definition.max_feeds === 0 && payload === 'feeds')  {
      if (state.step === 'settings') {
        payload = 'type'
      } else {
        payload = 'settings'
      }
    }

    return Object.assign({}, state, { step: payload })
  },

  BLOCKS_SET_EDITOR_BLOCK(state, { payload }) {
    const { block, definition } = payload

    const formBlockFeeds = block.block_feeds.map(bf => {
      return {
        group_id: bf.group ? bf.group.id : null,
        feed_id: bf.feed.id,
      }
    })

    const nextEditor = {
      block,
      definition,
      step: 'feeds',
      form: Object.assign({}, state.form, block, {
        block_feeds: formBlockFeeds
      })
    }

    return Object.assign({}, state, nextEditor)
  },

  BLOCKS_SET_CREATE_TYPE(state, { payload }) {
    // takes a type and a block definition, populates the form
    const { blockType, block } = payload

    const defaultProperties = {}
    block.properties.forEach(field => {
      defaultProperties[field.name] = field.defaultValue
    })

    const default_size = block.default_size || block.min_size

    const form = Object.assign({}, state.form, {
      visual_type: blockType,
      properties: defaultProperties,
      size_x: default_size[0],
      size_y: default_size[1]
    })

    if (form.visual_type === 'indicator') {
      form.name = `Status Indicator`
    }

    return Object.assign({}, state, {
      form,
      definition: block
    })
  },

  BLOCKS_CREATE_REJECTED(state, { payload }) {
    let saveError
    if (payload.obj && payload.obj.message) {
      saveError = `Error creating block. ${payload.obj.message}`
    } else {
      saveError = "Server error, failed to create block."
    }
    return Object.assign({}, state, { saveError })
  },

  BLOCKS_UPDATE_REJECTED(state, { payload }) {
    let saveError
    if (payload.obj && payload.obj.message) {
      saveError = `Error updating block. ${payload.obj.message}`
    } else {
      saveError = "Server error, failed to update block."
    }
    return Object.assign({}, state, { saveError })
  },


  BLOCKS_SELECT_FEEDS(state, { payload }) {
    const block_feeds = payload
    const form = Object.assign({}, state.form, {
      feeds: block_feeds,
      block_feeds
    })
    return Object.assign({}, state, { form })
  },

  BLOCKS_UPDATE_BLOCK_NAME(state, { payload }) {
    const name = payload
    const form = Object.assign({}, state.form, { name })
    return Object.assign({}, state, { form })
  },

  BLOCKS_EDITOR_UPDATE_BLOCK_PROPERTY(state, { payload }) {
    const { name, value } = payload
    const properties = Object.assign({}, state.form.properties, { [name]: value })
    const form = Object.assign({}, state.form, { properties })
    return Object.assign({}, state, { form })
  },

  BLOCKS_RESET_EDITOR(state) {
    const form = Object.assign({}, blockEditorInitialState.form)
    return Object.assign({}, state, { form, block: null, saveError: null })
  },

  BLOCKS_RESET_SELECTED_FEEDS(state) {
    const form = Object.assign({}, state.form, { block_feeds: [] })
    return Object.assign({}, state, { form })
  },

  BLOCKS_EDITOR_RESET_GROUP_HIDING(state, { payload }) {
    // don't mess with group hiding once user has modified it
    if (state.modifyHidden) return state;

    // ... otherwise, hide every group that is not default
    const { user, groups } = payload
    const notDefault = groups.filter(group => group.id !== user.default_group_id)
    return Object.assign({}, state, { hiddenGroupsByID: notDefault.map(group => group.id) })
  },

  BLOCKS_EDITOR_SHOW_GROUP(state, { payload }) {
    const others = state.hiddenGroupsByID.filter(other => other !== payload)
    return Object.assign({}, state, { hiddenGroupsByID: others, modifyHidden: true })
  },

  BLOCKS_EDITOR_HIDE_GROUP(state, { payload }) {
    return Object.assign({}, state, { hiddenGroupsByID: state.hiddenGroupsByID.concat(payload), modifyHidden: true })
  },



}, blockEditorInitialState)


/// SELECTORS

function first(arrayOrNull, defVal={}) {
  if (arrayOrNull && Array.isArray(arrayOrNull) && arrayOrNull.length > 0) {
    return arrayOrNull[0]
  } else {
    return defVal
  }
}

const BlockSelectors = {
  getFeedWithBlock(state, block) {
    return first(block.block_feeds).feed
  },

  getGroupWithBlock(state, block) {
    return first(block.block_feeds).group
  },

  getFeedsWithBlock(state, block) {
    return block.block_feeds.map(bf => bf.feed)
  },

  getGroupFeedsWithBlock(state, block) {
    if (!block?.block_feeds) { return null }

    let seen = {}
    return block.block_feeds.map(bf => ({
      group: bf.group,
      feed:  bf.feed
    })).sort((a, b) => {
      if (a.feed.id < b.feed.id) {
        return -1
      } else if (a.feed.id > b.feed.id) {
        return 1
      } else {
        return 0
      }
    }).filter(bf => {
      if (seen[bf.feed.id]) return false;
      seen[bf.feed.id] = true;
      return true
    })
  }
}

export { blockEditor, blocks, BlockSelectors }
