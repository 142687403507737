const
  // normalized local subscription ID, also happens to be a valid MQTT Topic
  feedTopic = (u, k) => `${u}/feeds/${k}/json`,
  // normalized local subscription ID, also happens to be a valid MQTT Topic
  groupTopic = (u, k) => `${u}/groups/${k}/json`,
  // used for monitoring Data events on dashboards (create only)
  dashboardTopic = (username) => `${username}/dashboard/stream/create`,
  // used for monitoring Data events on devices (create only)
  deviceTopic = (username, id) => `${username}/device/${id}/+`,
  // used for monitoring Data events on dashboards (create only)
  publicDashboardTopic = (username) => `${username}/dashboard/stream/create/public`,

  errorsTopic = (username) => `${username}/errors`,
  throttlesTopic = (username) => `${username}/throttle`,
  clientsTopic = (username) => `${username}/clients`,

  // WipperSnapper
  // broad WipperSnapper actions
  wprsnprsActionTopic = (username, action) => {
    return `${username}/wprsnpr/${action}`
  },
  // WipperSnapper actions related to a particular device
  wprsnprsDeviceActionTopic = (username, deviceId, action) => {
    return `${username}/wprsnpr/${deviceId}/${action}`
  },
  // WipperSnapper actions related to a particular device component
  wprsnprComponentActionTopic = (username, deviceId, action) => {
    return `${username}/wprsnpr/${deviceId}/components/${action}`
  },
  // WipperSnapper actions related to a device's I2C components
  wprsnprI2CComponentActionTopic = (username, deviceId, action) => {
    return `${username}/wprsnpr/${deviceId}/components/i2c/${action}`
  }

export { feedTopic, groupTopic, dashboardTopic, deviceTopic,
  publicDashboardTopic, errorsTopic, throttlesTopic, clientsTopic,
  wprsnprsActionTopic, wprsnprsDeviceActionTopic, wprsnprComponentActionTopic,
  wprsnprI2CComponentActionTopic }
