import { isString } from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import Alert from 'components/Alerts'

class Err extends React.Component {

  static propTypes = {
    reset: PropTypes.func.isRequired,
    errors: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array,
      PropTypes.object
    ]),
  }

  componentWillUnmount() {
    this.props.reset()
  }

  allErrors() {
    const { errors } = this.props

    // ensure we're using an array of strings
    return (isString(errors)
      ? [errors]
      : (errors?.message
        ? [errors.message]
        : (errors || [])
      )
    )
  }

  renderErrors() {
    return this.allErrors().map((e, i) => {
      return <p key={i}>
        <b>Error:</b> {e}
      </p>
    })
  }

  anyErrors() {
    return this.allErrors().length > 0
  }

  render() {
    return this.anyErrors() &&
      <Alert danger onDismiss={ this.props.reset }>
        { this.renderErrors() }
      </Alert>
  }
}

export default Err
