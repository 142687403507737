import { handleActions } from 'redux-actions'

const INITIAL_STATE = {
  currentSemver: "0.0.0-beta0",
  checkin: null,
  showPopover: false,
  currentComponent: null,
  currentComponents: [],
  i2cScanning: false,
  foundI2CAddresses: [],
  i2cBusError: {}
}

export default handleActions({
  WPRSNPR_SEMVER_LOAD_FULFILLED(state, { payload }) {
    return {
      ...state,
      currentSemver: payload
    }
  },

  WPRSNPR_CHECKIN(state, { payload: { machineName, deviceId, semver } }) {
    return { ...state, checkin: { machineName, deviceId, semver }, showPopover: true }
  },

  WPRSNPR_CLEAR_CHECKIN(state) {
    return { ...state, checkin: null }
  },

  WPRSNPR_HIDE_POPOVER(state) {
    return { ...state, showPopover: false }
  },

  WPRSNPR_SET_CURRENT_COMPONENT(state, { payload }) {
    return { ...state, currentComponent: payload }
  },

  WPRSNPR_SET_CURRENT_COMPONENTS(state, { payload }) {
    return { ...state, currentComponents: payload }
  },

  WPRSNPR_SET_I2C_SCANNING(state, { payload }) {
    return { ...state, i2cScanning: payload }
  },

  WPRSNPR_CLEAR_I2C_ADDRESSES(state) {
    return { ...state, foundI2CAddresses: [] }
  },

  WPRSNPR_SET_I2C_ADDRESSES(state, { payload }) {
    return { ...state, foundI2CAddresses: payload }
  },

  WPRSNPR_I2C_BUS_ERROR(state, { payload }) {
    return { ...state, i2cBusError: payload }
  }
}, INITIAL_STATE)
