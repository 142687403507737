import { handleActions } from 'redux-actions'

const initialState = {
  showModal: { show: false, identifier: null },
  step: null,
  subject: 'I need help with IO',
  submitted: null
}

const feedback = handleActions({

  CREATE_FEEDBACK_FULFILLED(state, { payload }) {
    return Object.assign({}, state, { submitted: payload })
  },

  LOAD_FEEDBACK_FULFILLED(state, { payload }) {
    return Object.assign({}, state, { feedbacks: payload })
  },

  FEEDBACK_TOGGLE_MODAL(state, { payload }) {
    return Object.assign({}, state, { showModal: payload })
  },

  FEEDBACK_LOAD_STEP(state, { payload }) {
    // set step: and subject:
    return Object.assign({}, state, payload)
  }

}, initialState)

export default feedback
