import { handleActions } from 'redux-actions';

const initialState = {
  count: 0
};

export default handleActions({
  CART_COUNT(state, { payload }) {
    return Object.assign({}, state, {
      count: payload.count
    });
  },
}, initialState);
