import React from 'react';
import DocumentTitle from 'components/TopLevel/DocumentTitle';

import landingImg from './landing.png';

class Maintenance extends React.Component {

  state = {
    user: false
  };

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="landing" >
        <DocumentTitle title="Welcome to Adafruit IO" />
        <div className="landing-image">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <img src={landingImg} className="img-responsive" />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <div className="landing-intro">
                <p>
                  Adafruit IO is currently down for maintenance. For more information, please check <a href="https://io.adafruit.com/blog">the IO blog</a> and <a href="https://forums.adafruit.com/viewtopic.php?f=56&t=111948">the Adafruit forums</a> for more information.
                  We will be restoring service as soon as possible.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Maintenance;
