import { some } from "lodash"
import React, { useRef, useEffect } from "react"

/**
 * Component that calls a callback if you click outside of it
 */
const OutsideClickDetector = props => {
  const
    { onOutsideClick, ignoreOtherTargets=[] } = props,
    wrapperRef = useRef(null),
    handleClickOutside = ({ target }) => {
      const insideRefs = [ wrapperRef ].concat(ignoreOtherTargets)
      // bail if any of our refs are inside the target
      if(some(insideRefs, ref => ref.current?.contains(target))) { return }

      onOutsideClick()
    }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)

  }, [wrapperRef])

  return <span ref={ wrapperRef }>{ props.children }</span>
}

export default OutsideClickDetector
