import { isFunction } from 'lodash'
import React from 'react'
import { Link } from 'react-router'

// A link that can tell whether its target is internal to the app, and uses
// a React Router <Link>, or external and uses an <a>
const
  SmartLink = props => !props.to
    ? renderNoLink(props) // no 'to' prop: no link
    : isFunction(props.to)
      ? renderOnClick(props) // is function: do onClick
      : isExternal(props.to)
        ? renderAnchor(props) // is external path: render a href
        : renderLink(props), // is app path: render router Link

  renderNoLink = props => <>{ props.children }</>,

  renderOnClick = props => (
    <a className={ props.className } onClick={ props.to }>
      { props.children }
    </a>
  ),

  // link is external if fully qualified but not pointing here
  isExternal = url => (
    url.startsWith("http") && !url.startsWith(document.location.origin)
  ),

  renderAnchor = props => (
    <a className={ props.className } href={ props.to }>
      { props.children }
    </a>
  ),

  renderLink = props => (
    <Link { ...props }>
      { props.children }
    </Link>
  )

export default SmartLink
