import { handleActions } from 'redux-actions'

import log from 'utils/logger' // eslint-disable-line

const initialState = {
  loading: false,
  regenerating: false,
  modal: {visible: false},
  keys: [],
};

export default handleActions({
  KEYS_ALL_FULFILLED(state, { payload }) {
    const keys = payload
    return Object.assign({}, state, { loading: false, keys })
  },

  KEYS_ALL_FAILED(state) {
    return Object.assign({}, state, { loading: false })
  },

  KEYS_ALL_PENDING(state) {
    return Object.assign({}, state, { loading: true })
  },

  KEYS_CREATE_PENDING(state) {
    return Object.assign({}, state, { regenerating: true })
  },

  KEYS_CREATE_FULFILLED(state) {
    return Object.assign({}, state, { regenerating: false })
  },

  KEYS_TOGGLE_MODAL(state, { payload }) {
    const visible = payload
    return Object.assign({}, state, { modal: { visible } })
  }
}, initialState)
