import { format, parseISO, formatDistanceToNow, formatDistanceStrict } from 'date-fns'
import { error } from 'utils/logger'

export function isDate (x) {
  return (null !== x) && !isNaN(x) && ("undefined" !== typeof x.getDate);
}

function zpad(num, count) {
  const isNeg = num < 0
  let n = Math.abs(Math.floor(num))
  let s = n.toString()
  while (s.length < count) {
    s = "0" + s
  }
  if (isNeg) {
    s = "-" + s
  }
  return s
}

export function tzOffset() {
  return zpad(-(new Date().getTimezoneOffset() / 60 * 100), 4)
}

export function parseDate(time) {
  try {
    if (isDate(time)) {
      return time
    } else if (typeof time === 'string') {
      return parseISO(time)
    } else if (typeof time === 'number') {
      let millis = time

      // new Date(time_int) expects time as milliseconds, but Stripe sends it as a
      // seconds. Assume that if a date before 1971 is given, that it's in seconds.
      if (millis < 10000000000) {
        millis *= 1000.0
      }

      return new Date(millis)
    } else if (time === null || typeof time === 'undefined') {
      return null
    } else {
      error("not sure how to parse date", typeof time, time)
      return new Date(0)
    }
  } catch (ex) {
    error("not sure how to parse date", typeof time, time)
    return null
  }
}

export function day(d) {
  const date = parseDate(d)
  return date ? format(date, 'LLLL d, yyyy') : ''
}

export function time(d) {
  const date = parseDate(d)
  return date ? format(date, 'h:mm:ssa') : ''
}

export function dayTime(d, options) {
  let fmt = 'LLLL do yyyy, h:mma'
  if (options && options.seconds) {
    fmt = 'LLLL do yyyy, h:mm:ssa'
  }

  const date = parseDate(d)
  return date ? format(date, fmt) : ''
}

export function shortDayTime(d, options={}) {
  const
    fmt = options.seconds
      ? 'yyyy/LL/dd hh:mm:ssa'
      : 'yyyy/LL/dd hh:mma',
    date = parseDate(d)

  return date ? format(date, fmt) : ''
}

export function secondsToHumanReadable(s) {
  s = Math.floor(s)

  // console.log('seconds', s)
  const m = 60
  const h = 60 * 60

  let hrs = Math.floor(s / h)
  if (hrs > 0) {
    // console.log('hours', s)
    s -= hrs*h
  }

  let mns = Math.floor(s / m)
  if (mns > 0) {
    // console.log('minutes', s)
    s -= mns*m
  }

  function ending(n) {
    return n !== 1 ? 's' : ''
  }

  let out = []
  if (hrs > 0) {
    out.push(`${hrs} hour${ending(hrs)}`)
  }
  if (mns > 0) {
    out.push(`${mns} minute${ending(mns)}`)
  }
  out.push(`${s} second${ending(s)}`)

  return out.join(' ')
}

export function timeAgo(d) {
  const date = parseDate(d)

  if (date) {
    let suffix = 'ago'
    if (date > new Date()) {
      suffix = 'from now'
    }
    return formatDistanceToNow(date) + ' ' + suffix
  }

  return ''
}

export function longDayTime(d) {
  const date = parseDate(d)
  return date ? format(date, 'LLLL do yyyy, h:mm:ssa') : ''
}

export function fromNow(d) {
  const date = parseDate(d)
  return date ? formatDistanceToNow(date) : ''
}

export function iso8601(d) {
  const date = parseDate(d)
  return date ? date.toISOString() : ''
}

export function datetimeInput(d) {
  const date = parseDate(d)
  return date ? format(date, "yyyy-LL-dd'T'HH:mm") : ''
}

export function addTime(initial, amount, unit) {
  let date = parseDate(initial)

  if (isDate(date)) {
    const newt = new Date(initial.getTime())
    let millis = 0
    switch (unit) {
      case 'years':
      case 'year':
        millis = amount * 1000 * 60 * 60 * 24 * 365
        break
      case 'months':
      case 'month':
        millis = amount * 1000 * 60 * 60 * 24 * 30
        break
      case 'weeks':
      case 'week':
        millis = amount * 1000 * 60 * 60 * 24 * 7
        break
      case 'days':
      case 'day':
        millis = amount * 1000 * 60 * 60 * 24
        break
      case 'hours':
      case 'hour':
        millis = amount * 1000 * 60 * 60
        break
      case 'minutes':
      case 'minute':
        millis = amount * 1000 * 60
        break
      case 'seconds':
      case 'second':
        millis = amount * 1000
        break
      default:
        millis = amount
        break
    }
    newt.setTime(newt.getTime() + millis)
    return newt
  } else {
    return date
  }
}

export function humanDuration(amount, unit) {
  // get the minimal human expression of the duration
  const start = new Date()
  const end = addTime(start, amount, unit)

  return formatDistanceStrict(start, end)
}
