import { chain, cloneDeep, compact, filter, find, includes, isEmpty, isEqual, map, some, omit, pick } from 'lodash'
import { createSlice, createSelector } from '@reduxjs/toolkit'

import { selectCurrentDevice, selectCurrentDeviceBoard, selectCurrentDeviceComponents, selectCurrentDeviceOnline,
  selectCurrentDevicePinStatus, selectCurrentDeviceAvailablePins, refreshCurrentDevice, selectCurrentDeviceUARTPins,
  selectCurrentDeviceI2CSettings, getComponentTypeAvailability } from 'slices/devices'
import { selectAllComponentTypes, selectMultiComponentTypes } from 'slices/component_types'
import FeedActions from 'actions/feeds'
import DataActions from 'actions/data'


// TODO: write a "how to slice" document
const { actions, reducer } = createSlice({
  name: 'components',
  initialState: {
    currentComponent: null,
    currentComponents: []
  },
  reducers: {
    clearCurrentComponents: state => ({ ...state, currentComponent: null, currentComponents: [] }),
    setCurrentComponent: (state, { payload: currentComponent }) => ({ ...state, currentComponent, currentComponents: [] }),
    setCurrentComponents: (state, { payload: currentComponents }) => ({ ...state, currentComponents, currentComponent: null }),
  },
})

export const
  // ACTIONS
  { clearCurrentComponents, setCurrentComponent, setCurrentComponents } = actions,

  clearCurrentComponentType = () => (dispatch, getState) => {
    if(selectCurrentComponent(getState())) {
      dispatch(updateCurrentComponent({ wipper_pin_info: { type: null } }))
    } else {
      dispatch(clearCurrentComponents())
    }
  },

  initCurrentComponentByType = componentType => dispatch => {
    // call specific init method by component type
    if(componentType.isPin) {
      dispatch(initPinComponentType(componentType))

    } else if(componentType.isServo) {
      dispatch(initServoComponentType(componentType))

    } else if(componentType.isI2C) {
      dispatch(initI2CComponentType(componentType))

    } else if(componentType.isDS18X20) {
      dispatch(initDS18X20ComponentType(componentType))

    } else if(componentType.isPWM) {
      dispatch(initPWMComponentType(componentType))

    } else if(componentType.isPixel) {
      dispatch(initPixelComponentType(componentType))

    } else if(componentType.isUART) {
      dispatch(initUARTComponentType(componentType))

    } else {
      throw new Error("Component Type Unknown")
    }
  },

  initCurrentComponentByExisting = existingComponent => (dispatch, getState) => {
    const { isI2C, isDS18X20, isUART } = existingComponent.wipper_pin_info

    if(isI2C || isDS18X20 || isUART) {
      const componentsForForm = getAllSiblingsForComponent(getState(), existingComponent)
      dispatch(setCurrentComponents(componentsForForm))

    } else {
      const componentForForm = pick(existingComponent, ["name", "key", "wipper_pin_info"])
      dispatch(setCurrentComponent(componentForForm))
    }
  },

  initPinComponentType = pinType => (dispatch, getState) => {
    const
      state = getState(),
      name = deduplicateName(state, pinType.displayName),
      pinName = autoSelectPin(state, pinType.autoSelectString, pinType.mode),
      // initialize component props from component type
      defaultAttributes = pick(pinType, [
        "type", "mode", "direction", "pull", "analogReadMode"
      ]),
      // construct actual component
      component = {
        name,
        wipper_pin_info: {
          pinName,
          ...defaultAttributes,
          period: pinType.defaultPeriod
        }
      }

    return dispatch(setCurrentComponent(component))
  },

  initServoComponentType = servoType => (dispatch, getState) => {
    const
      state = getState(),
      // initialize component props from component type
      defaultAttributes = pick(servoType, [
        "type", "mode", "direction", "frequency", "minPulseWidth", "maxPulseWidth"
      ]),
      // construct actual component
      component = {
        name: deduplicateName(state, servoType.displayName),
        wipper_pin_info: defaultAttributes
      }

    return dispatch(setCurrentComponent(component))
  },

  initI2CComponentType = i2cType => (dispatch, getState) => {
    const
      state = getState(),
      // build components from each subcomponent in the type
      components = map(i2cType.subcomponents, subcomponent => ({
        enabled: true, // default all subcomponents to enabled: true
        type: subcomponent.type,
        sensorType: subcomponent.sensorType,
        name: deduplicateName(state, `${i2cType.displayName}: ${subcomponent.displayName}`),
        period: subcomponent.defaultPeriod,
      }))

    return dispatch(setCurrentComponents(components))
  },

  initDS18X20ComponentType = ds18x20Type => (dispatch, getState) => {
    const
      state = getState(),
      // build components from each subcomponent in the type
      components = map(ds18x20Type.subcomponents, subcomponent => ({
        enabled: true, // default all subcomponents to enabled: true
        type: subcomponent.type,
        sensorType: subcomponent.sensorType,
        name: deduplicateName(state, `${ds18x20Type.displayName}: ${subcomponent.displayName}`),
        period: subcomponent.defaultPeriod,
        sensorResolution: subcomponent.sensorResolution
      }))

    return dispatch(setCurrentComponents(components))
  },

  initPWMComponentType = pwmType => (dispatch, getState) => {
    const
      state = getState(),
      fixedFrequency = pwmType.pwmSetting === "fixedFrequency",
      // initialize component props from component type
      defaultAttributes = pick(pwmType, [ "type", "pwmSetting" ]),
      // construct actual component
      component = {
        name: deduplicateName(state, pwmType.displayName),
        wipper_pin_info: {
          ...defaultAttributes,
          frequency: fixedFrequency
            ? 5000
            : null,
          resolution: fixedFrequency
            ? 12
            : 10
        }
      }

    return dispatch(setCurrentComponent(component))
  },

  initPixelComponentType = pixelType => (dispatch, getState) => {
    const
      state = getState(),
      { autoSelectString } = pixelType,
      pinProps = {}

    if(pixelType.pixelsType === 'NEOPIXEL') {
      pinProps["pinName"] = autoSelectPin(state, autoSelectString, 'digital')

    } else if(pixelType.pixelsType === 'DOTSTAR'){
      pinProps["dataPinName"] = autoSelectPin(state, autoSelectString, 'digital', 'data')
      pinProps["clockPinName"] = autoSelectPin(state, autoSelectString, 'digital', 'clock')
    }

    const
      // initialize component props from component type
      typeAttributes = pick(pixelType, [ "type", "pixelsType" ]),
      // construct actual component
      component = {
        name: deduplicateName(state, pixelType.displayName),
        wipper_pin_info: {
          pixelNumber: 1,
          pixelsOrder: pixelType.defaultPixelsOrder,
          pixelBrightness: 255,
          ...pinProps,
          ...typeAttributes,
        }
      }

    return dispatch(setCurrentComponent(component))
  },

  initUARTComponentType = uartType => (dispatch, getState) => {
    const
      state = getState(),
      // initialize component props from component type
      typeAttributes = pick(uartType, [ "baudRate", "inverted" ]),
      // hoist the UART pins up from the board def
      { rx: uartRx, tx: uartTx } = selectCurrentDeviceUARTPins(state),
      components = map(uartType.subcomponents, subcomponent => ({
        enabled: true, // default all subcomponents to enabled: true
        type: subcomponent.type,
        sensorType: subcomponent.sensorType,
        name: deduplicateName(state, `${uartType.displayName}: ${subcomponent.displayName}`),
        period: subcomponent.defaultPeriod,
        uartRx, uartTx,
        ...typeAttributes
      }))

    return dispatch(setCurrentComponents(components))
  },

  updateCurrentComponent = payload => (dispatch, getState) => {
    const componentToUpdate = selectCurrentComponent(getState())
    if(!componentToUpdate) { return }

    dispatch(setCurrentComponent({
      ...componentToUpdate,
      ...payload,
      wipper_pin_info: {
        ...componentToUpdate.wipper_pin_info,
        ...payload.wipper_pin_info
      }
    }))
  },

  updateCurrentComponentsProperty = (propertyName, propertyValue) => (dispatch, getState) => {
    const componentsToUpdate = selectCurrentComponents(getState())
    if(componentsToUpdate.length === 0) { return }

    dispatch(setCurrentComponents(
      componentsToUpdate.map(subcomponent => ({
        ...subcomponent,
        [propertyName]: propertyValue // update every subcomponent's i2cAddress
      }))
    ))
  },

  // MQTT ACTIONS
  publishComponentRemove = (deviceKey, componentKey, componentType, payload) => dispatch => {
    const
      { isPin, isServo, isDS18X20, isPWM, isPixel, isUART } = componentType,
      action = (isPin && "remove") ||
        (isServo && "detachServo") ||
        (isDS18X20 && "updateDS18X20") ||
        (isPWM && "detachPWM") ||
        (isPixel && "detachPixel") ||
        (isUART && "detachUART")

    if(!action) { throw new Error(`No remove action known for component:`, componentKey) }

    return dispatch(DataActions.publishToWipperComponentAction(deviceKey, componentKey, action, payload))
  },

  // API ACTIONS
  createComponent = component => async (dispatch, getState) => {
    const
      deviceKey = selectCurrentDevice(getState())?.key,
      createPayload = {
        feed: {
          group_ids: deviceKey,
          ...component
        }
      },
      // calls Rails API
      response = await dispatch(FeedActions.create(createPayload)),
      componentKey = response.value?.object?.key

    if(!componentKey) {
      // bad response from API
      const error = response.value?.object?.error?.join(', ')
      throw new Error(error || `Invalid API response: ${JSON.stringify(response)}`)
    }

    return componentKey
  },

  updateComponent = component => dispatch => (
    dispatch(FeedActions.replace({
      feed_key: component.key,
      feed: omit(component, ["key"])
    }))
  ),

  destroyComponent = componentKey => dispatch => (
    dispatch(FeedActions.destroy({ feed_key: componentKey }))
  ),

  saveCurrentComponent = () => async (dispatch, getState) => {
    const
      state = getState(),
      componentType = selectCurrentComponentType(state),
      { isPin, isI2C, isServo, isDS18X20, isPWM, isPixel, isUART } = componentType,
      saveAction = (isPin && savePinComponent)
        || (isI2C && saveI2CComponent)
        || (isServo && saveServoComponent)
        || (isDS18X20 && saveDS18X20Component)
        || (isPWM && savePWMComponent)
        || (isPixel && savePixelComponent)
        || (isUART && saveUARTComponent),
      componentsToSave = (isI2C || isDS18X20 || isUART)
        ? selectCurrentComponents(state)
        : selectCurrentComponent(state)

    if(!saveAction) { throw new Error(`No 'save' action for component type: ${componentType.type}`) }
    if(!componentsToSave) { throw new Error(`No components selected for save.`) }

    const componentKey = await dispatch(saveAction(componentsToSave))

    // TODO: can we NOT do a manual refresh after saves?
    // required in order for next key to be generated correctly
    await dispatch(refreshCurrentDevice())

    return componentKey
  },

  destroyCurrentComponent = () => async (dispatch, getState) => {
    // determine current component type and branch
    const
      state = getState(),
      { isI2C, isDS18X20, isUART } = selectCurrentComponentType(state)

    if(isI2C || isDS18X20 || isUART) {
      const existingComponents = filter(selectCurrentComponents(state), 'key')
      await dispatch(destroyMultiComponent(existingComponents))

    } else {
      await dispatch(destroySingularComponent(selectCurrentComponent(state)))
    }

    return await dispatch(refreshCurrentDevice())
  },

  destroyMultiComponent = components => async (dispatch, getState) => {
    const
      state = getState(),
      { key: deviceKey } = selectCurrentDevice(state),
      keys = map(components, "key"),
      firstComponent = (components[0] || {}),
      { isI2C, isDS18X20, isUART } = firstComponent

    // delete each via api request
    await Promise.all(map(keys, key => dispatch(destroyComponent(key))))

    if(isI2C) {
      const
        i2cPort = selectCurrentDeviceI2CSettings(state),
        sensorTypes = map(components, "sensorType"),
        { i2cAddress } = firstComponent

      // send batch delete to the broker for the device
      return dispatch(DataActions.publishToWipperI2CComponentAction(deviceKey, "batch", {
        i2cAddress, i2cPort, batch: {
          create: [],
          update: [],
          destroy: sensorTypes
        }
      }))

    } else if(isDS18X20) {
      const { pinName } = firstComponent

      return dispatch(publishComponentRemove(deviceKey, null, { isDS18X20 }, {
        pinName,
        useDeinit: true
      }))

    } else if(isUART) {
      const componentType = selectCurrentComponentType(state).type

      return dispatch(publishComponentRemove(deviceKey, null, { isUART }, { componentType }))
    }
  },

  destroySingularComponent = component => (dispatch, getState) => {
    const
      state = getState(),
      { key: deviceKey } = selectCurrentDevice(state),
      { key: componentKey, wipper_pin_info: componentType } = component

    // TODO: telling Node to do removal before DB removal, otherwise the component
    // will be gone from the DB by the time Node asks for it. doing this with a timeout
    // is a quick fix but can't be counted on always/forever
    dispatch(publishComponentRemove(deviceKey, componentKey, componentType))

    return new Promise(resolve => setTimeout(
      () => resolve(dispatch(destroyComponent(componentKey))), 400
    ))
  },

  // PER-COMPONENT TYPE ACTIONS
  // TODO: factor commonalities and simplify these
  savePinComponent = pinComponent => (dispatch, getState) => {
    const
      state = getState(),
      // clone so we don't mutate state, API response does that for us
      componentToSave = cloneDeep(pinComponent),
      creating = isEmpty(componentToSave.key)

    // get flagged as a pin component
    componentToSave.wipper_pin_info.isPin = true

    // new pin component...
    if(creating) {
      // needs to generate the key
      componentToSave.key = selectNextComponentKey(state)
    }

    // Default the component name to reflect the pin name
    if(isEmpty(componentToSave.name)) {
      const
        { pinName } = componentToSave.wipper_pin_info,
        boardDefinition = selectCurrentDeviceBoard(state),
        { digitalPins, analogPins } = boardDefinition.components,
        pins = digitalPins.concat(analogPins),
        pinDefinition = find(pins, { name: pinName })

      componentToSave.name = pinDefinition?.displayName
    }

    return creating
      ? dispatch(createPinComponent(componentToSave))
      : dispatch(updatePinComponent(componentToSave))
  },

  createPinComponent = wipperData => (dispatch, getState) => {
    const deviceKey = selectCurrentDevice(getState()).key

    return dispatch(createComponent(wipperData))
      .then(componentKey => {
        // add now if the device is connected
        if(selectCurrentDeviceOnline(getState())) {
          dispatch(DataActions.publishToWipperComponentAction(deviceKey, componentKey, "add"))
        }

        return componentKey
      })
  },

  updatePinComponent = wipperData => (dispatch, getState) => {
    const
      state = getState(),
      deviceKey = selectCurrentDevice(state)?.key,
      componentKey = wipperData.key,
      notConnected = !selectCurrentDeviceOnline(state),
      onlyCosmetic = currentComponentHasCosmeticChangesOnly(state),
      getApiPromise = () => dispatch(updateComponent(wipperData))

    return (notConnected || onlyCosmetic) ?
      // no device connected, or purely cosmetic changes? just hit the api and return
      getApiPromise() :
      // coordinate the device to remove and re-add the component around the update
      new Promise(resolve => {
        // remove from connected device
        resolve(dispatch(DataActions.publishToWipperComponentAction(deviceKey, componentKey, "remove")))
      })// wait a moment
        .then(() => new Promise(resolve => setTimeout(resolve, 400)))
        // call the Rails API
        .then(getApiPromise)
        // add back to the connected device
        .then(() => dispatch(DataActions.publishToWipperComponentAction(deviceKey, componentKey, "add")))
  },

  saveI2CComponent = components => (dispatch, getState) => {
    const
      state = getState(),
      deviceKey = selectCurrentDevice(state).key,
      i2cAddress = components[0]?.i2cAddress

    // save feeds
    // each subcomponent makes an API request
    return Promise.all(components.map(subcomponent => {
      // create/update/delete a feed
      const
        // translate from redux to rails/db
        component = {
          ...pick(subcomponent, ["name", "key"]),
          wipper_pin_info: {
            found: !!subcomponent.found,
            isI2C: true,
            ...pick(subcomponent, ["type", "period", "i2cAddress", "sensorType"])
          }
        },
        exists = !isEmpty(subcomponent.key),
        { enabled, sensorType } = subcomponent,
        actionToTake = exists ?
          (enabled ?
            "update" :
            "destroy") :
          "create"

      if(!exists) {
        if(!enabled) { return Promise.resolve() }

        // Looks like: ws-0x38-ambient-temp
        component.key = `ws-0x${i2cAddress.toString(16)}-${sensorType}`
      }

      // need the key in the closure for reference in .then()
      const key = includes(component.key, '.') ? // if it is a compound key
        component.key : // use it, otherwise
        // build the compound key
        `${deviceKey}.${component.key}`

      return (actionToTake === "create"
        ? dispatch(createComponent(component))
        : actionToTake === "update"
          ? dispatch(updateComponent(component))
          : dispatch(destroyComponent(component.key))
      ).then(() => ({ action: actionToTake, key, sensorType }))
    }))
      .then(compact)
      .then(actions => {
        // group the keys by action
        const
          batch = {
            // array of keys to create
            "create": map(filter(actions, ['action', 'create']), "key"),
            // ...update
            "update": map(filter(actions, ['action', 'update']), "key"),
            // ...and sensorTypes to delete
            "destroy": map(filter(actions, ['action', 'destroy']), "sensorType"),
          },
          i2cPort = selectCurrentDeviceI2CSettings(state),
          payload = { batch, i2cPort, i2cAddress }

        // send a batch update to the device
        return dispatch(DataActions.publishToWipperI2CComponentAction(deviceKey, "batch", payload))
      })
  },

  saveServoComponent = servoComponent => (dispatch, getState) => {
    const
      state = getState(),
      // clone so we don't mutate state, API response does that for us
      componentToSave = cloneDeep(servoComponent),
      creating = isEmpty(componentToSave.key)

    // gets flagged as a servo
    componentToSave.wipper_pin_info.isServo = true

    // New servos...
    if(creating) {
      // don't work until they're found
      componentToSave.wipper_pin_info.found = false
      // and need a unique key
      componentToSave.key = selectNextComponentKey(state)
    }

    // Default the component name to reflect the pin name
    if(isEmpty(componentToSave.name)) {
      const
        { pinName } = componentToSave.wipper_pin_info,
        boardDefinition = selectCurrentDeviceBoard(state),
        { digitalPins, analogPins } = boardDefinition.components,
        pins = digitalPins.concat(analogPins),
        pinDefinition = find(pins, { name: pinName })

      componentToSave.name = pinDefinition?.displayName
    }

    return creating
      ? dispatch(createServoComponent(componentToSave))
      : dispatch(updateServoComponent(componentToSave))
  },

  createServoComponent = wipperData => (dispatch, getState) => {
    const deviceKey = selectCurrentDevice(getState()).key

    return dispatch(createComponent(wipperData))
      .then(componentKey => {
        // attach servo now if device is connected
        if(selectCurrentDeviceOnline(getState())) {
          dispatch(DataActions.publishToWipperComponentAction(deviceKey, componentKey, "attachServo"))
        }

        return componentKey
      })
  },

  updateServoComponent = wipperData => (dispatch, getState) => {
    const
      deviceKey = selectCurrentDevice(getState())?.key,
      componentKey = wipperData.key,
      notConnected = !selectCurrentDeviceOnline(getState()),
      getApiPromise = () => dispatch(updateComponent(wipperData))

    return notConnected ?
      // no device connected, just hit the api and return
      getApiPromise() :
      // coordinate the device to remove and re-add the component around the update
      new Promise(resolve => {
        // detach from connected device
        resolve(dispatch(DataActions.publishToWipperComponentAction(deviceKey, componentKey, "detachServo")))
      })// wait a moment
        .then(() => new Promise(resolve => setTimeout(resolve, 400)))
        // call the Rails API
        .then(getApiPromise)
        // reattach to the connected device
        .then(() => dispatch(DataActions.publishToWipperComponentAction(deviceKey, componentKey, "attachServo")))
  },

  saveDS18X20Component = components => (dispatch, getState) => {
    const
      state = getState(),
      deviceKey = selectCurrentDevice(state).key,
      { pinName, sensorResolution } = (components[0] || {}),
      // if any component already exists, we need to deinit before we init
      useDeinit = some(components, "key")

    if(!pinName) { throw new Error("Pin is required.") }

    // save feeds
    // each subcomponent makes an API request
    return Promise.all(components.map(subcomponent => {
      // create/update/delete a feed
      const
        // translate from redux to rails/db
        component = {
          ...pick(subcomponent, ["name", "key"]),
          wipper_pin_info: {
            found: !!subcomponent.found,
            isDS18X20: true,
            ...pick(subcomponent, ["type", "sensorType", "pinName", "sensorResolution", "period"])
          }
        },
        exists = !isEmpty(subcomponent.key),
        { enabled, sensorType } = subcomponent,
        actionToTake = exists ?
          (enabled ?
            "update" :
            "destroy") :
          "create"

      // Doesn't exist and wasn't added, skip
      if(!(exists || enabled)) { return Promise.resolve() }
      if(!sensorType) { throw new Error("Sensor Type is required.") }

      // for new components, make a key from particular properties
      exists || (component.key = `ws-${pinName.toLowerCase()}-${sensorType}`)

      // need the key in the closure for reference in .then()
      const key = includes(component.key, '.') ? // if it is a compound key
        component.key : // use it, otherwise
        // build the compound key
        `${deviceKey}.${component.key}`

      return (actionToTake === "create"
        ? dispatch(createComponent(component))
        : actionToTake === "update"
          ? dispatch(updateComponent(component))
          : dispatch(destroyComponent(component.key))
      ).then(() => (actionToTake !== "destroy") && key) // just create and update keys
    }))
      .then(compact)
      .then(keys => {
        // global info and keys to init
        const payload = { useDeinit, pinName, sensorResolution, keys }
        // send the ds18x20 update
        return dispatch(DataActions.publishToWipperComponentAction(deviceKey, null, "updateDS18X20", payload))
      })
  },

  savePWMComponent = pwmComponent => (dispatch, getState) => {
    const
      state = getState(),
      // clone so we don't mutate state, API response does that for us
      componentToSave = cloneDeep(pwmComponent),
      creating = isEmpty(componentToSave.key)

    // flag as PWM
    componentToSave.wipper_pin_info.isPWM = true

    if(creating) {
      // don't work until they're found
      componentToSave.wipper_pin_info.found = false
      // generate key
      componentToSave.key = selectNextComponentKey(state)
    }

    return creating
      ? dispatch(createPWMComponent(componentToSave))
      : dispatch(updatePWMComponent(componentToSave))
  },

  createPWMComponent = wipperData => (dispatch, getState) => {
    const deviceKey = selectCurrentDevice(getState()).key

    return dispatch(createComponent(wipperData))
      .then(componentKey => {
        // attach now if the device is connected
        if(selectCurrentDeviceOnline(getState())) {
          dispatch(DataActions.publishToWipperComponentAction(deviceKey, componentKey, "attachPWM"))
        }

        return componentKey
      })
  },

  updatePWMComponent = wipperData => (dispatch, getState) => {
    const
      state = getState(),
      deviceKey = selectCurrentDevice(state)?.key,
      componentKey = wipperData.key,
      notConnected = !selectCurrentDeviceOnline(state),
      getApiPromise = () => dispatch(updateComponent(wipperData))

    return (notConnected) ?
      // no device connected, or purely cosmetic changes? just hit the api and return
      getApiPromise() :
      // coordinate the device to remove and re-add the component around the update
      new Promise(resolve => {
        // remove from connected device
        resolve(dispatch(DataActions.publishToWipperComponentAction(deviceKey, componentKey, "detachPWM")))
      })// wait a moment
        .then(() => new Promise(resolve => setTimeout(resolve, 400)))
        // call the Rails API
        .then(getApiPromise)
        // add back to the connected device
        .then(() => dispatch(DataActions.publishToWipperComponentAction(deviceKey, componentKey, "attachPWM")))
  },

  savePixelComponent = pixelComponent => (dispatch, getState) => {
    const
      state = getState(),
      // clone so we don't mutate state, API response does that for us
      componentToSave = cloneDeep(pixelComponent),
      creating = isEmpty(componentToSave.key)

    // flag as Pixel
    componentToSave.wipper_pin_info.isPixel = true

    if(creating) {
      // don't work until they're found
      componentToSave.wipper_pin_info.found = false
      // generate key
      componentToSave.key = selectNextComponentKey(state)
    }

    return creating
      ? dispatch(createPixelComponent(componentToSave))
      : dispatch(updatePixelComponent(componentToSave))
  },

  createPixelComponent = wipperData => (dispatch, getState) => {
    const deviceKey = selectCurrentDevice(getState()).key

    return dispatch(createComponent(wipperData))
      .then(componentKey => {
        // attach now if the device is connected
        if(selectCurrentDeviceOnline(getState())) {
          dispatch(DataActions.publishToWipperComponentAction(deviceKey, componentKey, "attachPixel"))
        }

        return componentKey
      })
  },

  updatePixelComponent = wipperData => (dispatch, getState) => {
    const
      state = getState(),
      deviceKey = selectCurrentDevice(state)?.key,
      componentKey = wipperData.key,
      notConnected = !selectCurrentDeviceOnline(state),
      getApiPromise = () => dispatch(updateComponent(wipperData))

    return (notConnected) ?
      // no device connected, or purely cosmetic changes? just hit the api and return
      getApiPromise() :
      // coordinate the device to remove and re-add the component around the update
      new Promise(resolve => {
        // remove from connected device
        resolve(dispatch(DataActions.publishToWipperComponentAction(deviceKey, componentKey, "detachPixel")))
      })// wait a moment
        .then(() => new Promise(resolve => setTimeout(resolve, 400)))
        // call the Rails API
        .then(getApiPromise)
        // add back to the connected device
        .then(() => dispatch(DataActions.publishToWipperComponentAction(deviceKey, componentKey, "attachPixel")))
  },

  saveUARTComponent = components => (dispatch, getState) => {
    const
      state = getState(),
      device = selectCurrentDevice(state),
      { key: deviceKey, machine_name: boardName } = device,
      componentType = selectCurrentComponentType(state).type,
      allNew = !some(components, "key"),
      noneLeft = !some(components, "enabled"),
      shouldDetach = noneLeft && !allNew,
      // compare last saved period with currently-saving period
      lastPeriod = getCurrentDeviceComponentsByProp(state, { isUART: true })[0]?.wipper_pin_info.period,
      periodChanged = !!lastPeriod && (components[0].period !== lastPeriod),
      shouldAttach = !shouldDetach && (allNew || periodChanged) && !noneLeft,
      alreadyFound = some(components, "found")

    // save feeds
    // each subcomponent makes an API request
    return Promise.all(components.map(subcomponent => {
      // create/update/delete a feed
      const
        // translate from redux to rails/db
        component = {
          ...pick(subcomponent, ["name", "key"]),
          wipper_pin_info: {
            found: alreadyFound, // incrementally added sensors are automatically found
            isUART: true,
            ...pick(subcomponent, ["type", "sensorType", "period", "baudRate", "inverted", "uartTx", "uartRx"])
          }
        },
        exists = !isEmpty(subcomponent.key),
        { enabled, sensorType } = subcomponent,
        actionToTake = exists ?
          (enabled ?
            "update" :
            "destroy") :
          "create"

      // Doesn't exist and wasn't added, skip
      if(!(exists || enabled)) { return Promise.resolve() }
      if(!sensorType) { throw new Error("Sensor Type is required.") }

      // for new components, use a singular uart key root with various sensor types
      exists || (component.key = `ws-uart-${sensorType}`)

      // need the key in the closure for reference in .then()
      const key = includes(component.key, '.') ? // if it is a compound key
        component.key : // use it, otherwise
        // build the compound key
        `${deviceKey}.${component.key}`

      return (actionToTake === "create"
        ? dispatch(createComponent(component))
        : actionToTake === "update"
          ? dispatch(updateComponent(component))
          : dispatch(destroyComponent(component.key))
      ).then(() => (actionToTake !== "destroy") && key) // just create and update keys
    }))
      .then(compact)
      .then(keys => {
        if(shouldAttach) {
          // just send 1 of the components, device uses all sensors and broker filters
          const componentKey = keys[0]
          return dispatch(DataActions.publishToWipperComponentAction(deviceKey, null, "attachUART", { boardName, componentKey }))

        } else if(shouldDetach) {
          return dispatch(DataActions.publishToWipperComponentAction(deviceKey, null, "detachUART", { componentType }))
        }
      })
  },

  // SELECTORS
  componentsState = state => state.components,

  // a single component being viewed in a form
  selectCurrentComponent = createSelector([componentsState], state => state.currentComponent),

  // a multi-component (i2c, ds18x20) being viewed in a form
  selectCurrentComponents = createSelector([componentsState], state => state.currentComponents),

  // the type of the component being viewed in a form (singular or multi)
  selectCurrentComponentType = createSelector(
    [selectCurrentComponent, selectCurrentComponents, selectAllComponentTypes],
    (component, components, types) => {
      const
        firstComponent = component?.wipper_pin_info || components?.[0],
        type = firstComponent?.type

      if(!type) { return null }

      const
        foundType = types[type],
        foundParentType = find(Object.values(types), ({ subcomponents }) => {
          return some(subcomponents, { type })
        })

      return foundType || foundParentType || null
    }
  ),

  selectCurrentComponentEditing = state => {
    const
      currentComponent = selectCurrentComponent(state),
      currentComponents = selectCurrentComponents(state)

    return (!!currentComponent?.key) || some(currentComponents || [], "key")
  },

  selectCurrentComponentTypeAvailability = state => {
    const componentType = selectCurrentComponentType(state)

    return componentType
      ? getComponentTypeAvailability(state, componentType)
      : false

  },

  selectNextComponentKey = createSelector([selectCurrentDeviceComponents], components => {
    const
      maxKey = chain(components)
        .map("key")                          // just keys
        .map(key => /ws-(\d{3,})/.exec(key)) // keys matching ws-###...
        .compact()                           // throw away non-matches
        .map(matches => matches[1])          // just the ### part of matches
        .map(match => parseInt(match, 10))   // convert from string to integer
        .compact()                           // discard NaNs from parseInt
        .max()                               // what's the highest number?
      .value() || 0,                         // return it, default to 0
      nextKey = (maxKey + 1).toString()      // add 1 and convert to string

    // pad it with zeroes as appropriate
    switch(nextKey.length) {
      case 1:
        return `ws-00${nextKey}`
      case 2:
        return `ws-0${nextKey}`
      default:
        return `ws-${nextKey}`
    }
  }),

  currentComponentHasCosmeticChangesOnly = createSelector(
    [selectCurrentComponent, selectCurrentDeviceComponents],
    (editedComponent, allComponents) => {
      const
        // find the matching component by key
        existingComponent = find(allComponents, { key: editedComponent.key }),
        // only compare wipper_pin_info, except for the "visualization" key
        existingNonCosmeticInfo = omit(existingComponent.wipper_pin_info, ["visualization"]),
        editedNonCosmeticInfo = omit(editedComponent.wipper_pin_info, ["visualization"])

      return isEqual(existingNonCosmeticInfo, editedNonCosmeticInfo)
    }
  ),

  currentComponentPinType = createSelector(
    [selectCurrentComponentType],
    ({ mode, isServo, isPWM, isDS18X20, isPixel })  => {
      if(mode === 'ANALOG') {
        return 'analog'
      } else if(mode === 'DIGITAL') {
        return 'digital'
      } else if(isServo) {
        return 'servo'
      } else if(isPWM) {
        return 'pwm'
      } else if(isDS18X20 || isPixel) {
        return 'digital'
      }

      return ''
    }
  ),

  currentOptionsForPinSelect = createSelector([currentComponentPinType, selectCurrentDevicePinStatus],
    (pinType, currentPinsWithStatus) =>  {
      const
        pins = currentPinsWithStatus[pinType] || [],
        options = map(pins, ({ name, displayName, used }) => ({
          id: name,
          text: displayName,
          disabled: used
        }))

      return options
    }
  ),

  // QUERIES
  deduplicateName = (state, name) => {
    // other names to check against
    const existingNames = map(selectCurrentDeviceComponents(state), "name")

    let
      attempt = 0,
      nextName = name

    // if the name already exists...
    while(includes(existingNames, nextName)) {
      // ...try "Name (1)", then "Name (2)", etc...
      attempt += 1
      nextName = `${name} (${attempt})`
    }

    // return the first unique name
    return nextName
  },

  autoSelectPin = (state, matchString, pinMode, extraMatch=null) => {
    if(!(matchString && pinMode)) { return }

    const
      // grab the current board's list of available pinNames
      availablePins = selectCurrentDeviceAvailablePins(state)[pinMode.toLowerCase()],
      // find the first one that contains the searchText string
      match = find(availablePins, ({ displayName }) => {
        const stringToSearch = displayName.toLowerCase()

        return stringToSearch.includes(matchString) &&
          // and the extra match string, if given
          (!extraMatch || stringToSearch.includes(extraMatch))
      })?.name // extract its name

    return match
  },

  getCurrentDeviceComponentsByProp = (state, propPredicate) => {
    const
      allComponents = selectCurrentDeviceComponents(state),
      componentsByProp = filter(allComponents, { wipper_pin_info: propPredicate })

    return componentsByProp
  },

  getAllSiblingsForComponent = (state, { wipper_pin_info }) => {
    // look up parent via type
    const
      components = selectCurrentDeviceComponents(state),
      parentTypes = selectMultiComponentTypes(state),
      { type: subType, isI2C, isDS18X20, isUART, period } = wipper_pin_info,
      parentType = subType.split(':')[0],
      parent = find(parentTypes, { type: parentType })

    if(!parent) { throw new Error(`Unable to lookup a parent for subcomponent type: ${subType}`) }

    // look up all sibling subcomponents via type and type-specific properties
    const
      typeSpecificProperties = isI2C
        ? pick(wipper_pin_info, [ "i2cAddress" ])
        : isDS18X20
        ? pick(wipper_pin_info, [ "pinName", "sensorResolution" ])
        : isUART
        ? pick(wipper_pin_info, [ "baudRate", "inverted", "uartRx", "uartTx" ])
        : {},
      typeSpecificFind = type => {
        const componentsMatchingType = filter(components, [ 'wipper_pin_info.type', type ])

        return isI2C
          ? find(componentsMatchingType, ['wipper_pin_info.i2cAddress', typeSpecificProperties.i2cAddress])
          : isDS18X20
          ? find(componentsMatchingType, ['wipper_pin_info.pinName', typeSpecificProperties.pinName])
          : isUART
          ? componentsMatchingType[0]
          : null
      },
      subcomponents = map(parent.subcomponents, subcomponentType => {
        // walk the subcomponent definitions and look for matching ones
        const
          existing = typeSpecificFind(subcomponentType.type),
          baseComponent = {
            enabled: false,
            type: subcomponentType.type,
            sensorType: subcomponentType.sensorType,
            name: `${parent.displayName}: ${subcomponentType.displayName}`,
            period: (isUART && period) || subcomponentType.defaultPeriod,
            ...typeSpecificProperties
          }

        return existing ?
          // overwrite the base with existing properties and enable it
          {
            ...baseComponent,
            ...pick(existing, ['name', 'key']),
            ...existing.wipper_pin_info,
            enabled: true
          } :
          // otherwise, return the base component
          baseComponent
      })

    if(subcomponents.length === 0) { throw new Error(`No subcomponents located for ${parent}`) }

    // construct final object and return
    return subcomponents
  }


  export default reducer
