import React from 'react'
import { connect } from 'react-redux'

import GuideActions from 'actions/guides'

class GuideLink extends React.PureComponent {
  render() {
    const { dispatch, name, page, children } = this.props

    if (name) {
      return <a onClick={() => dispatch(GuideActions.showGuide(name))}>{children}</a>
    } else if (!isNaN(page)) {
      return <a onClick={() => dispatch(GuideActions.showGuidePage(page))}>{children}</a>
    } else {
      return <a>{children}</a>
    }
  }
}

const ConnectedGuideLink = connect()(GuideLink)
export default ConnectedGuideLink
