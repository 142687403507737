// TODO: replace img src="" with src={}, download images and bring into io-ui
//
import React from 'react'

// import GuideLink from 'components/GuideViewer/GuideLink'
import arduinologo from 'images/landing/arduino-icon.png';
import arduino01 from 'images/guides/arduino-adafruit_io_library_menu.png'
import arduino02 from 'images/guides/arduino-adafruit_io_mqtt_install.png'
import arduino03 from 'images/guides/arduino-adafruit_io_lib_search.png'
import arduino04 from 'images/guides/arduino-get-aio-key.gif'
import arduino05 from 'images/guides/arduino-adafruit_io_select_example.png'
import arduino06 from 'images/guides/arduino-adafruit_io_wifi_creds.png'
import arduino07 from 'images/guides/arduino-adafruit_io_arduinohttpclient.png'

const title = "Getting Started with Adafruit IO Arduino"

const pages = [
  () => (
    <>
      <img src={arduinologo} className="guide-image" alt="Arduino logo" />

      <p>Adafruit IO makes it simple to get your Arduino project's data into the best data system in the world - <strong>Adafruit IO</strong>.</p>

      <p>Using the <a href="https://github.com/adafruit/Adafruit_IO_Arduino/">Adafruit IO Arduino</a> library you can send data from an Arduino sketch to Adafruit IO. You can also retrieve data from Adafruit IO feeds into an Arduino Sketch.</p>
    </>
  ),
  () => (
    <>
      <h2>Hardware Compatibility</h2>

      <p>
        This library officially supports the following Adafruit hardware:
      </p>

      <ul>
        <li><a href="https://www.adafruit.com/product/3046">Adafruit Feather Huzzah ESP8266</a></li>
        <li><a href="https://www.adafruit.com/product/3591">Adafruit Feather Huzzah ESP32</a></li>
        <li><a href="https://www.adafruit.com/product/3010">Adafruit Feather M0 WiFi</a></li>
        <li><a href="https://www.adafruit.com/product/3027">Adafruit 32u4 FONA</a></li>
        <li><a href="https://www.adafruit.com/product/3201">Adafruit Ethernet Featherwing</a></li>
      </ul>
    </>
  ),
  () => (
    <>
      <h2>Installing Adafruit IO Arduino</h2>

      <p>Before we start, you'll need the latest version of the <a href="https://arduino.cc">Arduino IDE</a> installed and the latest board support packages for your hardware.</p>

      <p>From the Arduino IDE, navigate to the <strong>Manage Libraries...</strong> option in the <strong>Sketch -&gt; Include Library</strong> menu.</p>

      <img className="guide-image" src={arduino01} alt="managelibraries" />

      <p>Adafruit IO Arduino requires three Arduino libraries:</p>

      <ul>
        <li><a href="https://github.com/adafruit/Adafruit_MQTT_Library">Adafruit MQTT Library</a></li>
        <li><a href="https://github.com/arduino-libraries/ArduinoHttpClient">ArduinoHTTPClient</a></li>
        <li><a href="https://github.com/adafruit/Adafruit_IO_Arduino">Adafruit IO Arduino</a></li>
      </ul>

      <p>Let's start by installing the Adafruit MQTT Library. Enter <strong>Adafruit MQTT</strong> into the search box, and click <strong>Install</strong> on the <strong>Adafruit MQTT</strong> library option to install version 0.16.1 or higher.</p>

      <img className="guide-image" src={arduino02} alt="installmqttlibrary" />

      <p>Enter <strong>ArduinoHttpClient</strong> into the search box, and click <strong>Install</strong> on the <strong>ArduinoHttpClient</strong> library option to install version 0.2.0 or higher.</p>

      <img className="guide-image" src={arduino07} alt="installarduinohttp" />

      <p>Enter <strong>Adafruit IO Arduino</strong> into the search box, and click <strong>Install</strong> on the <strong>Adafruit IO Arduino</strong> library option to install version 2.7.3 or later.</p>

      <img className="guide-image" src={arduino03} alt="installaioarduino" />
    </>
  ),
  () => (
    <>
      <h2>Your First Adafruit IO Arduino Sketch</h2>

      <h3>1) Grab the Adafruit IO Key</h3>

      <p>Before we begin using Adafruit IO Arduino, we&#39;ll need to grab two important things: our <strong>Adafruit IO Username</strong> and our unique <strong>Adafruit IO Key</strong>.</p>

      <p><a href="http://io.adafruit.com">Navigate to your profile</a> and <strong>click the <em>View Adafruit IO Key</em> button</strong> to retrieve them. Write them down in a safe place, we&#39;ll need them for later.</p>

      <img className="guide-image" src={arduino04} alt="aio-key" />

      <h3>2) Load the example sketch</h3>

      <p>From the Arduino IDE, navigate to the  adafruitio<em>00</em>publish*  sketch by opening the  <strong>File -&gt; Examples -&gt; Adafruit IO Arduino</strong>  menu and clicking on <strong>adafruitio<em>00</em>publish</strong>.</p>

      <img className="guide-image" src={arduino05} alt="select-publish-sketch" />

      <p>Click on the  <strong>config.h</strong>  tab, and replace the placeholders with your Adafruit IO credentials and network connection info.</p>

      <img className="guide-image" src={arduino06} alt="io-info" />

      <h3>3) Upload to the Arduino</h3>

      <p>Now that everything&#39;s set up, save your sketch and upload it to the Arduino.</p>

      <p><em>BUT</em> - where&#39;s our data? <a href="https://learn.adafruit.com/adafruit-io-basics-esp8266-arduino/example-sketches#viewing-data-on-adafruit-io-6-16">Follow this guide to view your data on the Adafruit IO Website</a>.</p>
    </>
  ),
  () => (
    <>
      <h2>More Examples</h2>

      <p>Need more examples for using Adafruit IO Arduino with a servo, reading data from a sensor, or lighting up NeoPixels?</p>

      <p>Head over to the <a href="https://learn.adafruit.com/series/adafruit-io-basics"><em>Adafruit IO Basics Series</em> on the Adafruit Learning System</a>.</p>
    </>
  ),
  () => (
    <>
      <h2>Learning Guides and Resources</h2>

      <p><a href="https://learn.adafruit.com/category/adafruit-io">The Adafruit Learning System has lots of Adafruit IO guides to inspire your next project!</a></p>
    </>
  )
]

export {
  title,
  pages
}
