import { find } from 'lodash'
import 'whatwg-fetch'
import { createActions } from 'redux-actions'

import Session from './session'
import { Paths } from 'utils/paths'
import { ioFetch } from 'utils/ajax'
import { nanoid } from 'utils/strings'
import log from 'utils/logger' // eslint-disable-line

const SystemMessages = createActions({},
  'SYSTEM_MESSAGES_UNFLASH'
)

// store a message for playback after navigation
//    dispatch(SystemMessageActions.flash('info', 'Successfully created feed.'))
//    dispatch(SystemMessageActions.flash('info', <span>Successfully created <Link to={Paths.feed({...})}>new feed.</Link></span>))
SystemMessages.flash = (level, message) => {
  log("flash()", level, message)
  return {
    type: 'SYSTEM_MESSAGES_FLASH',
    payload: {
      id: nanoid(4),
      level,
      component: message,
      dismissable: true,
      _local: true
    }
  }
}

// Primary method for loading system message data.
//
// - `params` is an object with username property
SystemMessages.load = () => (dispatch, getState) => {
  const
    client = Session.getClient(dispatch, getState),
    auth = client.jwt ? { 'Authorization': `Bearer ${client.jwt}` } : {}

  return dispatch({
    type: 'SYSTEM_MESSAGES_LOAD',
    payload: ioFetch(
      Paths.api_system_messages(),
      { headers: auth }
    )
  })
}

// Add a transient system message. Valid levels are: info, warn, alert
SystemMessages.add = (level, message) => ({
  type: 'SYSTEM_MESSAGES_ADD',
  payload: {
    id: nanoid(4),
    level: level,
    component: message,
    dismissable: true,
    _local: true
  }
})

// Add or Replace an existing message at the given ID if it's present
SystemMessages.replace = (id, level, message) => (dispatch, getState) => {
  const { messages } = getState().systemMessages

  if(find(messages, { id })) {
    // simply remove the existing one before adding
    dispatch({
      type: 'SYSTEM_MESSAGES_DISMISS_NOW',
      payload: { id }
    })
  }

  return dispatch({
    type: 'SYSTEM_MESSAGES_ADD',
    payload: {
      id,
      level: level,
      component: message,
      dismissable: true,
      _local: true
    }
  })
}

// Dismiss a system message
//
// - `params` is an object with id property
SystemMessages.dismiss = (params) => {
  if (params._local) {
    return {
      type: 'SYSTEM_MESSAGES_DISMISS_NOW',
      payload: params
    }
  }

  return (dispatch, getState) => {
    const
      client = Session.getClient(dispatch, getState),
      auth = client.jwt ? { 'Authorization': `Bearer ${client.jwt}` } : {}

    dispatch({
      type: 'SYSTEM_MESSAGES_DISMISS_NOW',
      payload: params
    })

    return dispatch({
      type: 'SYSTEM_MESSAGES_DISMISS',
      payload: ioFetch(
        Paths.api_system_messages_dismiss(params),
        { headers: auth, method: 'POST' }
      )
    })
  }
}

export default SystemMessages
