import { handleActions } from 'redux-actions'
import { buildAPIReducers } from 'utils/reducers';

const initialState = {
  triggers: [],
  trigger: null,
};

const triggers = buildAPIReducers('trigger', initialState);

const formInit = {
  form: {
    id: null, // set if this is an editing form
    feed_id: null,
    operator: null,
    value: null,
    action: null,
    to_feed_id: null,
    action_feed_id: null,
    action_value: null,
    trigger_type_id: null,
    trigger_type: null,
    status: "started",
    notify_limit: "15",
    notify_on_reset: null,
    timer_extend: null,
    timer_wait: "10",
    subject_template: null,
    body_template: null,
    form_encoded: null
  }
}

const triggerEditor = handleActions({
  INIT_TRIGGER_FORM(state, { payload }) {
    return Object.assign({}, state, {
      form: payload
    })
  },

  CLEAR_TRIGGER_FORM(state) {
    return Object.assign({}, state, {
      form: formInit.form
    })
  },

  UPDATE_TRIGGER_PROPERTY(state, { payload }) {
    const { name, value } = payload
    const form = Object.assign({}, state.form, {
      [ name ]: value
    })
    return Object.assign({}, state, { form })
  },
}, formInit)

export { triggers, triggerEditor }
