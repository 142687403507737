import log from 'utils/logger'
import { buildAPIReducers } from 'utils/reducers'

const initialState = {
  activities: [],
  activity: {}
}

export const activities = buildAPIReducers('activity', initialState, record => {
  // if record has data, try parsing it as JSON
  if (record.data) {
    try {
      return { ...record, data: JSON.parse(record.data) }
    } catch (ex) {
      log("reducers/activities failed to JSON parse data")
    }
  }

  return record
})
