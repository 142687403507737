import { includes } from 'lodash'

import BetaFlags from 'utils/beta_flags.json'
import { propCheck } from 'utils/properties'


export const hasBetaAccess = (user, flag) => {
  const
    value = BetaFlags[flag],
    flags = user?.beta_flags

  // flag setting is a boolean: return the user's flag if set, otherwise return the boolean
  if (typeof value === 'boolean') {
    return (propCheck(flags, flag)
      ? flags[flag]
      : value)

  // flag setting is a string: use the string as the user flag to look up
  // return a boolean, true if it's one of a few magic truthy values
  } else if (typeof value === 'string') {
    if (!flags) { return false }

    const flagValue = flags[value]
    // value is the name of a beta flag on User record
    return includes(['1', 1, 'true', true], flagValue)
  }
}
