import { handleActions } from 'redux-actions';

const initialValue = {
  current: {}
}

export default handleActions({
  NAVIGATION_LOAD_FULFILLED(state, { payload }) {
    return Object.assign({}, state, { current: payload })
  }
}, initialValue)

const NavigationSelectors = {
  getMenu(state) {
    return state.navigation.current
  }
}

export { NavigationSelectors }
