import React from 'react'
import { connect } from 'react-redux'

import FeedbackActions from 'actions/feedback'
import FeedbackTypes from './FeedbackTypes'
import FormButtons from './FormButtons'


class FeedbackThanks extends React.Component {
  close = () => {
    this.props.dispatch(FeedbackActions.toggleModal(false, this.props.identifier))
  }

  description() {
    switch (this.props.subject) {
      case FeedbackTypes.SERVICE:
        return (
          <div>
            <p>
              Thank you for your report! We read and investigate every issue
              and error report we receive, but please understand that we may
              not be able to respond to every report directly.
            </p>

            <p>
              Be sure to also check out <a href={import.meta.env.VITE_FORUMS_URL}>the forums</a> and
              the <a href={import.meta.env.VITE_BLOG_URL}>Adafruit IO blog</a> to
              keep up to date with news and updates from Adafruit IO.
            </p>
          </div>
        )
      case FeedbackTypes.WIPPERSNAPPER:
        return (
          <div>
            <p>
              Thank you for your report! We're paying close attention to these
              with WipperSnapper in its Beta period, but please understand that
              we may not be able to respond to every report directly.
            </p>

            <p>
              Be sure to also check out <a href={import.meta.env.VITE_WIPPER_FORUMS_URL}>the
              WipperSnapper-specific forums</a>, <a href={import.meta.env.VITE_FORUMS_URL}>the
              general IO forums</a>, and the <a href={import.meta.env.VITE_BLOG_URL}>Adafruit
              IO blog</a> to keep up to date with news and updates from Adafruit
              IO.
            </p>
          </div>
        )
      case FeedbackTypes.WIPPER_SUGGESTION:
        return (
          <p>
            Thank you for interest in the WipperSnapper Beta, and for sharing your idea with us!
          </p>
        )
      case FeedbackTypes.PLUS:
        return (
          <div>
            <p>
              Thank you for your interest in Adafruit IO+. We will do our best
              to address your issue or question in 2-3 business days.
            </p>
          </div>
        )
      case FeedbackTypes.GENERAL:
        return (
          <div>
            <p>
              We appreciate your feedback. We will do our best to address your
              issue but we may not be able to respond personally to every
              question or request.
            </p>

            <p>
              Make sure to check out <a href={import.meta.env.VITE_FORUMS_URL}>the forums</a> and
              the <a href={import.meta.env.VITE_BLOG_URL}>Adafruit IO blog</a> to
              keep up to date with news from Adafruit IO.
            </p>
          </div>
        )
      case FeedbackTypes.SUGGESTION:
        return (
          <p>
            Thank you for interest in Adafruit IO and for sharing your idea with us!
          </p>
        )
      case FeedbackTypes.OTHER:
      default:
        return (
          <p>
            We appreciate your feedback. We will do our best to address your
            issue but we may not be able to respond personally to every
            question or request.
          </p>
        )
        // should not end up here
    }
  }

  render() {
    return (
      <div>
        { this.description() }

        <p>
          <strong>Thanks for choosing Adafruit IO!</strong>
        </p>

        <FormButtons>
          <button className='btn blue' onClick={this.close}>Close</button>
        </FormButtons>
      </div>
    )
  }
}

const mapState = ({ feedback }) => ({
  step: feedback.step,
  subject: feedback.subject
})

const ConnectedFeedbackThanks = connect(mapState)(FeedbackThanks)
export default ConnectedFeedbackThanks
