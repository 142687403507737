// Higher Order Component managing containers that act as first-rendered
// elements in routing. In practice, this means everything that renders into
// `main` via the react-router components prop on routes.

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import NotFound from 'views/NotFound'
import ErrorBoundary from './ErrorBoundary'
import Breadcrumbs from 'components/Elements/Breadcrumbs'
import BreadcrumbActions from 'actions/breadcrumbs'
import BrowsingActions from 'actions/browsing'
import SystemMessageActions from 'actions/system_messages'

const TopLevelComponent = function(WrappedContainer) {
  class TopLevelContainer extends React.Component {
    componentDidMount() {
      this.props.reset()
      this.props.unflash()
    }

    componentWillUnmount() {
      this.props.resetBreadcrumbs()
    }

    render() {
      if(this.props.error === 'not_found') {
        return <NotFound />
      }

      return <ErrorBoundary errorPrefix="TopLevel">
        <Breadcrumbs { ...this.props } />
        <WrappedContainer { ...this.props } />
      </ErrorBoundary>
    }
  }

  const mapState = (state, ownProps) => ({
    currentUser: state.session.user,
    currentUsername: state.session.user?.username,
    urlUsername: ownProps.params.username,
    userIsOwner: state.session.user &&
      (state.session.user.username === ownProps.params.username),
    error: state.browsing.error
  })

  const mapDispatch = dispatch => ({
    dispatch,
    notfound: () => dispatch(BrowsingActions.browsingNotFound()),
    reset: () => dispatch(BrowsingActions.browsingReset()),
    flash: (lvl, msg) => dispatch(SystemMessageActions.flash(lvl, msg)),
    unflash: () => dispatch(SystemMessageActions.systemMessagesUnflash()),
    resetBreadcrumbs: () => dispatch(BreadcrumbActions.clearBreadcrumbs()),
    setBreadcrumbs: (bcParams) => dispatch(BreadcrumbActions.setBreadcrumbs(bcParams))
  })

  return connect(mapState, mapDispatch)(withRouter(TopLevelContainer))
}

export default TopLevelComponent
