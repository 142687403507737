// import only the bits of semver we need
import semverValid from 'semver/functions/valid'
import semverGte from 'semver/functions/gte'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { logException } from 'utils/errors'
import { Paths } from 'utils/paths'
import { currentSemver } from 'selectors/wprsnpr'
import { usernameSelector } from 'selectors/users'
import SmartLink from 'components/Elements/SmartLink'

import "stylesheets/components/semver_indicator.scss"

class SemverIndicator extends React.PureComponent {
  static propTypes = {
    semver: PropTypes.string.isRequired,
    nolink: PropTypes.bool,
    deviceKey: PropTypes.string,
    onUpdateClicked: PropTypes.func,
  }

  upToDate() {
    const { semver, currentSemver } = this.props

    try {
      // these methods throw if given invalid semver
      return semverValid(semver) && semverGte(semver, currentSemver)
    } catch(error) {
      // tell us something happened
      logException(error)
      // but don't crash the front-end
      return false
    }
  }

  renderSemver() {
    const { semver } = this.props
    return semver ? `v${ semver }` : "Firmware Unknown"
  }

  renderFirmwareUpdateLink(content="") {
    const
      { username, deviceKey, onUpdateClicked } = this.props,
      updateAction = onUpdateClicked || Paths.update_device({ username, key: deviceKey })

    return <SmartLink to={ updateAction } className="update-link">
      <i className="fas fa-rotate"></i> { content }
    </SmartLink>
  }

  renderUpToDate = () =>
    <span className="semver-indicator up-to-date">
      <i className="fas fa-circle-check"></i> { this.renderSemver() }
      { this.renderFirmwareUpdateLink() }
    </span>

  renderNeedsUpdate = () =>
    <span className="semver-indicator needs-update">
      <i className="fas fa-circle-exclamation" /> { this.renderSemver() }

      { this.renderFirmwareUpdateLink("Update") }
    </span>

  render = () => this.upToDate()
    ? this.renderUpToDate()
    : this.renderNeedsUpdate()
}

const mapState = state => ({
  username: usernameSelector(state),
  currentSemver: currentSemver(state)
})

const ConnectedSemverIndicator = connect(mapState)(SemverIndicator)
export default ConnectedSemverIndicator
