import React from 'react'
import GuideLink from 'components/GuideViewer/GuideLink'

const title = "Adafruit IO Help"

const pages = [
  () => (
    <>
      <h3>Welcome to Adafruit IO!</h3>

      <p>
        <strong>Quick Help</strong> guides are the best way to get started
        using Adafruit IO on the web.
      </p>

      <p><strong>Quick Help</strong>:</p>
      <ul>
        <li><GuideLink name="python">Getting Started With Python</GuideLink></li>
        <li><GuideLink name="arduino">Getting Started With Arduino</GuideLink></li>
        <li><GuideLink name="feeds">Quick Guide to Feeds</GuideLink></li>
        <li><GuideLink name="triggers">Quick Guide to Actions</GuideLink></li>
        {/*
        <li><GuideLink name="dashboards">Quick Guide to Dashboards</GuideLink></li>
        */}
      </ul>

      <p>Other places to <strong>ask questions and get help directly</strong>:</p>
      <ul>
        <li><a href="https://forums.adafruit.com/viewforum.php?f=56">The Adafruit IO Forum</a></li>
        <li><a href="https://discord.gg/adafruit">The Adafruit Discord Chat Server</a></li>
      </ul>

      <p>
        <strong>Get inspired</strong> or <strong>find your next project</strong> with examples and walkthroughs
        on <a href="https://learn.adafruit.com/category/internet-of-things-iot">The Adafruit Learning System</a>.
      </p>

      <ul>
        <li><a href="https://learn.adafruit.com/welcome-to-adafruit-io">Welcome to Adafruit IO</a></li>
        <li><a href="https://learn.adafruit.com/adafruit-io-house-lights-and-temperature">Adafruit IO Home: Lights and Temperature</a></li>
        <li><a href="https://learn.adafruit.com/gmailbox">The Gmailbox</a></li>
        <li><a href="https://learn.adafruit.com/adafruit-io-air-quality-monitor">Adafruit IO Environmental Monitor</a></li>
      </ul>
    </>
  )
]

export {
  title,
  pages
}
