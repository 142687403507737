import { handleActions } from 'redux-actions'

const initialState = {
  current: {},   // active plan
  upgrade: null, // plan to upgrade to
  checkout: {}   // Stripe payment form
}

const plans = handleActions({
  UPGRADE_SUBSCRIPTION(state, { payload }) {
    return Object.assign({}, state, {upgrade: payload})
  },

  GET_SUBSCRIPTION_FULFILLED(state, { payload }) {
    return Object.assign({}, state, {current: payload})
  },

  CREATE_SUBSCRIPTION_FULFILLED(state, { payload }) {
    // console.log('[reducers/plans] subscription creation payload', payload)
    return Object.assign({}, state, {current: payload})
  },

  UPDATE_SUBSCRIPTION_FULFILLED(state, { payload }) {
    // console.log('[reducers/plans] subscription creation payload', payload)
    return Object.assign({}, state, {current: payload})
  },

  CREATE_SUBSCRIPTION_FAILED(state, { payload }) {
    // console.log("CREATION FAILED. payload:", payload)

    if (typeof payload === 'undefined') {
      return Object.assign({}, state, {checkout: {error: 'There was a server error, your purchase could not be processed.'}})
    } else {
      return Object.assign({}, state, {checkout: payload})
    }
  },

  // get plan value from user record
  SET_SESSION_USER(state, { payload }) {
    const user = payload
    return Object.assign({}, state, {current: user.subscription})
  },
}, initialState)

export default plans
