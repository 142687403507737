import React from 'react'

import IODataProvider from './IODataProvider'
import IOLayout from './IOLayout'
import IORouter from './IORouter'

import 'stylesheets/application.scss'


const IOApplication = () =>
  <IODataProvider>
    <IOLayout>
      <IORouter />
    </IOLayout>
  </IODataProvider>

export default IOApplication
