import Session from './session'
import { Paths } from 'utils/paths'

const Feedback = {
  create: form => (dispatch, getState) => {
    const
      client = Session.getAuthenticatedClient(dispatch, getState),
      body = JSON.stringify({
        feedback: form
      })

    return dispatch({
      type: 'CREATE_FEEDBACK',
      payload: Session.ajax(client, Paths.api_feedbacks(), { method: 'POST', body })
    })
  },

  load: () => (dispatch, getState) => {
    const client = Session.getAuthenticatedClient(dispatch, getState)

    return dispatch({
      type: 'LOAD_FEEDBACK',
      payload: Session.ajax(client, Paths.api_feedbacks(), { method: 'GET' })
    })
  },

  // requires identifier to specify which FeedbackModal to show
  toggleModal: (isVisible, identifier) => {
    return (dispatch) => {
      isVisible && dispatch(Feedback.toStep('pick_type'))

      return dispatch({
        type: 'FEEDBACK_TOGGLE_MODAL',
        payload: {
          identifier,
          show: isVisible
        }
      })
    }
  },

  // pick_type: initial selection
  // forum: forum link with explanation
  // feedback: feedback form
  // thanks: thank you screen
  toStep: (step, subject=null) => ({
    type: 'FEEDBACK_LOAD_STEP',
    payload: {
      step, subject
    }
  })
}

export default Feedback
