import React from 'react'

const title = "Introduction to Dashboards"

const pages = [
  () => (
    <>
      <h3>Dasboards are cool</h3>

      <p>
        Haxx0r ipsum bang buffer rm -rf snarf var echo default ascii do data script
        kiddies tunnel in d00dz char mutex highjack suitably small values. Finally
        hexadecimal gurfle printf then infinite loop machine code int unix giga eaten
        by a grue worm irc ack shell emacs afk injection. Deadlock double error chown
        hello world server throw exception leet Dennis Ritchie root recursively over
        clock.
      </p>
    </>
  ),

  () => (
    <>
      <h3>Dashboards are awesome</h3>

      <p>
        Mutex cat ascii bypass bit headers frack bin var gcc system perl tcp tera
        data brute force interpreter. Machine code back door do long python *.* fatal
        gc hexadecimal vi kilo infinite loop. Malloc Trojan horse piggyback syn
        strlen Leslie Lamport.
      </p>
    </>
  ),

  () => (
    <>
      <h3>Dashboards are neat all the time</h3>

      <p>
        James T. Kirk hack the mainframe cd public leapfrog emacs deadlock server
        system. Stack float gnu break pwned infinite loop sudo packet sniffer access.
        Recursively mega double crack epoch bypass ascii stdio.h function less else
        January 1, 1970.
      </p>

      <p>Check out the Adafruit Learning System:</p>
      <ul>
        <li><a href="https://learn.adafruit.com/welcome-to-adafruit-io">Welcome to Adafruit IO</a></li>
        <li><a href="https://learn.adafruit.com/adafruit-io-basics-dashboards">Adafruit IO Basics: Dashboards</a></li>
      </ul>
    </>
  )
]

export {
  title,
  pages
}
