import { at, chain, every, filter, flatMap, map, property, some, trim, values } from 'lodash'
import { createSlice, createSelector } from '@reduxjs/toolkit'


const
  SINGLE_COMPONENT_TYPES = [ 'isPin', 'isPWM', 'isServo', 'isPixel'],
  MULTI_COMPONENT_TYPES = [ 'isI2C', 'isDS18X20', 'isUART' ]

const { actions, reducer } = createSlice({
  name: 'componentTypes',

  initialState: {
    componentTypes: {}
  },

  reducers: {
    setComponentTypes: (state, { payload: componentTypes }) => ({ ...state, componentTypes }),
  },
})

export const
  // ACTIONS
  { setComponentTypes } = actions,

  // SELECTORS
  selectState = property('componentTypes'),

  selectAllComponentTypes = createSelector([selectState], property('componentTypes')),

  selectComponentTypesSearchIndex = createSelector([selectAllComponentTypes], allTypes => {
    return map(allTypes, type => {
      const index = []

      index.push(at(type, ['type', 'vendor', 'description', 'displayName', 'direction', 'mode']))

      type.i2cAddresses && index.push(...type.i2cAddresses)

      index.push(...flatMap(type.subcomponents, sub =>
        [sub.displayName, sub.sensorType]
      ))

      index.push(
        type.isI2C ? "i2c"
        : type.isPin ? "pin"
        : type.isUART ? "uart"
        : type.isPixel ? "pixel"
        : type.isServo ? "servo"
        : type.isDS18X20 ? "ds18x20"
        : type.isPWM && "pwm"
      )

      type.published || index.push('dev')

      // TODO:
      // - categories (don't exist yet)
      // - units

      const indexString =
        // build this index!
        chain(index)
          .compact() // toss blanks
          .join(' ') // one big string
          .toLower() // lowercase it
          // .words()
          .split(/[\s-()+]+/) // break it into tokens without whitespace and symbols
          .uniq() // deduplicate
          .filter(term => term.length > 2) // at least 3 letters
          .join(' ') // one big string for easy matching
        .value()

      return [ type, indexString ]
    })
  }),

  selectMultiComponentTypes = createSelector([selectAllComponentTypes], allTypes =>
    filter(Object.values(allTypes), type => some(MULTI_COMPONENT_TYPES, prop => type[prop]))
  ),

  selectSingleComponentTypes = createSelector([selectAllComponentTypes], allTypes =>
    filter(Object.values(allTypes), type => some(SINGLE_COMPONENT_TYPES, prop => type[prop]))
  ),

  // QUERIES
  getComponentTypesBySearch = (state, searchString) => {
    // be fast when nothing to search
    if(!trim(searchString)?.length) {
      return values(selectAllComponentTypes(state))
    }

    const
      searchTerms = searchString.toLowerCase().split(/[\s-()]+/),
      searchIndex = selectComponentTypesSearchIndex(state),
      // filter to types that match every search term in their index
      indexMatches = filter(searchIndex, ([ , index ]) =>
        every(searchTerms, forTerm => index.includes(forTerm))
      )

    return map(indexMatches, '[0]')
  }

export default reducer
