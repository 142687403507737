import { compact, isEmpty, filter, flatten, groupBy, keyBy, map, mapValues, omit, pick, property, reduce, reject, some, values } from 'lodash'
import { createSlice, createSelector } from '@reduxjs/toolkit'

import downloadFile from 'utils/download_file'
import { selectAllComponentTypes } from 'slices/component_types'
import {
  selectCurrentDevice, selectCurrentDeviceBoard, selectCurrentDeviceSemver,
  selectCurrentDeviceComponents, selectCurrentDeviceBoardMagic } from 'slices/devices'
import {
  deduplicateName, setCurrentComponent,
  setCurrentComponents, saveCurrentComponent } from 'slices/components'
import { usernameSelector } from 'selectors/users'
import GroupActions from 'actions/groups'
import { ProfileSelectors } from 'reducers/profiles'


const MAGIC_CONFIG_EXPORT_FORMAT_VERSION = "1.0.0"

const { actions, reducer } = createSlice({
  name: 'device_autoconfig',

  initialState: {
    config: null,
    components: null,
    status: 'configuring', // 'working' 'done' 'error'
    componentStatus: {},
    componentErrors: {},
  },

  reducers: {
    setConfig: (state, { payload: config }) => ({ ...state, config }),

    setStatus: (state, { payload: status }) => ({ ...state, status }),

    setComponentStatuses: (state, { payload: componentUpdates }) => {
      return {
        ...state,
        componentStatus: {
          ...state.componentStatus,
          ...componentUpdates
        }
      }
    },

    setComponentErrors: (state, { payload: componentUpdates }) => {
      return {
        ...state,
        componentErrors: {
          ...state.componentErrors,
          ...componentUpdates
        }
      }
    },

    setConfigWithObject: (state, { payload: config }) => ({
      ...state,
      config: omit(config, ['components']),
      components: map(config.components, component => ({
        ...component,
        importing: !(component.selected === false),
        selected: undefined
      }))
    }),

    setComponentImport: (state, { payload: { key, importing } }) => {
      state.components[key].importing = importing
      return state
    },

    clearConfig: state => ({
      ...state,
      config: null,
      components: null,
      status: 'configuring',
      componentStatus: {},
      componentErrors: {},
    }),
  },
})

export const
  { setConfig, setConfigWithObject, setComponentImport, clearConfig } = actions,

  setConfigFromJSON = jsonString => (dispatch) => {
    const config = JSON.parse(jsonString)
    dispatch(setConfigWithObject(config))
  },

  setConfigWithMagic = () => (dispatch, getState) => {
    // get current device's magic config
    const magic = selectCurrentDeviceBoardMagic(getState())
    dispatch(setConfigWithObject(magic))
  },

  downloadCurrentDeviceJson = () => (dispatch, getState) => {
    const
      state = getState(),
      { key } = selectCurrentDevice(state),
      deviceJSON = selectCurrentDeviceExportJSON(state)

    downloadFile(deviceJSON, "application/json", `${key}.json`)
  },

  importConfigToCurrentDevice = () => async (dispatch, getState) => {
    let finalStatus = 'done'

    const
      state = getState(),
      importingComponents = selectComponentsToImport(state),

      setComponentStatus = async (components, status) => {
        await dispatch(actions.setComponentStatuses(
          mapValues( keyBy(components, 'name'), () => status )
        ))
      },

      setComponentErrors = async (components, error) => {
        await dispatch(actions.setComponentErrors(
          mapValues( keyBy(components, 'name'), () => error )
        ))
      },

      saveComponentsWithErrorHandling = async components => {
        try {
          await setComponentStatus(components, 'saving')
          await dispatch(saveCurrentComponent())
          await setComponentStatus(components, 'done')

        } catch(error) {
          finalStatus = 'error'
          await setComponentStatus(components, 'error')
          await setComponentErrors(components, error.obj?.error || [error.message])
        }
      },

      toSaveableComponent = component => ({
        name: deduplicateName(state, component.name),
        wipper_pin_info: {
          ...omit(component, ['name', 'importing'])
        }
      }),

      toSaveableComponents = components => map(components, component => ({
        enabled: true,
        name: deduplicateName(state, component.name),
        ...pick(component, ['type', 'sensorType', 'i2cAddress', 'pinName', 'period', 'sensorResolution', 'uartTx', 'uartRx', 'baudRate', 'inverted']),
      }))

    await setComponentStatus(importingComponents, 'waiting')
    await dispatch(actions.setStatus('working'))

    // Multi-component types
    const multiComponentGroupings = [
      // [ prop to filter on, prop to group by ]
      ['isI2C', 'i2cAddress'],
      ['isDS18X20', 'pinName'],
      ['isUART', 'uartTx']
    ]

    for (const [filterProp, groupProp] of multiComponentGroupings) {
      const
        filteredComponents = filter(importingComponents, filterProp),
        groupedComponents = groupBy(filteredComponents, groupProp)

      for(const componentGroup of Object.values(groupedComponents)) {
        const saveableComponents = toSaveableComponents(componentGroup)
        await dispatch(setCurrentComponents(saveableComponents))
        await saveComponentsWithErrorHandling(componentGroup)
      }
    }

    // Singular component types
    const singleComponents = reject(importingComponents, ({ isI2C, isDS18X20, isUART }) => isI2C || isDS18X20 || isUART)

    for(const component of singleComponents) {
      const saveableComponent = toSaveableComponent(component)
      dispatch(setCurrentComponent(saveableComponent))
      await saveComponentsWithErrorHandling([component])
    }

    dispatch(GroupActions.all())

    await dispatch(actions.setStatus(finalStatus))
  },

  importState = state => state.device_autoconfig,

  selectStatus = createSelector([importState], property("status")),

  deviceConfig = state => {
    const
      { config, components } = importState(state),
      board = selectCurrentDeviceBoard(state)

    return config && {
      version: config.exportVersion,
      exportedFromDevice: {
        name: config.exportedFromDevice.name,
        exportBoard: config.exportedFromDevice.board,
        targetBoard: board.boardName
      },
      components
    }
  },

  selectComponentsToImport = createSelector(
    [deviceConfig], config => filter(config?.components, "importing")
  ),

  configValidations = createSelector(
    [deviceConfig, selectCurrentDeviceComponents, selectAllComponentTypes, selectComponentsToImport, ProfileSelectors.getUserFeedsAvailable],
    (config, existingComponents, componentTypes, importingComponents, feedsAvailable) => {
      const
        warnings = [],
        errors = {
          global: [],
          components: {}
        }

      // early out failure if no config file yet
      if(!config) {
        errors.global.push({ title: "No Device Config File loaded." })
        return { warnings, errors }
      }

      const
        { exportBoard, targetBoard } = config.exportedFromDevice,
        feedsNeeded = importingComponents.length,
        collisionProperties = [ 'pinName', 'dataPinName', 'clockPinName', 'uartTx', 'uartRx', 'i2cAddress'],
        existingProperties = reduce(collisionProperties, (acc, propName) => {
          return acc.concat(compact(map(existingComponents, `wipper_pin_info.${propName}`)))
        }, [])

      // validate boards match
      if(targetBoard !== exportBoard) {
        warnings.push(`Import board (${exportBoard}) doesn't match target board (${targetBoard})`)
      }

      // validate at least one component is selected for import
      if(!importingComponents.length) {
        errors.global.push({
          title: "No Components selected",
          message: "Select at least one component to import, above."
        })
      }

      // validate enough feeds available to do entire import
      if(feedsNeeded > feedsAvailable) {
        errors.global.push({
          title: 'Not enough Feeds available',
          message: `Feeds required: ${feedsNeeded}, Feeds available: ${feedsAvailable}. You could import fewer components, delete or disable some feeds, or get unlimited feeds by signing up for IO Plus.`
        })
      }

      // TODO: use shared validations with component wizard
      // validate per component:
      importingComponents.forEach(component => {
        // component types are recognized
        const
          validType = !!componentTypes[component.type],
          componentErrors = []

        // simple type check
        if(!validType) {
          const
            [ type, subType ] = component.type.split(':'),
            subcomponentTypes = componentTypes[type]?.['subcomponents'],
            validSubType = some(subcomponentTypes, { sensorType: subType })

          // compound type check
          if(!validSubType) {
            componentErrors.push(`Unrecognized Component type: "${component.type}"`)
          }
        }

        // no address/pin collisions
        collisionProperties.forEach(propName => {
          if(existingProperties.includes(component[propName])) {
            // TODO: not a collision if used by a potential sibling sensor
            componentErrors.push(`${propName} (${component[propName]}) is taken`)
          }
        })

        // TODO: collisions between components within the file

        errors.components[component.name] = componentErrors
      })



      return { warnings, errors }
    }
  ),

  selectImportIsValid = createSelector(
    [configValidations], ({ errors }) => (
      isEmpty(errors.global) && isEmpty(compact(flatten(values(errors.components))))
    )
  ),

  selectCurrentDeviceHasMagic = createSelector(selectCurrentDeviceBoardMagic, Boolean),

  selectCurrentDeviceExportObject = createSelector(
    [usernameSelector, selectCurrentDeviceBoard, selectCurrentDeviceComponents, selectCurrentDeviceSemver],
    (username, board, components, semver) => ({
      exportVersion: MAGIC_CONFIG_EXPORT_FORMAT_VERSION,
      exportedBy: username,
      exportedAt: new Date(),

      exportedFromDevice: {
        board: board.boardName,
        firmwareVersion: semver,
      },

      components: map(components, ({ name, wipper_pin_info }) => ({
        name,
        ...omit(wipper_pin_info, ['found'])
      }))
    })
  ),

  selectCurrentDeviceExportJSON = createSelector(
    selectCurrentDeviceExportObject, exportObject => JSON.stringify(exportObject, null, 2)
  ),

  getComponentStatus = (state, componentName) => {
    const
      configState = importState(state),
      status = configState.componentStatus[componentName]

    return status
  },

  getComponentError = (state, componentName) => {
    const
      configState = importState(state),
      error = configState.componentErrors[componentName]

    return error
  }

export default reducer
