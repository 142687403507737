import { keys, partial, pick } from 'lodash'
import React from 'react'
import { Route, IndexRoute, Redirect, IndexRedirect } from 'react-router'


// metaprogramming magic to properly extend React Router v3 classes
// - props: props that will be passed directly to the extended component
// - onEnterProps:
//   - keys are possible prop names that can be passed bare to a route component
//   - values are either:
//     - the onEnter function that will be used if a prop with the given key is present
//     - a function that takes a single parameter and generates the onEnter function to be used
//       - the value of the prop will be passed as the single parameter
// - getComponentsProps:
//   - keys are possible prop names that can be passed to a route component
//   - values are the functions that the given prop key's value will be passed into and should return a getComponents-compatible function
const extendRouteClass = (extendedClass, { props={}, onEnterProps={}, getComponentsProps={} }={}) => {
  return class extends extendedClass {
    static createRouteFromReactElement = (routeElement, parentElement) => {
      const
        // getComponents-based props
        // extract matching prop keys
        presentGetComponentsProps = keys(pick(routeElement.props, keys(getComponentsProps))),
        getComponentsPropKey = presentGetComponentsProps[0],
        getComponents = getComponentsProps[getComponentsPropKey]?.(routeElement.props[getComponentsPropKey])

      // enforce only one getComponents prop
      if(presentGetComponentsProps.length > 1) {
        throw new Error(`Up to 1 getComponents prop can be passed at a time, got ${presentGetComponentsProps}`)
      }

      const
        // onEnter-based prop flags
        // extract all props that match onEnter keys
        presentOnEnterProps = keys(pick(routeElement.props, keys(onEnterProps))),
        onEnterPropKey = presentOnEnterProps[0],
        // pull the first found onEnter (or undefined)
        onEnterFunc = onEnterProps[onEnterPropKey],
        // if a function is present and takes a single parameter
        onEnter = onEnterFunc?.length === 1
          // call it with the value of the prop
          ? onEnterFunc(routeElement.props[onEnterPropKey])
          // else pass whatever is there as the onEnter directly (undefined is fine)
          : onEnterFunc

      // enforce only one onEnter prop
      if(presentOnEnterProps.length > 1) {
        throw new Error(`Up to 1 onEnter prop can be passed at a time, got ${presentOnEnterProps}`)
      }

      return extendedClass.createRouteFromReactElement(
        <extendedElement getComponents={ getComponents } onEnter={ onEnter } { ...routeElement.props } { ...props } />,
        parentElement
      )
    }
  }
}

// shortcuts to extend various react-router components without importing them elsewhere
export const
  extendRoute = partial(extendRouteClass, Route),
  extendIndexRoute = partial(extendRouteClass, IndexRoute),
  extendRedirect = partial(extendRouteClass, Redirect),
  extendIndexRedirect = partial(extendRouteClass, IndexRedirect)
