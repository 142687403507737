import React, { useEffect } from 'react'
import hotkeys from 'hotkeys-js'

import useKiosk from 'effects/useKiosk'


const KioskLayout = ({ children }) => {
  const { hideKiosk } = useKiosk()

  // we're only rendered if kiosk mode is active, we attach hotkeys now
  useEffect(() => {
    hotkeys('esc', hideKiosk)

    // and remove hotkeys when we exit
    return () => hotkeys.unbind('esc', hideKiosk)
  }, [])

  return (
    <div className="kiosk-wrapper">
      { children }
    </div>
  )
}

export default KioskLayout
