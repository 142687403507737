import React from 'react';
import Modal from 'react-bootstrap/lib/Modal'

import { Spinner } from 'components/Elements/Loading'


export default class SimpleModal extends React.PureComponent {
  render() {
    const
      subProps = {
        // defaults
        backdrop: true,
        working: false,
        title: 'Simple Modal',
        // overwritten by whatever parent gives
        ...this.props
      },
      title = subProps.title,
      // allows parent to inject a ref for the modal body
      bodyRef = subProps.bodyRef

    // title is special for SimpleModal, don't want it to set HTML title text
    delete subProps.title
    delete subProps.bodyRef
    delete subProps.working

    return (
      <Modal {...subProps}>
        { this.props.working &&
          <div className='modal-spinner'>
            <Spinner blue />
          </div>
        }

        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body ref={bodyRef}>
          { this.props.children }
        </Modal.Body>

        { this.props.footer &&
          <Modal.Footer>
            { this.props.footer }
          </Modal.Footer>
        }
      </Modal>
    )
  }
}
