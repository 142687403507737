// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
// takes #000000 and returns rgb(0, 0, 0)
export function hexToRGB(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
        return r + r + g + g + b + b;
    });
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    } : null;
}

// https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
// given a value in {r: Number, g: Number, b: Number} form, darken and return as css RGB string
export function shadeRGBColor(color, percent) {
  const { r, g, b } = color
  const t = percent < 0 ? 0 : 255,
        p = percent < 0 ? percent * -1 : percent;

  return `rgb(${Math.round((t-r)*p)+r}, ${Math.round((t-g)*p)+g}, ${Math.round((t-b)*p)+b})`
}

export function colorIsLight(color) {
  const { r, g, b } = color
  // Counting the perceptive luminance
  // human eye favors green color...
  var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return (a < 0.5);
}

function linmap(x, inx, iny, ox, oy) {
  return ((x - inx) * (oy - ox) + ox * (iny - inx)) / (iny - inx);
}

function hueToRGB(p, q, _t) {
  let t = _t

  if (t < 0) t += 1
  if (t > 1) t -= 1

  if (t < 0.16666)      return p + (q - p) * 6.0 * t
  else if (t < 0.5)     return q
  else if (t < 0.66666) return p + (q - p) * (0.66666 - t) * 6.0
  else                  return p
}

export function HSLtoRGB(_h, _s, _l, base=255) {
  const h = linmap(_h, 0, base, 0.0, 1.0),
        s = linmap(_s, 0, base, 0.0, 1.0),
        l = linmap(_l, 0, base, 0.0, 1.0)

  const q = l < 0.5 ? l * (1 + s) : (l + s) - (l * s)
  const p = (2 * l) - q

  const r = hueToRGB(p, q, h + 0.333),
        g = hueToRGB(p, q, h),
        b = hueToRGB(p, q, h - 0.333)

  return {
    r: parseInt(r * 255),
    g: parseInt(g * 255),
    b: parseInt(b * 255),
  }
}

export function rgbToCSS(rgb) {
  return `rgb(${rgb.r},${rgb.g},${rgb.b})`
}

// These colors are also mapped in variables.scss
const IO_COLOR_NAMES = {
  black: '#000',
  white: '#fff',
  blue: '#0062c7',
  blue_dark: '#0056af',
  blue_focus: '#d0effb',
  green: '#477a00',
  green_dark: '#365c00',
  green_focus: '#e0efcb',
  green_alt: '#90c83a',
  red: '#d42133',
  red_dark: '#ac1b29',
  red_focus: '#fbd0d0',
  red_alt: '#ff2424',
  yellow: '#efab11',
  yellow_dark: '#efab11',
  yellow_focus: '#efab11',
  yellow_alt: '#efab11',
  inactive_gray: '#949494',
  dark_gray: '#333333',
  background_gray: '#f0f0f0'
}

export function ioNameToHex(name='') {
  return IO_COLOR_NAMES[name.replace('-', '_')];
}

const COLOR_NAMES = {
  aliceblue: ['#F0F8FF', [240, 248, 255]],
  antiquewhite: ["#FAEBD7", [250, 235, 215]],
  aqua: ["#00FFFF", [0, 255, 255]],
  aquamarine: ["#7FFFD4", [127, 255, 212]],
  azure: ["#F0FFFF", [240, 255, 255]],
  beige: ["#F5F5DC", [245, 245, 220]],
  bisque: ["#FFE4C4", [255, 228, 196]],
  black: ["#000000", [0, 0, 0]],
  blanchedalmond: ["#FFEBCD", [255, 235, 205]],
  blue: ["#0000FF", [0, 0, 255]],
  blueviolet: ["#8A2BE2", [138, 43, 226]],
  brown: ["#A52A2A", [165, 42, 42]],
  burlywood: ["#DEB887", [222, 184, 135]],
  cadetblue: ["#5F9EA0", [95, 158, 160]],
  chartreuse: ["#7FFF00", [127, 255, 0]],
  chocolate: ["#D2691E", [210, 105, 30]],
  coral: ["#FF7F50", [255, 127, 80]],
  cornflowerblue: ["#6495ED", [100, 149, 237]],
  cornsilk: ["#FFF8DC", [255, 248, 220]],
  crimson: ["#DC143C", [220, 20, 60]],
  cyan: ["#00FFFF", [0, 255, 255]],
  darkblue: ["#00008B", [0, 0, 139]],
  darkcyan: ["#008B8B", [0, 139, 139]],
  darkgoldenrod: ["#B8860B", [184, 134, 11]],
  darkgray: ["#A9A9A9", [169, 169, 169]],
  darkgreen: ["#006400", [0, 100, 0]],
  darkgrey: ["#A9A9A9", [169, 169, 169]],
  darkkhaki: ["#BDB76B", [189, 183, 107]],
  darkmagenta: ["#8B008B", [139, 0, 139]],
  darkolivegreen: ["#556B2F", [85, 107, 47]],
  darkorange: ["#FF8C00", [255, 140, 0]],
  darkorchid: ["#9932CC", [153, 50, 204]],
  darkred: ["#8B0000", [139, 0, 0]],
  darksalmon: ["#E9967A", [233, 150, 122]],
  darkseagreen: ["#8FBC8F", [143, 188, 143]],
  darkslateblue: ["#483D8B", [72, 61, 139]],
  darkslategray: ["#2F4F4F", [47, 79, 79]],
  darkslategrey: ["#2F4F4F", [47, 79, 79]],
  darkturquoise: ["#00CED1", [0, 206, 209]],
  darkviolet: ["#9400D3", [148, 0, 211]],
  deeppink: ["#FF1493", [255, 20, 147]],
  deepskyblue: ["#00BFFF", [0, 191, 255]],
  dimgray: ["#696969", [105, 105, 105]],
  dimgrey: ["#696969", [105, 105, 105]],
  dodgerblue: ["#1E90FF", [30, 144, 255]],
  firebrick: ["#B22222", [178, 34, 34]],
  floralwhite: ["#FFFAF0", [255, 250, 240]],
  forestgreen: ["#228B22", [34, 139, 34]],
  fuchsia: ["#FF00FF", [255, 0, 255]],
  gainsboro: ["#DCDCDC", [220, 220, 220]],
  ghostwhite: ["#F8F8FF", [248, 248, 255]],
  gold: ["#FFD700", [255, 215, 0]],
  goldenrod: ["#DAA520", [218, 165, 32]],
  gray: ["#808080", [128, 128, 128]],
  green: ["#008000", [0, 128, 0]],
  greenyellow: ["#ADFF2F", [173, 255, 47]],
  grey: ["#808080", [128, 128, 128]],
  honeydew: ["#F0FFF0", [240, 255, 240]],
  hotpink: ["#FF69B4", [255, 105, 180]],
  indianred: ["#CD5C5C", [205, 92, 92]],
  indigo: ["#4B0082", [75, 0, 130]],
  ivory: ["#FFFFF0", [255, 255, 240]],
  khaki: ["#F0E68C", [240, 230, 140]],
  lavender: ["#E6E6FA", [230, 230, 250]],
  lavenderblush: ["#FFF0F5", [255, 240, 245]],
  lawngreen: ["#7CFC00", [124, 252, 0]],
  lemonchiffon: ["#FFFACD", [255, 250, 205]],
  lightblue: ["#ADD8E6", [173, 216, 230]],
  lightcoral: ["#F08080", [240, 128, 128]],
  lightcyan: ["#E0FFFF", [224, 255, 255]],
  lightgoldenrodyellow: ["#FAFAD2", [250, 250, 210]],
  lightgray: ["#D3D3D3", [211, 211, 211]],
  lightgreen: ["#90EE90", [144, 238, 144]],
  lightgrey: ["#D3D3D3", [211, 211, 211]],
  lightpink: ["#FFB6C1", [255, 182, 193]],
  lightsalmon: ["#FFA07A", [255, 160, 122]],
  lightseagreen: ["#20B2AA", [32, 178, 170]],
  lightskyblue: ["#87CEFA", [135, 206, 250]],
  lightslategray: ["#778899", [119, 136, 153]],
  lightslategrey: ["#778899", [119, 136, 153]],
  lightsteelblue: ["#B0C4DE", [176, 196, 222]],
  lightyellow: ["#FFFFE0", [255, 255, 224]],
  lime: ["#00FF00", [0, 255, 0]],
  limegreen: ["#32CD32", [50, 205, 50]],
  linen: ["#FAF0E6", [250, 240, 230]],
  magenta: ["#FF00FF", [255, 0, 255]],
  maroon: ["#800000", [128, 0, 0]],
  mediumaquamarine: ["#66CDAA", [102, 205, 170]],
  mediumblue: ["#0000CD", [0, 0, 205]],
  mediumorchid: ["#BA55D3", [186, 85, 211]],
  mediumpurple: ["#9370DB", [147, 112, 219]],
  mediumseagreen: ["#3CB371", [60, 179, 113]],
  mediumslateblue: ["#7B68EE", [123, 104, 238]],
  mediumspringgreen: ["#00FA9A", [0, 250, 154]],
  mediumturquoise: ["#48D1CC", [72, 209, 204]],
  mediumvioletred: ["#C71585", [199, 21, 133]],
  midnightblue: ["#191970", [25, 25, 112]],
  mintcream: ["#F5FFFA", [245, 255, 250]],
  mistyrose: ["#FFE4E1", [255, 228, 225]],
  moccasin: ["#FFE4B5", [255, 228, 181]],
  navajowhite: ["#FFDEAD", [255, 222, 173]],
  navy: ["#000080", [0, 0, 128]],
  oldlace: ["#FDF5E6", [253, 245, 230]],
  olive: ["#808000", [128, 128, 0]],
  olivedrab: ["#6B8E23", [107, 142, 35]],
  orange: ["#FFA500", [255, 165, 0]],
  orangered: ["#FF4500", [255, 69, 0]],
  orchid: ["#DA70D6", [218, 112, 214]],
  palegoldenrod: ["#EEE8AA", [238, 232, 170]],
  palegreen: ["#98FB98", [152, 251, 152]],
  paleturquoise: ["#AFEEEE", [175, 238, 238]],
  palevioletred: ["#DB7093", [219, 112, 147]],
  papayawhip: ["#FFEFD5", [255, 239, 213]],
  peachpuff: ["#FFDAB9", [255, 218, 185]],
  peru: ["#CD853F", [205, 133, 63]],
  pink: ["#FFC0CB", [255, 192, 203]],
  plum: ["#DDA0DD", [221, 160, 221]],
  powderblue: ["#B0E0E6", [176, 224, 230]],
  purple: ["#800080", [128, 0, 128]],
  rebeccapurple: ["#663399", [102, 51, 153]],
  red: ["#FF0000", [255, 0, 0]],
  rosybrown: ["#BC8F8F", [188, 143, 143]],
  royalblue: ["#4169E1", [65, 105, 225]],
  saddlebrown: ["#8B4513", [139, 69, 19]],
  salmon: ["#FA8072", [250, 128, 114]],
  sandybrown: ["#F4A460", [244, 164, 96]],
  seagreen: ["#2E8B57", [46, 139, 87]],
  seashell: ["#FFF5EE", [255, 245, 238]],
  sienna: ["#A0522D", [160, 82, 45]],
  silver: ["#C0C0C0", [192, 192, 192]],
  skyblue: ["#87CEEB", [135, 206, 235]],
  slateblue: ["#6A5ACD", [106, 90, 205]],
  slategray: ["#708090", [112, 128, 144]],
  slategrey: ["#708090", [112, 128, 144]],
  snow: ["#FFFAFA", [255, 250, 250]],
  springgreen: ["#00FF7F", [0, 255, 127]],
  steelblue: ["#4682B4", [70, 130, 180]],
  tan: ["#D2B48C", [210, 180, 140]],
  teal: ["#008080", [0, 128, 128]],
  thistle: ["#D8BFD8", [216, 191, 216]],
  tomato: ["#FF6347", [255, 99, 71]],
  turquoise: ["#40E0D0", [64, 224, 208]],
  violet: ["#EE82EE", [238, 130, 238]],
  wheat: ["#F5DEB3", [245, 222, 179]],
  white: ["#FFFFFF", [255, 255, 255]],
  whitesmoke: ["#F5F5F5", [245, 245, 245]],
  yellow: ["#FFFF00", [255, 255, 0]],
  yellowgreen: ["#9ACD32", [154, 205, 50]],
}

export function nameToRGB(word) {
  if (COLOR_NAMES[word]) {
    const rgb = COLOR_NAMES[word][1]
    return {
      r: rgb[0],
      g: rgb[1],
      b: rgb[2],
    }
  } else {
    return null
  }
}
