import React, { useEffect } from 'react'
import { Router, browserHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { syncHistoryWithStore } from 'react-router-redux'

import routes from 'routes'
import store from 'store'
import ErrorBoundary from 'components/TopLevel/ErrorBoundary'
import SessionActions from 'actions/session'
import { Spinner } from 'components/Elements/Loading'


const history = syncHistoryWithStore(browserHistory, store)

const IORouter = (props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(SessionActions.initializeSession())
  }, [])

  const
    loading = useSelector(state => state.session.loading),
    ready = useSelector(state => state.session.ready)

  return loading
    ? <LoadingLayout />
    : ready
    ? <ErrorBoundary errorPrefix="Router">
        <Router history={history} routes={routes} />
      </ErrorBoundary>
    : <div className="error">Error</div>
}

const LoadingLayout = () =>
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className='root-loading'>
          <Spinner />
        </div>
      </div>
    </div>
  </div>


export default IORouter
