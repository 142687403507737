import { pick } from 'lodash'
import React from 'react'
import { Link } from 'react-router'

import useRoutes from 'effects/useRoutes'


const IOLink = props => {
  const
    { to, route, params } = props,
    passthruProps = pick(props, [ "title", "className" ])

  if(to && route) {
    throw new Error(`Cannot specify 'to' and 'route' props.\nto: "${ to }"\nroute: "${ route }"`)
  }

  if(!(to || route)) {
    throw new Error("Must specify 'to' or 'route' prop, neither were provided")
  }

  const linkTo = to || useRoutes(route, params)

  return (linkTo
    ? <Link to={ linkTo } { ...passthruProps }>{ props.children }</Link>
    : <>{ props.children }</>)
}

export default IOLink
