import React from 'react'
import { connect } from 'react-redux'

import { pluralize } from 'utils/strings'
import { shortDayTime } from 'utils/dates'
import FeedbackActions from 'actions/feedback'
import FeedbackTypes from './FeedbackTypes'
import FormButtons from './FormButtons'


const RadioButton = ({ onChange, value, selected }) =>
  <input type='radio' name='feedback_type' value={ value } checked={ selected === value } onChange={ onChange } />

class PickFeedbackType extends React.Component {
  state = {
    selected: null,
    showPrevious: false
  }

  componentDidMount() {
    this.props.dispatch(FeedbackActions.load())
  }

  select = evt => this.setState({ selected: evt.target.value })

  submit = evt => {
    evt.preventDefault()

    // no selection
    if (!this.state.selected) { return }

    switch (this.state.selected) {
      case FeedbackTypes.SERVICE:
      case FeedbackTypes.PLUS:
      case FeedbackTypes.SUGGESTION:
      case FeedbackTypes.OTHER:
      case FeedbackTypes.BETA:
        this.props.dispatch(FeedbackActions.toStep('feedback', this.state.selected))
        break
      case FeedbackTypes.WIPPERSNAPPER:
      case FeedbackTypes.WIPPER_SUGGESTION:
      case FeedbackTypes.PROJECT:
      case FeedbackTypes.GENERAL:
      default:
        this.props.dispatch(FeedbackActions.toStep('forum', this.state.selected))
        break
    }
  }

  feedbackTableRows = () =>
    this.props.feedbacks.map(({ id, created_at, subject }) =>
      <tr key={ id }>
        <td>{ shortDayTime(created_at, { seconds: true }) }</td>
        <td>{ subject }</td>
      </tr>
    )

  feedbackTable() {
    if (this.state.showPrevious) {
      return (
        <table className='table compact'>
          <thead>
            <tr>
              <th>Created</th>
              <th>Subject</th>
            </tr>
          </thead>
          <tbody>
            { this.feedbackTableRows() }
          </tbody>
        </table>
      )
    } else {
      return (
        <p>
          <a onClick={() => this.setState({ showPrevious: true })}>Show previous feedback</a>
        </p>
      )
    }
   }

  feedbacks() {
    const length = this.props.feedbacks?.length

    if (length) {
      return (
        <div className='previous-requests'>
          <p>You have sent { pluralize(length, 'request') }.</p>

          { this.feedbackTable() }
        </div>
      )
    }
  }

  render() {
    const buttonDisabled = !this.state.selected

    return (
      <div>
        <form onSubmit={this.submit}>
          <p>
            If you have noticed a bug with Adafruit IO, have a question about
            IO+, or have any other reason you want to get in touch with the
            Adafruit IO team then you have come to the right place. Fill out
            the form below to figure out the best way to reach us.
          </p>

          <p>How can we help you?</p>

          <ul className='options'>
            <li>
              <label>
                <RadioButton value={FeedbackTypes.SERVICE} onChange={this.select} {...this.state} />
                There is an issue with Adafruit IO. Something is broken!
              </label>
            </li>
            <li>
              <label>
                <RadioButton value={FeedbackTypes.WIPPERSNAPPER} onChange={this.select} {...this.state} />
                I need help with my WipperSnapper project
              </label>
            </li>
            <li>
              <label>
                <RadioButton value={FeedbackTypes.WIPPER_SUGGESTION} onChange={this.select} {...this.state} />
                I have feedback or suggestions for the WipperSnapper Beta
              </label>
            </li>
            <li>
              <label>
                <RadioButton value={FeedbackTypes.PLUS} onChange={this.select} {...this.state} />
                I have a question about Adafruit IO+ or Adafruit IO payments
              </label>
            </li>
            <li>
              <label>
                <RadioButton value={FeedbackTypes.GENERAL} onChange={this.select} {...this.state} />
                I have a general question about Adafruit IO
              </label>
            </li>
            <li>
              <label>
                <RadioButton value={FeedbackTypes.SUGGESTION} onChange={this.select} {...this.state} />
                I have a suggestion for Adafruit IO
              </label>
            </li>
            <li>
              <label>
                <RadioButton value={FeedbackTypes.PROJECT} onChange={this.select} {...this.state} />
                I need help with my project hardware or code
              </label>
            </li>
            <li>
              <label>
                <RadioButton value={FeedbackTypes.OTHER} onChange={this.select} {...this.state} />
                It's something not listed here
              </label>
            </li>
          </ul>

          <FormButtons>
            <button className='btn blue' disabled={buttonDisabled}>CONTINUE</button>
          </FormButtons>

        </form>

        { this.feedbacks() }
      </div>
    )
  }
}

const mapState = state => ({
  feedbacks: state.feedback.feedbacks
})

const ConnectedPickFeedbackType = connect(mapState)(PickFeedbackType)
export default ConnectedPickFeedbackType
