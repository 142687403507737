import Cookies from 'universal-cookie'

import TopLevelComponent from 'components/TopLevel'
import { canSeeDesignGuide, hasBlocklyAccess } from 'selectors/users'
import store from 'store'
import { Paths } from 'utils/paths'

const MAINTENANCE_MODE = import.meta.env.VITE_MAINTENANCE_MODE === 'true'

export const
  redirectToSessionRoute = toProp => (nextState, replace) => {
    const
      { session } = store.getState(),
      toRoute = Paths[toProp]

    if(!toRoute) { throw new Error(`IOShortcut Error: No route generator named "${toProp}"`) }

    session?.user
      // if user, generate the requested route with their username
      ? replace(toRoute({ username: session.user.username, ...nextState.params }))
      // if no user, redirect to landing
      : replace(Paths.root())
  },

  forwardToHome = (nextState, replace) => {
    const user = store.getState().session?.user

    if (user?.username) {
      replace(Paths.home({ username: user.username }))
    }
  },

  checkEveryPage = (nextState, replace) => {
    const nextPath = nextState.location.pathname

    // Check for maintenance mode
    if (MAINTENANCE_MODE && !/maintenance/.test(nextPath)) {
      replace('/maintenance')
      return
    }

    // Check for users without a username
    const user = store.getState().session?.user

    if (user && !user.username && (nextPath !== Paths.noUsername())) {
      // direct them immediately to noUsername
      replace(Paths.noUsername())
      return
    }

    // Check for redirect cookie
    const
      cookies = new Cookies(),
      redir = cookies.get('redirect_to')

    if (redir) {
      cookies.remove('redirect_to')

      const pathname = decodeURIComponent(redir)
      if (Paths.isSafe(pathname)) {
        replace(pathname)
        return
      }
    }
  },

  // checks auth and redirects to root
  authOnly = (nextState, replace) => {
    const sessionUser = store.getState().session?.user

    if(!sessionUser) { replace(Paths.root()) }
  },

  // compare the username in the session to the username in the url
  // mismatches are redirected to the public page for the url username
  ownerOnly = (nextState, replace) => {
    const
      urlUsername = nextState.params.username,
      sessionUser = store.getState().session?.user,
      isAnon = !sessionUser,
      isNotUser = isAnon || (urlUsername !== sessionUser.username),
      hasAuthority = !isAnon && ['admin', 'developer'].includes(sessionUser.role)

    if (isNotUser && !hasAuthority) {
      replace(Paths.public_user({ username: urlUsername }))
    }
  },

  guideAccessOnly = (nextState, replace) => {
    canSeeDesignGuide(store.getState()) || replace(Paths.root())
  },

  // temporary onEnter prop combining ownerOnly and a beta access check
  ownerBlocklyBetaOnly = (nextState, replace) => {
    let replaceCalled = false

    ownerOnly(nextState, (...args) => {
      replaceCalled = true
      replace(...args)
    })

    // ownerOnly handled it
    if(replaceCalled) { return }

    // otherwise we have a user and the url username is right
    const username = nextState.params.username
    // check their blockly access, redirect to legacy actions if missing
    hasBlocklyAccess(store.getState()) || replace(Paths.legacy_actions({ username }))
  },

  wrapPage = page => async (_, callback) => {
    try {
      const view = await page()
      callback(null, { main: TopLevelComponent(view.default) })

    } catch(err) {
      if(err.name === "ChunkLoadError") {
        // TODO: handle this, gentle reload, tell sentry with extras, engage ErrorBoundary
      }

      // same behavior as now
      throw err
    }
  }
