import { createAction } from 'redux-actions'

import Session from './session'
import { Paths } from 'utils/paths'
import { ProfileSelectors } from 'reducers/profiles'


const Profiles = {}

Profiles.select = createAction('PROFILE_SELECT', params => params)

const isRecent = ({ retrievedAt }, storeUntil) => retrievedAt
  ? (Date.now() - retrievedAt) < storeUntil
  : false

Profiles.load = ({ username, force }) => {
  return (dispatch, getState) => {
    const
      client = Session.getClient(dispatch, getState),
      state = getState(),
      existing = ProfileSelectors.getCurrentUserProfile(state)

    if (!force && existing && (existing.username === username) && isRecent(existing, 30000)) {
      return dispatch({
        type: 'PROFILE_LOAD',
        payload: Promise.resolve(existing)
      })
    }

    // ignore request if profile is already loading
    if (state.profiles?.currentLoading) { return }

    return dispatch({
      type: 'PROFILE_LOAD',
      payload: Session.ajax(client, Paths.api_profile({ username }))
    })
  }
}

Profiles.reload = params => Profiles.load({ ...params, force: true })

Profiles.checkForPromotions = (params={}) => {
  return (dispatch, getState) => {
    const client = Session.getClient(dispatch, getState)
    const url = Paths.api_promotion_check({ username: params.username })

    return dispatch({
      type: 'PROMOTION_CHECK',
      payload: Session.ajax(client, url, {method: 'POST', credentials: 'include'})
    })
  }
}

Profiles.loadPromotionDiscounts = (params={}) => {
  return (dispatch, getState) => {
    const client = Session.getClient(dispatch, getState)
    const url = Paths.api_promotion_discounts({ username: params.username })

    return dispatch({
      type: 'PROMOTION_DISCOUNTS_LOAD',
      payload: Session.ajax(client, url)
    })
  }
}

export default Profiles
